import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../shared/events.service';
import { ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/shared/models/Event';
import { RequestedCable } from 'src/app/shared/models/RequestedCable';


export class CableSizes {
  public length: number;
  public amount: number;
}

@Component({
  selector: 'app-preparation-paper',
  templateUrl: './preparation-paper.component.html',
  styleUrls: ['./preparation-paper.component.css']
})
export class PreparationPaperComponent implements OnInit {

  public event: Event = new Event();
  public cablesAmount: CableSizes[] = [];

  constructor(private _eventsService: EventsService, private _route: ActivatedRoute) { }

  ngOnInit() {
    this._route.params.subscribe(paramas => {
      this._eventsService.getEventById(paramas['eventId']).subscribe(result => {
        this.event = result;
        console.log(this.event);
        this.calculateCableAmount(this.event.requestedCable);
      });
    });
  }

  calculateCableAmount(requestedCable: RequestedCable[]) {
    requestedCable.forEach(cable => {
      this.cablesAmount.push({ length: cable.cables.cableType.length, amount: cable.quantity });
    });
  }

}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CLIENTS_ROUTES } from './clients.routes';
import { SharedModule } from '../shared/shared.module';
import { ClientsComponent } from './clients.component';
import { ClientsService } from './shared/clients.service';
import { ClientListComponent } from './client-list/client-list.component';
import { ViewClientComponent } from './view-client/view-client.component';
import { UpdateClientComponent } from './update-client/update-client.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    RouterModule.forChild(CLIENTS_ROUTES),
    SharedModule,
    NgSelectModule
  ],
  declarations: [
    ClientsComponent,
    ClientListComponent,
    ViewClientComponent,
    UpdateClientComponent
  ],
  providers: [
    ClientsService
  ]
})
export class ClientsModule { }

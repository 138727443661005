import { PriceList } from './PriceList';
import { Event } from './Event';
import { DeviceUserType } from './DeviceUserType';
import { Device } from './Device';

export class DeviceUser {
    public id: number;
    public name: string;
    public username: string;
    public allowOhViewing: boolean;
    public enablePaymentScreen: boolean;
    public ohUpdateInterval: boolean;
    public showProductFilter: boolean;
    public lvlOnePin: number;
    public lvlTwoPin: number;
    public pinCode: number;
    public pinCodeUse: boolean;
    public priceList: PriceList = new PriceList();
    public event: Event = new Event();
    public deviceUserType: DeviceUserType = new DeviceUserType();
    public device: Device = new Device();

    static fromDto(d): DeviceUser {
        const deviceUser = new DeviceUser();

        if (!d) {
            return null;
        }

        deviceUser.id = d.id;
        deviceUser.name = d.name;
        deviceUser.username = d.username;
        deviceUser.allowOhViewing = d.allowOhViewing;
        deviceUser.enablePaymentScreen = d.enablePaymentScreen;
        deviceUser.ohUpdateInterval = d.ohUpdateInterval;
        deviceUser.showProductFilter = d.showProductFilter;
        deviceUser.lvlOnePin = d.lvlOnePin;
        deviceUser.lvlTwoPin = d.lvlTwoPin;
        deviceUser.pinCode = d.pinCode;
        deviceUser.pinCodeUse = d.pinCodeUse;
        deviceUser.priceList = d.priceList;
        deviceUser.event = d.event;
        deviceUser.deviceUserType = d.deviceUserType;
        deviceUser.device = d.device;

        return deviceUser;
    }
}

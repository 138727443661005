import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductOption } from 'src/app/shared/models/ProductOption';
import { PriceListService } from 'src/app/price-lists/shared/price-list.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ValidationUtils } from 'src/app/shared/validation.utils';

@Component({
  selector: 'app-multiple-product-options',
  templateUrl: './multiple-product-options.component.html',
  styleUrls: ['./multiple-product-options.component.css']
})
export class MultipleProductOptionsComponent implements OnInit {

  public productOptionForm: FormGroup;
  public productOptionErrorArray = Array();
  public productOptionsArray: ProductOption[] = [];
  public editProductOption = false;
  public toEditProductOption: ProductOption;


  @Input()
  disableSaveButton: boolean;

  @Input()
  productIdsArray: Array<string>;

  @Output()
  afterOptionsAdded: EventEmitter<void> = new EventEmitter<void>();

  constructor(private _formBuilder: FormBuilder, private _priceListService: PriceListService, private _toastr: ToastrService,
    private _translate: TranslateService) {
    this.productOptionForm = this._formBuilder.group({
      name: ['', [<any>Validators.required, <any>Validators.maxLength(32)]],
      extraInfo: ['', <any>Validators.maxLength(32)],
      price: ['0.00', <any>Validators.pattern(/^\-*\d{0,8}(\.\d{1,4})?$/)],
      itemOrder: [],
      status: []
    }, {
        validator: ValidationUtils.checkStringLength('name', 'extraInfo')
      });
  }

  ngOnInit() {
  }


  onProductOptionSubmit() {
    if (this.productOptionForm.valid) {
      const submitedProductOption = ProductOption.fromDto(this.productOptionForm.value);
      if (!this.editProductOption) {
        submitedProductOption.itemOrder = (this.productOptionsArray.length === 0 ? 1 :
          (this.productOptionsArray[this.productOptionsArray.length - 1].itemOrder + 1));
        submitedProductOption.status = 1;
        this.productOptionsArray.push(submitedProductOption);
        this.productOptionForm.reset({ name: '', extraInfo: '', price: '0.00' });
      } else {
        const optionIndex = this.productOptionsArray.findIndex(option => option.itemOrder === submitedProductOption.itemOrder);
        this.productOptionsArray[optionIndex] = submitedProductOption;
        this.cancelEditOption();
      }
    } else {
      this.productOptionErrorArray = ValidationUtils.getFormValidationErrors(this.productOptionForm, 'price-list.manage');
    }
  }

  changeProductOptionStatus(option: ProductOption, status: number) {
    option.status = status;
  }

  changeProductOptionPosition(currentItemOrder: number, newItemOrder: number) {
    const optionByCurrentItemOrder = this.productOptionsArray.filter(option => option.itemOrder === currentItemOrder)[0];
    const optionByNewItemOrder = this.productOptionsArray.filter(option => option.itemOrder === newItemOrder)[0];
    const optionByCurrentItemOrderValue = optionByCurrentItemOrder.itemOrder;
    const optionByNewItemOrderValue = optionByNewItemOrder.itemOrder;
    optionByCurrentItemOrder.itemOrder = optionByNewItemOrderValue;
    optionByNewItemOrder.itemOrder = optionByCurrentItemOrderValue;
    this.productOptionsArray.sort((a, b) => a.itemOrder - b.itemOrder);
  }

  enableProductOptionedit(option: ProductOption) {
    this.editProductOption = true;
    this.toEditProductOption = { ...option };
    this.productOptionForm.patchValue(this.toEditProductOption);
  }

  cancelEditOption() {
    this.editProductOption = false;
    this.toEditProductOption = undefined;
    this.productOptionForm.reset({ name: '', extraInfo: '', price: '0.00' });
  }

  deleteProductOption(optionOrderValue: number) {
    const toDeleteOptionIndex = this.productOptionsArray.findIndex(option => option.itemOrder === optionOrderValue);
    this.productOptionsArray.splice(toDeleteOptionIndex, 1);
    this.productOptionsArray.filter(option => option.itemOrder > optionOrderValue).forEach(option => {
      option.itemOrder = (option.itemOrder - 1);
    });
    this.productOptionsArray.sort((a, b) => a.itemOrder - b.itemOrder);
  }

  duplicateProductOption(optionToDuplicate: ProductOption) {
    const newOption = { ...optionToDuplicate };
    newOption.itemOrder = (this.productOptionsArray[(this.productOptionsArray.length - 1)].itemOrder + 1);
    this.productOptionsArray.push(newOption);
    this.productOptionsArray.sort((a, b) => a.itemOrder - b.itemOrder);
  }

  saveOptionsToProducts() {
    if (this.productOptionsArray.length > 0) {
      console.log(this.productIdsArray);
      console.log(this.productOptionsArray);
      this._priceListService.addMultipleOptionsToMultipleProducts(this.productIdsArray, this.productOptionsArray).subscribe(result => {
        console.log(result);
        this._toastr.success(this._translate.instant('Options added successfully'));
        this.afterOptionsAdded.emit();
      });
    } else {
      this._toastr.error(this._translate.instant('Please add some options'));
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RoutersService } from '../shared/routers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { RouterType } from 'src/app/shared/models/RouterType';
import { ValidationUtils } from 'src/app/clients/shared/validation.utils';

@Component({
  selector: 'app-update-router-type',
  templateUrl: './update-router-type.component.html',
  styleUrls: ['./update-router-type.component.css']
})
export class UpdateRouterTypeComponent implements OnInit {

  public routerTypesFormGroup: FormGroup;
  private _routerTypeId: string;
  private _method: string;
  public routerTypeVals: RouterType[] = [];
  public validationErrorsArray = [];
  public routerType: RouterType;

  public routerTypeStatusArray: Option[] = [
    { value: 0, label: 'Disabled' },
    { value: 1, label: 'Enabled' },
  ];

  constructor(private _toastr: ToastrService, private _routerService: RoutersService,
    private _router: Router, private _route: ActivatedRoute, private _formBuilder: FormBuilder) {
    this.routerTypesFormGroup = this._formBuilder.group({
      id: [''],
      price: ['', Validators.required],
      name: ['', Validators.required],
      status: [1],
      routerManufacturer: [, Validators.required]
    });
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this._method = params['methodType'];
      this._routerTypeId = params['routerTypeId'];
      this._routerService.getAllRouterTypes().subscribe(result => {
        this.routerTypeVals = result;
      });
      if (this._method !== 'update' && this._method !== 'insert') {
        this._router.navigate(['/hardware/routers']);
      }
      this._routerService.getRouterTypeInformationsById(this._routerTypeId).subscribe(result => {
        this.routerTypesFormGroup.patchValue(result);
        console.log(result.routerManufacturer);
        this.routerTypesFormGroup.get('routerManufacturer').setValue(result.routerManufacturer.id);
      });
      this.routerTypesFormGroup.reset({ status: 1 });
    });
  }

  onRouterTypeFormSubmit() {
    this.routerTypeStatusArray = [];
    if (this.routerTypesFormGroup.valid) {
      this.routerType = RouterType.fromDto(this.routerTypesFormGroup.value);
      this._routerService.updateRouterTypeInformations(this.routerType).subscribe(result => {
        this.routerTypesFormGroup.patchValue(result);
        this._toastr.success('RouterType updated with success');
      });
      if (this._method === 'insert') {
        this._routerService.insertRouterTypeInformations(this.routerType).subscribe(result => {
          this._toastr.success('RouterType inserted with success');
          this.routerTypesFormGroup.reset({ status: 1 });
        })
      }
    }
    this.validationErrorsArray = ValidationUtils.getFormValidationErrors(this.routerTypesFormGroup, 'routerType');
  }
}


import { Company } from './Company';
import { Event } from './Event';
import { EventOffer } from './EventOffer';
import { SurveyAnswer } from './SurveyAnswer';

export class Client {
    public id: number;
    public title: string;
    public firstName: string;
    public lastName: string;
    public firstEmail: string;
    public secondEmail: string;
    public firstPhone: string;
    public secondPhone: string;
    public username: string;
    public password: string;
    public createdAt: string;
    public parentAdminId: number;
    public notes: string;
    public company: Company = new Company();
    public status: number;
    public administrator: boolean;
    public newsletter: string;
    public firstLogin: number;
    public language: string;
    public events: Event[] = [];
    public offers: EventOffer[] = [];
    public answer: SurveyAnswer = new SurveyAnswer();

    static fromDto(c): Client {
        const client = new Client();

        if (!c) {
            return null;
        }

        client.id = c.id;
        client.title = c.title;
        client.firstName = c.firstName;
        client.lastName = c.lastName;
        client.firstEmail = c.firstEmail;
        client.secondEmail = c.secondEmail;
        client.firstPhone = c.firstPhone;
        client.secondPhone = c.secondPhone;
        client.username = c.firstEmail;
        client.password = c.password;
        client.createdAt = c.createdAt;
        client.parentAdminId = c.parentAdminId;
        client.notes = c.notes;
        client.status = c.status;
        client.administrator = c.administrator;
        client.company = Company.fromDto(c.company);
        client.newsletter = c.newsletter;
        client.language = c.language;
        client.firstLogin = c.firstLogin;
        if (c.events) {
            client.events = c.events.map(event => Event.fromDto(event));
        }
        if (c.offers) {
          client.offers = c.offers.map(offer => EventOffer.fromDto(offer));
      }

        return client;
    }
}



import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { PriceList } from '../../models/PriceList';
import { EventsService } from 'src/app/events/shared/events.service';
import { PriceListService } from 'src/app/price-lists/shared/price-list.service';
import { Option } from '../../select/select.component';
import {Observable} from 'rxjs';
import { SearchObjectResult } from '../../models/SearchObject';
import { PriceListSearchCriteria } from 'src/app/price-lists/shared/price-list-search-criteria.model';
import { DataTableColumn } from '../../data-table/data-table.component';
import { SearchProduct } from '../../models/SearchProduct';
import { ProductOption } from '../../models/ProductOption';
import { map } from 'rxjs/operators';
import { Product } from '../../models/Product';
import { ProductCategory } from '../../models/ProductCategory';

@Component({
  selector: 'app-price-list-preview-modal',
  templateUrl: './price-list-preview-modal.component.html',
  styleUrls: ['./price-list-preview-modal.component.css']
})
export class PriceListPreviewModalComponent implements OnInit {

  public priceList: PriceList = new PriceList();
  public categoryOptions: Option[] = [{value: undefined, label: 'All Categories'}];
  public hideOptionsTable = true;
  public selectedProduct: Product = new Product();

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<SearchProduct>> = null;
  filters: PriceListSearchCriteria = { };

  @ViewChild('productId')
  productId: TemplateRef<any>;

  @ViewChild('productCategoryColumn')
  productCategoryColumn: TemplateRef<any>;

  @ViewChild('productNameColumn')
  productNameColumn: TemplateRef<any>;

  @ViewChild('extraInfoColumn')
  extraInfoColumn: TemplateRef<any>;

  @ViewChild('priceColumn')
  priceColumn: TemplateRef<any>;

  @ViewChild('statusColumn')
  statusColumn: TemplateRef<any>;

  @ViewChild('optionsColumn')
  optionsColumn: TemplateRef<any>;

  public columns: DataTableColumn[];

  @Input()
  priceListId: number;

  @Input()
  disabled = false;

  constructor(private _priceListService: PriceListService, private _eventsService: EventsService,
    private _modalService: NgbModal, modalConfig: NgbModalConfig) { }

  ngOnInit() {
  }

  open(content) {
    this.loadPage = (page: number, pageSize: number) => this._priceListService.search(page, pageSize, this.filters, this.priceListId);
    this.columns = [
      {
        name: 'productId',
        displayed: true,
        translationKey: 'Id',
        template: this.productId,
        sortable: true
      },
      {
        name: 'categoryName',
        displayed: true,
        translationKey: 'Product Category',
        template: this.productCategoryColumn,
        sortable: true
      },
      {
        name: 'productName',
        displayed: true,
        translationKey: 'Product Name',
        template: this.productNameColumn,
        sortable: true
      },
      {
        name: 'productExtraInfo',
        displayed: true,
        translationKey: 'Extra Info',
        template: this.extraInfoColumn,
        sortable: true
      },
      {
        name: 'productPrice',
        displayed: true,
        translationKey: 'Price',
        template: this.priceColumn,
        sortable: true
      },
      {
        name: 'productStatus',
        displayed: true,
        translationKey: 'Status',
        template: this.statusColumn,
        sortable: true
      },
      {
        name: 'optionsCount',
        displayed: true,
        translationKey: 'Options',
        template: this.optionsColumn,
        sortable: true
      }
    ];
    this._modalService.open(content, {size: 'lg', windowClass: 'price-list-preview-modal'}).result.then((result) => {
    }, (reason) => {
      this.priceList = new PriceList();
      this.categoryOptions = [{value: undefined, label: 'All Categories'}];
    });
    this._priceListService.gatPriceListsById(this.priceListId.toString()).subscribe(result => {
      this.priceList = result;
      this.priceList.productCategories.forEach(category => {
        this.categoryOptions.push({value: category.id, label: category.name});
      });
    }, error => {
      console.log(error);
    });
  }

  onProductTableRowClick(item) {
    if (item.optionsCount > 0) {
      this.hideOptionsTable = false;
      const categoryProducts = ProductCategory.fromDto(this.priceList.productCategories
        .filter(category => category.id === item.categoryId)[0]).products;
      this.selectedProduct = Product.fromDto(categoryProducts.filter(product => product.id === item.productId)[0]);
    } else {
      this.hideOptionsTable = true;
      this.selectedProduct = new Product();
    }
  }

}

import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ProvincesService } from './../shared/provinces.service';
import { Province } from 'src/app/shared/models/Province';
import { Region } from 'src/app/shared/models/Region';

@Component({
  selector: 'app-edit-province-form',
  templateUrl: './edit-province-form.component.html',
  styleUrls: ['./edit-province-form.component.css']
})
export class EditProvinceFormComponent implements OnInit {

  public editProvinceForm: FormGroup;
  public editErrorArray = new Array();
  public resProvince = [];

  @Input()
  province: Province;

  @Input()
  provincesList: Province[];

  @Input()
  regionsList: Region[];

  @Input()
  regionsOptions: Option[];

  @Output()
  getCancelEditModeEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  geResetProvincesList: EventEmitter<Province[]> = new EventEmitter<Province[]>();

  constructor(private _formBuilder: FormBuilder, private _provincesService: ProvincesService, private _toastr: ToastrService,
    private _translate: TranslateService) {

    this.editProvinceForm = this._formBuilder.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      regionId: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.editProvinceForm.patchValue({
      regionId: this.province.region.id,
      name: this.province.name,
      id: this.province.id
    });
  }

  ngOnInit() {
    this.provincesList;
  }

  onProvinceSubmit() {
    if (this.editProvinceForm.valid) {
      const province = Province.fromDto(this.editProvinceForm.value);
      province.region = this.regionsList.filter(region =>
        region.id.toString() === this.editProvinceForm.value.regionId.toString())[0];
      this._provincesService.updateProvince(province).subscribe(result => {
        this.provincesList = result;
        this.geResetProvincesList.emit(this.provincesList);
        this.getCancelEditModeEvent.emit();
        this._toastr.success(this._translate.instant('Province updated with success'));
      }, error => {
        console.log(error);
      });
    }
    this.editErrorArray = ValidationUtils.getFormValidationErrors(this.editProvinceForm, 'province');
  }

  cancelEditButtonClick() {
    this.getCancelEditModeEvent.emit();
  }

}

import { Routes } from '@angular/router';
import { PrintersComponent } from './printers.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { PrintersListComponent } from './printers-list/printers-list.component';
import { UpdatePrinterComponent } from './update-printer/update-printer.component';
import { UpdatePrinterTypesComponent } from './update-printer-types/update-printer-types.component';

export const PRINTERS_ROUTES: Routes = [
    {
        path: 'hardware/printers', component: PrintersComponent, canActivate: [AuthGuard], children: [
            { path: '', component: PrintersListComponent, canActivate: [AuthGuard] },
            { path: ':methodType', component: UpdatePrinterComponent, canActivate: [AuthGuard] },
            { path: ':methodType/:printerId', component: UpdatePrinterComponent, canActivate: [AuthGuard] }
        ]
    }, {
        path: 'hardware/printer-types/:methodType', component: UpdatePrinterTypesComponent, canActivate: [AuthGuard]
    }, {
        path: 'hardware/printer-types/:methodType/:printerTypeId', component: UpdatePrinterTypesComponent, canActivate: [AuthGuard]
    }
];


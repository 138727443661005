import { Routes } from '@angular/router';
import { DevicesComponent } from './devices.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { DevicesListComponent } from './devices-list/devices-list.component';
import { DevicesUpdateComponent } from './devices-update/devices-update.component';
import { DeviceManufacturerUpdateComponent } from './device-manufacturer-update/device-manufacturer-update.component';

export const DEVICES_ROUTES: Routes = [
    {
        path: 'hardware/devices', component: DevicesComponent, canActivate: [AuthGuard], children: [
            { path: '', component: DevicesListComponent, canActivate: [AuthGuard] },
            { path: ':methodType', component: DevicesUpdateComponent, canActivate: [AuthGuard] },
            { path: ':methodType/:deviceId', component: DevicesUpdateComponent, canActivate: [AuthGuard] }
        ]
    },{
        path: 'hardware/manufacturer/:methodType', component: DeviceManufacturerUpdateComponent, canActivate: [AuthGuard]
    }, {
        path: 'hardware/manufacturer/:methodType/:deviceManufacturerId', component: DeviceManufacturerUpdateComponent, canActivate: [AuthGuard]
    }
];

import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Event } from 'src/app/shared/models/Event';
import { EventsService } from 'src/app/events/shared/events.service';
import { ToastrService } from 'ngx-toastr';
import { DateUtils } from 'src/app/shared/date-utils';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-event-card',
  templateUrl: './edit-event-card.component.html',
  styleUrls: ['./edit-event-card.component.css']
})
export class EditEventCardComponent implements OnInit, OnChanges {

  public eventCopy: Event = new Event();
  public prevStartDate: string;
  public prevEndDate: string;
  public currentStartDate: string;
  public currentEndDate: string;

  @Input()
  event: Event;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onEventUpdated = new EventEmitter<any>();

  constructor(private _eventsService: EventsService, private _toastr: ToastrService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges');
    if (!changes.event.firstChange) {
      this._copyEventObject();
      this.prevStartDate = this.eventCopy.startDate;
      this.prevEndDate = this.eventCopy.endDate;
      this.currentStartDate = this.eventCopy.startDate;
      this.currentEndDate = moment(this.eventCopy.endDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
      this.eventCopy.endDate = moment(this.eventCopy.endDate, 'DD-MM-YYYY').add(1, 'day').format('DD-MM-YYYY');
    }
  }

  onCancelButtonClick() {
    this._copyEventObject();
  }

  onUbdateButtonClick() {
    this.eventCopy.startDate = DateUtils.dateFormatConvert(this.eventCopy.startDate, 'DD-MM-YYYY', 'YYYY-MM-DD');
    this.eventCopy.endDate = DateUtils.dateFormatConvert(this.eventCopy.endDate, 'DD-MM-YYYY', 'YYYY-MM-DD');
    this.eventCopy.pickUpDate = DateUtils.dateFormatConvert(this.eventCopy.pickUpDate, 'DD-MM-YYYY', 'YYYY-MM-DD');
    this.eventCopy.returnDate = DateUtils.dateFormatConvert(this.eventCopy.returnDate, 'DD-MM-YYYY', 'YYYY-MM-DD');

    this._eventsService.updateSimpleEventSpecs(this.eventCopy).subscribe(result => {
      this._toastr.success('Event updated!');
      const eventCopyIvan = { ... this.eventCopy };
      eventCopyIvan.endDate = moment(eventCopyIvan.endDate, 'YYYY-MM-DD').add(-1, 'day').format('YYYY-MM-DD');
      this.onEventUpdated.emit(eventCopyIvan);
      // this._copyEventObject();
      this.eventCopy.startDate = DateUtils.dateFormatConvert(this.eventCopy.startDate, 'YYYY-MM-DD', 'DD-MM-YYYY');
      this.eventCopy.endDate = DateUtils.dateFormatConvert(this.eventCopy.endDate, 'YYYY-MM-DD', 'DD-MM-YYYY');
      this.eventCopy.pickUpDate = DateUtils.dateFormatConvert(this.eventCopy.pickUpDate, 'YYYY-MM-DD', 'DD-MM-YYYY');
      this.eventCopy.returnDate = DateUtils.dateFormatConvert(this.eventCopy.returnDate, 'YYYY-MM-DD', 'DD-MM-YYYY');
    });
  }

  private _copyEventObject() {
    this.eventCopy = { ... this.event };
    this.eventCopy.startDate = DateUtils.dateFormatConvert(this.event.startDate, 'YYYY-MM-DD', 'DD-MM-YYYY');
    this.eventCopy.endDate = DateUtils.dateFormatConvert(this.event.endDate, 'YYYY-MM-DD', 'DD-MM-YYYY');
    this.eventCopy.pickUpDate = DateUtils.dateFormatConvert(this.event.pickUpDate, 'YYYY-MM-DD', 'DD-MM-YYYY');
    this.eventCopy.returnDate = DateUtils.dateFormatConvert(this.event.returnDate, 'YYYY-MM-DD', 'DD-MM-YYYY');
  }

  onDateChange(dateInputType: string) {
    // console.log('onDateChange');
    if (dateInputType === 'startDate') {
      this.eventCopy.startDate = this.currentStartDate;
      // exclude saturdays and sundays
      for (let i = 1; i <= 3; i++) {
        const currentDate = moment(this.eventCopy.startDate, 'DD-MM-YYYY').subtract(i, 'day');
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          this.eventCopy.pickUpDate = currentDate.format('DD-MM-YYYY');
          break;
        }
      }
    } else {
      // exclude saturdays and sundays
      for (let i = 1; i <= 3; i++) {
        const currentDate = moment(this.currentEndDate, 'DD-MM-YYYY').add(i, 'day');
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          this.eventCopy.returnDate = currentDate.format('DD-MM-YYYY');
          break;
        }
      }
      if (this.currentEndDate !== this.prevEndDate) {
        this.prevEndDate = this.currentEndDate;
        this.eventCopy.endDate = moment(this.currentEndDate, 'DD-MM-YYYY').add(1, 'day').format('DD-MM-YYYY');
      }
    }
  }

}

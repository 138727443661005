import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

export class Option {
  value: string;
  label: string;
}

@Component({
  selector: 'app-ng-form-group-select',
  templateUrl: './ng-form-group-select.component.html',
  styleUrls: ['./ng-form-group-select.component.css']
})
export class NgFormGroupSelectComponent implements OnInit {
  @Input()
  label: string;

  @Input()
  additionalClass: string;

  @Input()
  model: string;

  @Input()
  options: Option[];

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  name: string;

  @Input()
  id: string;

  @Input()
  error: string;

  @Input()
  formControlNameValue: string;

  @Input()
  formGroup: FormGroup;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  change() {
    console.log(this.model);
    this.modelChange.emit(this.model);
  }

}

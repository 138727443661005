import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PRICE_LISTS_ROUTES } from './price-lists.routes';
import { SharedModule } from '../shared/shared.module';
import { PriceListsComponent } from './price-lists.component';
import { AllPriceListsComponent } from './all-price-lists/all-price-lists.component';
import { ManagePriceListComponent } from './manage-price-list/manage-price-list.component';
import { CategoryTableComponent } from './manage-price-list/category-table/category-table.component';
import { ProductsTableComponent } from './manage-price-list/products-table/products-table.component';
import { AddProductFormComponent } from './manage-price-list/add-product-form/add-product-form.component';
import { EditProductFormComponent } from './manage-price-list/products-table/edit-product-form/edit-product-form.component';
// tslint:disable-next-line:max-line-length
import { ProductOptionFormComponent } from './manage-price-list/products-table/product-options/product-option-form/product-option-form.component';
// tslint:disable-next-line:max-line-length
import { ProductOptionTableComponent } from './manage-price-list/products-table/product-options/product-option-table/product-option-table.component';
// tslint:disable-next-line:max-line-length
import { ProductOptionUpdateFormComponent } from './manage-price-list/products-table/product-options/product-option-table/product-option-update-form/product-option-update-form.component';
import { ProductsBulkActionsComponent } from './manage-price-list/products-table/products-bulk-actions/products-bulk-actions.component';
import { MultipleOptionsTableComponent } from './manage-price-list/multiple-options-table/multiple-options-table.component';
import { MultipleProductOptionsComponent } from './manage-price-list/multiple-options-table/multiple-product-options/multiple-product-options.component';
import { PriceListSummeryPaperComponent } from './print-layouts/price-list-summery-paper/price-list-summery-paper.component';

@NgModule({
  imports: [
    RouterModule.forChild(PRICE_LISTS_ROUTES),
    SharedModule
  ],
  declarations: [
    PriceListsComponent,
    AllPriceListsComponent,
    ManagePriceListComponent,
    CategoryTableComponent,
    ProductsTableComponent,
    AddProductFormComponent,
    EditProductFormComponent,
    ProductOptionFormComponent,
    ProductOptionTableComponent,
    ProductOptionUpdateFormComponent,
    ProductsBulkActionsComponent,
    MultipleOptionsTableComponent,
    MultipleProductOptionsComponent,
    PriceListSummeryPaperComponent
  ],
  providers: [

  ]
})
export class PriceListsModule { }

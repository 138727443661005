import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdditionalCost, AdditionalCostsSaveOffer } from 'src/app/shared/models/AdditionalCost';
import { EventsService } from '../events.service';
import { forkJoin } from 'rxjs';
import { Event } from 'src/app/shared/models/Event';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-request-event-additional-cost',
  templateUrl: './request-event-additional-cost.component.html',
  styleUrls: ['./request-event-additional-cost.component.css']
})
export class RequestEventAdditionalCostComponent implements OnInit {

  @Input()
  event: Event;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onEventUpdate: EventEmitter<Event> = new EventEmitter<Event>();

  public additionalCostsCustomArray: {
    id: number, name: string, cost: number, typeShow: string, typeField: string, title: string,
    text: string, priceLabel: string, originalValue: number, value: number, endRow: boolean, quantity: number
  } [] = [];

  public additionalCostsArray: AdditionalCost[] = [];
  public additionalCostsAssignedArray: any[] = [];

  constructor(private _eventsService: EventsService, private _toastr: ToastrService) { }

  ngOnInit() {
    const eventId = this.event.id;
    forkJoin([
      this._eventsService.getAdditionalCosts(),
      this._eventsService.getAdditionalCostsAssigned(eventId.toString())
    ]).subscribe(result => {
      this.additionalCostsArray = [...result[0]];
      this.additionalCostsAssignedArray = [...result[1]];
      this._updateAdditionalCostObject();
    });
  }

  private _updateAdditionalCostObject () {

    this.additionalCostsArray.forEach(rec => {
      const obj: {
        id: number, name: string, cost: number, typeShow: string, typeField: string, title: string,  text: string,
        priceLabel: string, originalValue: number, value: number, endRow: boolean, quantity: number
      } = {
        id: 0, name: '', cost: 0, typeShow: '', typeField: '', title: '', text: '',
        priceLabel: '', originalValue: 0, value: 0, endRow: false, quantity: 0
      };
      const arrayFilter = this.additionalCostsAssignedArray.filter(item => item.id === rec.id);
      let quantity = 0;
      let value = 0;
      if (arrayFilter.length === 1) {
        quantity =  arrayFilter[0].quantity;
        value =  arrayFilter[0].quantity;
      }
      console.log('quantity:', quantity);
      obj.id = rec.id;
      obj.name = rec.name;
      obj.cost = rec.cost;
      obj.title = rec.name;
      switch (rec.type) {
        case 1:
          obj.typeShow = 'showPerc';
          break;
        case 2:
          obj.typeShow = 'showValue';
          break;
        case 3:
          obj.typeShow = 'showLabel';
          break;
      }
      switch (rec.name) {
        case 'beltBag':
        case 'wallet':
        case 'cashDrawer':
          obj.typeField = 'groupNumber';
          obj.quantity = 0;
          obj.value = quantity;
          break;
        case 'badWeather':
        case 'pickupInstalled':
        case 'uploadingCheckingPricelist':
        case 'training':
          obj.typeField = 'radio';
          obj.quantity = quantity;
          obj.value = quantity;
          break;
        case 'cancellation':
          obj.typeField = 'simpleText';
          break;
      }
      obj.endRow = (rec.name === 'training');
      obj.originalValue = value;
      if (rec.name !== 'cancellation') {
        this.additionalCostsCustomArray.push(obj);
      }
    });

    console.log('this.additionalCostsCustomArray', this.additionalCostsCustomArray);

  }

  public onAdditionalCostNumberInputChange(name) {
    const additionalCostObject = this.additionalCostsCustomArray.filter(item => item.name === name)[0];
    additionalCostObject.value = additionalCostObject.originalValue + additionalCostObject.quantity;
  }

  public saveData() {
    this.event.eventAdditionalCosts = [];
    this.event.additionalCosts = [];
    console.log('saveData____this.additionalCostsCustomArray', this.additionalCostsCustomArray);
    this.additionalCostsCustomArray.forEach(element => {
      if (element.value > 0) {
        const additionalCost: AdditionalCost = {
          id: element.id,
          name: element.name,
          cost: element.cost,
          type: element.name == 'badWeather' ? 1 : 2,
          quantityRequested: element.value,
        }
        const additionalCostSaveOffer: AdditionalCostsSaveOffer = {
          id: element.id,
          quantity: element.value,
        }
        this.event.eventAdditionalCosts.push(additionalCost)
        this.event.additionalCosts.push(additionalCostSaveOffer);
      }
    });
    console.log('saveData__this.event:', this.event);
    this._eventsService.updateEvent(this.event).subscribe(result => {
      this._toastr.success('Additional costs added successfully');
      this.onEventUpdate.emit(this.event);
    });
  }


}

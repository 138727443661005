import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { Company } from 'src/app/shared/models/Company';
import { CompaniesSearchCriteria } from '../shared/CompaniesSearchCriteria';
import { CompaniesService } from '../shared/companies.service';
import { DataTableColumn } from 'src/app/shared/data-table/data-table.component';
import { CompanyStatusEnum } from 'src/app/shared/enums/CompanyStatusEnum';
import { Option } from 'src/app/shared/select/select.component';

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.css']
})
export class CompaniesListComponent implements OnInit {

  public statusFilterArray: Option[] = [
    { label: 'All', value: undefined },
    { label: 'Deleted', value: CompanyStatusEnum.Deleted.toString() },
    { label: 'Enabled', value: CompanyStatusEnum.Enabled.toString() },
    { label: 'Disabled', value: CompanyStatusEnum.Disabled.toString() }
  ];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Company>> = null;
  filters: CompaniesSearchCriteria = {};

  @ViewChild('selectColumn')
  selectColumn: TemplateRef<any>;

  @ViewChild('nameColumn')
  nameColumn: TemplateRef<any>;

  @ViewChild('firstEmailColumn')
  firstEmailColumn: TemplateRef<any>;

  @ViewChild('firstPhoneColumn')
  firstPhoneColumn: TemplateRef<any>;

  @ViewChild('addressColumn')
  addressColumn: TemplateRef<any>;

  @ViewChild('villageColumn')
  villageColumn: TemplateRef<any>;

  @ViewChild('countryColumn')
  countryColumn: TemplateRef<any>;

  @ViewChild('createdAtColumn')
  createdAtColumn: TemplateRef<any>;

  @ViewChild('statusColumn')
  statusColumn: TemplateRef<any>;

  @ViewChild('actionsColumn')
  actionsColumn: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private _companiesService: CompaniesService) {
    this.loadPage = (page: number, pageSize: number) => this._companiesService.search(page, pageSize, this.filters);
  }

  ngOnInit() {
    this.columns = [
      {
        name: 'select',
        displayed: true,
        translationKey: '',
        template: this.selectColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'nameColumn',
        displayed: true,
        translationKey: 'Name',
        template: this.nameColumn,
        sortable: true
      },
      {
        name: 'firstEmailColumn',
        displayed: true,
        translationKey: 'First Email',
        template: this.firstEmailColumn,
        sortable: true
      },
      {
        name: 'firstPhoneColumn',
        displayed: true,
        translationKey: 'First Phone',
        template: this.firstPhoneColumn,
        sortable: true
      },
      {
        name: 'addressColumn',
        displayed: true,
        translationKey: 'Address',
        template: this.addressColumn,
        sortable: true
      },
      {
        name: 'villageColumn',
        displayed: true,
        translationKey: 'Village',
        template: this.villageColumn,
        sortable: true
      },
      {
        name: 'countryColumn',
        displayed: true,
        translationKey: 'Country',
        template: this.countryColumn,
        sortable: true
      },
      {
        name: 'createdAtColumn',
        displayed: true,
        translationKey: 'Created at',
        template: this.createdAtColumn,
        sortable: true
      },
      {
        name: 'statusColumn',
        displayed: true,
        translationKey: 'Status',
        template: this.statusColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actionsColumn,
        sortable: true,
        additionalClass: 'text-right'
      }
    ];
  }

  softDeleteCompany(companyId: string) {
    console.log(companyId);
  }

}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Option } from 'src/app/shared/select/select.component';
import { Event } from 'src/app/shared/models/Event';
import { EventsService } from 'src/app/events/shared/events.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-price-list-card',
  templateUrl: './price-list-card.component.html',
  styleUrls: ['./price-list-card.component.css']
})
export class PriceListCardComponent implements OnInit {

  public disableSelectPriceListButton = true;
  public disableDetailsPriceListButton = true;
  public priceListId = undefined;

  @Input()
  event: Event = new Event();

  @Input()
  eventFinished = false;

  @Input()
  unusedPriceListOptions: Option[] = [];

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPriceListAssignedEvent = new EventEmitter<void>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPriceListAssignedEventB = new EventEmitter<string>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPriceListRemovedEvent = new EventEmitter<string>();

  constructor(private _eventsService: EventsService, private _toastr: ToastrService) { }

  ngOnInit() {
  }

  priceListSelectChange() {
    if (this.priceListId !== undefined) {
      this.disableSelectPriceListButton = false;
      this.disableDetailsPriceListButton = false;
    } else {
      this.disableSelectPriceListButton = true;
      this.disableDetailsPriceListButton = true;
    }
  }

  assignPriceListToEventClick(priceListId: number) {

    if (priceListId === 0) {
      this._eventsService.assignPriceListToSpecifcEvent(this.event.id.toString(), this.priceListId).subscribe(result => {
        this.event.priceLists.push(result);
        this.onPriceListAssignedEvent.emit();
        this.priceListId = undefined;
        this.disableSelectPriceListButton = true;
        this.disableDetailsPriceListButton = true;
        this._toastr.success('Price List assigned successfully');
      }, error => {
        console.log(error);
      });
    } else {
      this._eventsService.assignPriceListToSpecifcEvent(this.event.id.toString(), priceListId.toString()).subscribe(result => {
        this.onPriceListAssignedEventB.emit(priceListId.toString());
        this.priceListId = undefined;
        this._toastr.success('Price List assigned successfully');
      }, error => {
        console.log(error);
      });
    }
  }

  unassignPriceListEventClick(priceListId: string) {
    this._eventsService.unassignPriceListFromSpecifcEvent(this.event.id.toString(), priceListId).subscribe(result => {
      this.onPriceListRemovedEvent.emit(priceListId);
      this._toastr.success('Price List unassigned successfully');
    }, error => {
      console.log(error);
    });
  }

}

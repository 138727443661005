import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-group-timepicker',
  templateUrl: './form-group-timepicker.component.html',
  styleUrls: ['./form-group-timepicker.component.css']
})
export class FormGroupTimepickerComponent implements OnInit {

  public timePcikerConfig = {
    showTwentyFourHours: true,
    showSeconds: false,
    minTime: '09:00:00',
    maxTime: '16:30:00',
    minutesInterval: 5
  };

  @Input()
  label: string;

  @Input()
  model = '09:00:00';

  @Input()
  disabled: false;

  constructor() { }

  ngOnInit() {
  }

}

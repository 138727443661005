import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'eventStatus'
})
export class EventStatusPipe implements PipeTransform {

  constructor(private _translate: TranslateService) { }

  transform(status: number, startDate?: string, endDate?: string): string {
    const today = moment();
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate).add(1, 'day');
    let humanStatus = '';
    switch (status) {
      case 0:
        humanStatus = '<span class="badge badge-pill badge-danger">' + this._translate.instant('eventStatusPipe.deleted') + '</span>';
        break;
      case 2:
        if (today >= momentStartDate && today <= momentEndDate) {
          humanStatus = '<span class="badge badge-pill badge-primary">' + this._translate.instant('eventStatusPipe.ongoing') + '</span>';
        } else if (today > momentEndDate) {
          humanStatus = '<span class="badge badge-pill badge-info">' + this._translate.instant('eventStatusPipe.finished') + '</span>';
        } else {
          humanStatus = '<span class="badge badge-pill badge-success">' + this._translate.instant('eventStatusPipe.approved') + '</span>';
        }
        break;
      case 3:
        humanStatus = '<span class="badge badge-pill badge-danger">' + this._translate.instant('eventStatusPipe.cancelled') + '</span>';
        break;
      case 4:
        humanStatus = '<span class="badge badge-pill badge-dark">Preparing</span>';
        break;
      case 5:
        humanStatus = '<span class="badge badge-pill badge-secondary">Prepered</span>';
        break;
      default:
        humanStatus = '<span class="badge badge-pill badge-warning">' + this._translate.instant('eventStatusPipe.pending') + '</span>';
        break;
    }
    return humanStatus;
  }

}

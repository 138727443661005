import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EVENTS_ROUTES } from './events.routes';
import { EventsComponent } from './events.component';
import { SharedModule } from '../shared/shared.module';
import { EventDetailsComponent } from './event-details/event-details.component';
import { AllEventsComponent } from './all-events/all-events.component';
import { EventDetailsCardComponent } from './event-details/cards/event-details-card/event-details-card.component';
import { DeviceUsersCardComponent } from './event-details/cards/device-users-card/device-users-card.component';
import { PriceListCardComponent } from './event-details/cards/price-list-card/price-list-card.component';
import { PrintersCardComponent } from './event-details/cards/printers-card/printers-card.component';
import { RoutersCardComponent } from './event-details/cards/routers-card/routers-card.component';
import { DeliveryPaperComponent } from './print-layouts/delivery-paper/delivery-paper.component';
// tslint:disable-next-line:max-line-length
import { RequestedHardwareSoftwareTableComponent } from './shared/requested-hardware-software-table/requested-hardware-software-table.component';
import { EventsCalendarComponent } from './events-calendar/events-calendar.component';
import { PreparationPaperComponent } from './print-layouts/preparation-paper/preparation-paper.component';
// tslint:disable-next-line:max-line-length
import { AssignPrinterProductCategoryComponent } from './event-details/cards/assign-printer-product-category/assign-printer-product-category.component';
// tslint:disable-next-line:max-line-length
import { CustomSwitchPrinterComponent } from './event-details/cards/assign-printer-product-category/custom-switch-printer/custom-switch-printer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RequestEventComponent } from './request-event/request-event.component';
import { EditEventCardComponent } from './event-details/cards/edit-event-card/edit-event-card.component';
// tslint:disable-next-line:max-line-length
import { RequestedHardwareSoftwareSummaryComponent } from './event-details/cards/requested-hardware-software-summary/requested-hardware-software-summary.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TranslateVillageNamePipe } from './shared/pipes/translate-village-name.pipe';
import { AdvertiseEventComponent } from './event-details/advertise-event/advertise-event.component';
import { EventLocationCardComponent } from './event-details/advertise-event/event-location-card/event-location-card.component';
import { RequestEventAdditionalCostComponent } from './shared/request-event-additional-cost/request-event-additional-cost.component';

@NgModule({
  imports: [
    RouterModule.forChild(EVENTS_ROUTES),
    SharedModule,
    NgSelectModule,
    LeafletModule
  ],
  declarations: [
    EventsComponent,
    EventDetailsComponent,
    AllEventsComponent,
    EventDetailsCardComponent,
    DeviceUsersCardComponent,
    PriceListCardComponent,
    PrintersCardComponent,
    RoutersCardComponent,
    DeliveryPaperComponent,
    RequestedHardwareSoftwareTableComponent,
    EventsCalendarComponent,
    PreparationPaperComponent,
    AssignPrinterProductCategoryComponent,
    CustomSwitchPrinterComponent,
    RequestEventComponent,
    EventLocationCardComponent,
    EditEventCardComponent,
    RequestedHardwareSoftwareSummaryComponent,
    TranslateVillageNamePipe,
    AdvertiseEventComponent,
    RequestEventAdditionalCostComponent
  ]
})
export class EventsModule { }

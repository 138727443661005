import { Component, OnInit, Input } from '@angular/core';
import { Company } from 'src/app/shared/models/Company';
import { Option } from 'src/app/shared/select/select.component';
import { Country } from 'src/app/shared/models/Country';
import { Client } from 'src/app/shared/models/Client';

@Component({
  selector: 'app-company-tab',
  templateUrl: './company-tab.component.html',
  styleUrls: ['./company-tab.component.css']
})
export class CompanyTabComponent implements OnInit {

  @Input()
  company: Company;

  @Input()
  client: Client;

  @Input()
  countrySelectOptions: Option[];

  @Input()
  createNewClientEnv: boolean;

  @Input()
  countryObjects: Country[];

  public countryId = (this.company === undefined ? 1 : this.company.country.id);

  constructor() { }

  ngOnInit() {
  }

  changeCountry() {
    console.log(this.countryObjects.filter(country => country.id === this.countryId)[0]);
    this.company.country = this.countryObjects.filter(country => country.id === this.countryId)[0];
  }

}

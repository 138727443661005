import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { ValidationUtils } from 'src/app/clients/shared/validation.utils';
import { Village } from '../../../../shared/models/Village';
import { Province } from 'src/app/shared/models/Province';
import { VillagesService } from './../shared/villages.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-new-village',
  templateUrl: './add-new-village.component.html',
  styleUrls: ['./add-new-village.component.css']
})
export class AddNewVillageComponent implements OnInit {

  private villageObj: Village;
  public villageFormGroup: FormGroup;
  public validationErrorsArray = [];

  @Input()
  filteredVillages: Village[];

  @Input()
  provincesOptions: Option[];

  @Input()
  provincesList: Province[];

  constructor(private _villagesService: VillagesService,
    private _formBuilder: FormBuilder, private _toastr: ToastrService, private _translate: TranslateService) {
    this.villageFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      provinceId: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  onVillageFormSubmit() {
    if (this.villageFormGroup.valid) {
      this.validationErrorsArray = [];
      this.villageObj = Village.fromDto(this.villageFormGroup.value);
      this.villageObj.province = this.provincesList.filter(province => province.id.toString() === this.villageFormGroup.value.provinceId)[0];
      this._villagesService.insertVillage(this.villageObj).subscribe(result => {
        this.filteredVillages.push(result);
        this._toastr.success(this._translate.instant('Village added with success'));
      }, error => {
        console.log(error.status);
      });
    }
    this.validationErrorsArray = ValidationUtils.getFormValidationErrors(this.villageFormGroup, 'village');
  }
}

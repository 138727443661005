import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { Client } from 'src/app/shared/models/Client';
import { Option } from 'src/app/shared/select/select.component';
import { Company } from 'src/app/shared/models/Company';

@Component({
  selector: 'app-client-tab',
  templateUrl: './client-tab.component.html',
  styleUrls: ['./client-tab.component.css']
})
export class ClientTabComponent implements OnInit, OnChanges {

  public newClientPassword = '';
  public clientCompanyActionArray: Option[] = [
    {value: 'no', label: 'insert-client-company.no-company'},
    {value: 'new', label: 'insert-client-company.new-company'},
    {value: 'search', label: 'Search Company'}
  ];
  public hideCompanySearch = true;

  @Input()
  client: Client;

  @Input()
  company: Company;

  @Input()
  selectedCompanyAction = 'no';

  @Input()
  selectedParentAdminAction = undefined;

  @Input()
  createNewClientEnv: boolean;

  @Output()
  enableCompanySection: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  getSelectedCompany: EventEmitter<Company> = new EventEmitter<Company>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('CLIENT TAB CHANGES');
    console.log(changes);
    if (this.selectedCompanyAction === 'search') {
      this.hideCompanySearch = false;
    }
    if (changes.selectedCompanyAction) {
      if (changes.selectedCompanyAction.currentValue === 'search') {
        this.selectedCompanyAction = 'search';
        this.hideCompanySearch = false;
      } else {
        this.selectedCompanyAction = changes.selectedCompanyAction.currentValue;
        this.hideCompanySearch = true;
      }
    }
    console.log(this.selectedCompanyAction);
  }

  onClientNewPasswordChange() {
    this.client.password = this.newClientPassword;
  }

  usernameCreation() {
    if (this.createNewClientEnv) {
      const clientFirstName = this.client.firstName;
      const clientLastName = this.client.lastName;
      this.client.username = (clientFirstName !== undefined ? clientFirstName.toLowerCase() + '.' : '') +
        (clientLastName !== undefined ? clientLastName.toLowerCase() : '');
    }
  }

  companyActionSelectBoxChange() {
    if (this.selectedCompanyAction === 'search') {
      this.hideCompanySearch = false;
    } else {
      this.hideCompanySearch = true;
    }
    this.enableCompanySection.emit(this.selectedCompanyAction);
  }

  onCompanySelectedEvent(item: Company) {
    console.log(item);
    this.enableCompanySection.emit('search');
    this.getSelectedCompany.emit(Company.fromDto(item));
  }

}

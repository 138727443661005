import { Injectable } from '@angular/core';
import { HttpUtils } from 'src/app/shared/http-utils';
import { HttpClient } from '@angular/common/http';
import { URL_BASE } from 'src/app/core/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Village } from 'src/app/shared/models/Village';

@Injectable({
  providedIn: 'root'
})
export class VillagesService {

  private _headers: any;

  constructor(private _http: HttpClient) { }

  public getAllVillages(): Observable<Village[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/villages/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Village.fromDto(res)))
    );
  }

  public insertVillage(village: Village): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/village/insert', { village: village, returnAll: false }, { headers }).pipe(
      map((res: Village) => {
        return res;
      }));
  }

  public updateVillage(village: Village): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/village/update', { village: village, returnAll: true }, { headers }).pipe(
      map((res: Village) => {
        return res;
      }));
  }

  public deleteVillage(villageId: number): Observable<Village> {
    const headers = HttpUtils.createHeaders();
    return this._http.delete(URL_BASE + '/admin/village/delete', {
      headers, params: { id: villageId.toString(), force: '1' }
    }).pipe(
      map((res: any) => Village.fromDto(res)));
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from 'src/app/shared/models/Router';
import { RoutersSearchCriteria } from './RoutersSearchCriteria';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { HttpUtils } from 'src/app/shared/http-utils';
import { URL_BASE } from 'src/app/core/constants';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RouterType } from 'src/app/shared/models/RouterType';
import { RouterManufacturer } from 'src/app/shared/models/RouterManufacturer';

@Injectable({
  providedIn: 'root'
})
export class RoutersService {

  public routerssArray: any[] = [];

  constructor(private _http: HttpClient) { }

  public search(page: number, size: number, criteria: RoutersSearchCriteria): Observable<SearchObjectResult<Router>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(URL_BASE + '/admin/routers/search', { headers, params }).pipe(
      map((res: any) => {
        this.routerssArray = res;

        return SearchObjectResult.fromDto(res, Router.fromDto);
      })
    );
  }

  // get all routers
  public getAllRouters(): Observable<Router> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/router/get', { headers }).pipe(
      map((res: Router) => {
        return res;
      }));
  }
  // get RouterType
  public getAllRouterTypes(): Observable<RouterType[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin//routerType/get', { headers }).pipe(
      map((res: RouterType[]) => {
        return res;
      }));
  }

  // routers by Id
  public getAllRoutersById(routerId: string): Observable<Router> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/router/get', { headers, params: { id: routerId } }).pipe(
      map((res: Router) => {
        return res;
      }));
  }

  // update device informations
  public updateRouter(myRouter: Router): Observable<any> {
    const headers = HttpUtils.createHeaders();
    console.log(myRouter);
    return this._http.put(URL_BASE + '/admin/router/update', { router: myRouter }, { headers }).pipe(
      map((res: Router) => {
        return res;
      }));
  }

  // insert router informations
  public insertRouter(router: Router): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/router/insert', { router: router }, { headers }).pipe(
      map((res: Router) => {
        return res;
      }));
  }

  // delete router
  public deleteRouter(id: string, force: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.delete(URL_BASE + '/admin/router/delete', {
      headers,
      params: { id, force }
    }).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }));
  }

    // get routerTypes
    public getRouterTypeInformationsById(routerTypeId: string): Observable<RouterType> {
      const headers = HttpUtils.createHeaders();
      return this._http.get(URL_BASE + '/admin/routerType/get', { headers, params: { id: routerTypeId } }).pipe(
        map((res: RouterType) => {
          return res;
        }));
    }


  public updateRouterTypeInformations(routerType: RouterType): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/routerType/update', { routerType: routerType }, { headers }).pipe(
      map((res: RouterType) => {
        return res;
      }));
  }

  public insertRouterTypeInformations(routerType: RouterType): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/routerType/insert', { routerType: routerType }, { headers }).pipe(
    map((res: RouterType) => {
      return res;
    }));
  }

    // delete router
    public deleteRouterType(id: string, force: string): Observable<any> {
      const headers = HttpUtils.createHeaders();
      return this._http.delete(URL_BASE + '/admin/routerType/delete', {
        headers,
        params: { id, force }
      }).pipe(
        map((res: any) => {
          console.log(res);
          return res;
        }));
    }

    public getRouterManufacturers(): Observable<RouterManufacturer[]> {
      const headers = HttpUtils.createHeaders();
      return this._http.get(URL_BASE + '/admin/routerManufacturer/get', { headers }).pipe(
        map((res: RouterManufacturer[]) => {
          return res;
        }));
    }


}

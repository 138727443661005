import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Region } from 'src/app/shared/models/Region';
import { Country } from 'src/app/shared/models/Country';
import { RegionsService } from './shared/regions.service';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.css']
})
export class RegionsComponent implements OnInit {

  public choosenEditRegion: Region = new Region();
  private _choosenRegionId: number;

  @Input()
  regionsList: Region[];

  @Input()
  countriesList: Country[];

  @Input()
  countriesOptions: Option[];

  @ViewChild('confirmDeleteRegionDialog')
  confirmDeleteRegionDialog;

  constructor(private _regionsService: RegionsService, private _toastr: ToastrService) { }

  ngOnInit() {
  }

  resetRegionsList(regionsListResponse: Region[]) {
    this.regionsList = regionsListResponse ;
  }

  enableEditRegion(region: Region) {
    this.choosenEditRegion = { ...region };
  }

  cancelRegionEditMode() {
    this.choosenEditRegion = new Region();
  }

  getDeleteRegionDecision(decision: boolean) {
    if (decision) {
      this._regionsService.deleteRegion(this._choosenRegionId).subscribe(result => {
      const index = this.regionsList.findIndex(region => region.id === this._choosenRegionId);
      this.regionsList.splice(index, 1);
        this._toastr.success('Region successfully deleted');
      }, error => {
        console.log(error);
      });
    }
    this._choosenRegionId = undefined;
  }

  showConfirmDeleteDialog(regionId: number) {
    this._choosenRegionId = regionId;
    this.confirmDeleteRegionDialog.openDialog();
  }

}

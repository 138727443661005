import { Pipe, PipeTransform } from '@angular/core';
import { PrinterStatusEnum } from '../enums/PrinterStatusEnums';

@Pipe({
  name: 'printerStatus',
})
export class PrinterStatusPipe implements PipeTransform {

  transform(printerTypeStatus: any): any {
    if (printerTypeStatus === PrinterStatusEnum.Available) {
      return 'Available';
    }
    if (printerTypeStatus === PrinterStatusEnum.Damaged) {
      return 'Damaged';
    }
    if (printerTypeStatus === PrinterStatusEnum.Rented) {
      return 'Rented';
    } else {
      return 'Deleted';
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { EventsService } from 'src/app/events/shared/events.service';
import { ToastrService } from 'ngx-toastr';
import { Event } from 'src/app/shared/models/Event';

@Component({
  selector: 'app-event-details-card',
  templateUrl: './event-details-card.component.html',
  styleUrls: ['./event-details-card.component.css']
})
export class EventDetailsCardComponent implements OnInit {

  @Input()
  event: Event;

  constructor(private _eventsService: EventsService, private _toastr: ToastrService) { }

  ngOnInit() {
  }
}

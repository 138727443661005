import {Component, Input, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-data-table-column',
  templateUrl: './data-table-column.component.html',
  styleUrls: ['./data-table-column.component.css']
})
export class DataTableColumnComponent implements OnInit {

  @Input()
  template: TemplateRef<any>;

  @Input()
  item: any;

  @Input()
  additionalClass: string;

  constructor() {
  }

  ngOnInit() {
  }
}

import { DeviceType } from './DeviceType';
import { DeviceUser } from './DeviceUser';
import { DeviceManufacturers } from './DeviceManufacturers';

export class Device {
    public id: number;
    public name: string;
    public serialeNumber: string;
    public status: number; // "0 = Deleted - 1 = Available - 2 = Rented - 3 = Damaged"
    public notes: string;
    public deviceManufacturer: DeviceManufacturers;
    public deviceType: DeviceType;
    public deviceUsers: DeviceUser[] = [];
    public deviceAssignedCount: string;
    public assignedEvent: string;

    static fromDto(d): Device {
        const device = new Device();

        if (!d) {
            return null;
        }

        device.id = d.id;
        device.name = d.name;
        device.serialeNumber = d.serialeNumber;
        device.status = d.status;
        device.notes = d.notes;
        device.deviceManufacturer = d.deviceManufacturer;
        device.deviceType = d.deviceType;
        device.deviceUsers = d.deviceUsers;
        device.deviceAssignedCount = d.deviceAssignedCount;
        device.assignedEvent = d.assignedEvent;

        return d;
    }

}

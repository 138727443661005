import { Pipe, PipeTransform } from '@angular/core';
import { ClientStatusEnum } from '../enums/ClientStatusEnum';

@Pipe({
  name: 'clientStatusBadge'
})
export class ClientStatusBadgePipe implements PipeTransform {

  transform(status: any): any {
    let badgeTemplate = '';
    switch (status) {
      case ClientStatusEnum.Enabled:
        badgeTemplate = '<span class="badge badge-pill badge-success">Enabled</span>';
        break;
      case ClientStatusEnum.Disabled:
        badgeTemplate = '<span class="badge badge-pill badge-warning">Disabled</span>';
        break;
      case ClientStatusEnum.RecoveryMode:
        badgeTemplate = '<span class="badge badge-pill badge-secondary">Recovery Mode</span>';
        break;
      case ClientStatusEnum.Registration:
        badgeTemplate = '<span class="badge badge-pill badge-info">Registration</span>';
        break;
      case ClientStatusEnum.Deleted:
        badgeTemplate = '<span class="badge badge-pill badge-danger">Deleted</span>';
        break;
      default:
        badgeTemplate = 'No Status';
        break;
    }
    return badgeTemplate;
  }

}

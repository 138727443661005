import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Client} from '../../shared/models/Client';
import {Company} from '../../shared/models/Company';
import {Option} from '../../shared/select/select.component';
import {ClientsService} from '../shared/clients.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ValidationUtils} from '../../shared/validation.utils';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-view-client',
  templateUrl: './view-client.component.html',
  styleUrls: ['./view-client.component.css']
})
export class ViewClientComponent implements OnInit {

  public clientForm: FormGroup;
  public companyForm: FormGroup;
  public clientErrorArray = new Array();
  public companyErrorArray = new Array();
  public _newClient: Client = new Client();
  public _newCompany: Company = new Company();
  public clientCompanyActionArray: Option[] = [
    {value: 'no', label: 'insert-client-company.no-company'},
    {value: 'new', label: 'insert-client-company.new-company'},
    {value: 'search', label: 'Search Company'}
  ];
  public selectedCompanyAction = 'no';
  public selectedCountry = 1;
  public clientId = null;
  public hideCompanySearch = true;
  public clientCountryArray: Option[] = [];


  constructor(private _clientsService: ClientsService, private _toastr: ToastrService, private _translate: TranslateService,
              private _formBuilder: FormBuilder, private _activatedRoute: ActivatedRoute) {
    this.clientForm = this._formBuilder.group({
      firstName: ['', [<any>Validators.required, <any>Validators.minLength(5)]],
      lastName: ['', [<any>Validators.required, <any>Validators.minLength(5)]],
      firstEmail: ['', <any>Validators.email],
      secondEmail: ['', <any>Validators.email],
      firstPhone: ['', <any>Validators.required],
      secondPhone: ['', <any>Validators.minLength(5)],
      newPassword: ['', <any>Validators.minLength(5)],
      repeatPassword: [''],
      company: [null],
      companyAction: [''],
      notes: [''],
      language: ['de', [<any>Validators.required]],
      newsletter: [false]
    }, {
      validator: ValidationUtils.checkIfMatchingPasswords('newPassword', 'repeatPassword', false)
    });

    this.companyForm = this._formBuilder.group({
      name: ['', [<any>Validators.required, <any>Validators.minLength(5)]],
      firstEmail: ['', [<any>Validators.required, <any>Validators.email]],
      secondEmail: ['', <any>Validators.email],
      firstPhone: ['', <any>Validators.required],
      secondPhone: [''],
      fiscalCode: [''],
      vat: [''],
      pecEmail: ['', <any>Validators.email],
      isdNumber: [''],
      address: ['', <any>Validators.required],
      postalCode: ['', <any>Validators.required],
      city: ['', <any>Validators.required],
      country: ['', <any>Validators.required]
    }, {
      // validator: this.checkFiscalCodeAndVat('fiscalCode', 'vat')
    });
    this.disableEdit();
    // console.log(this.companyForm.controls.name.disable());
  }

  ngOnInit() {
    this._clientsService.getAllCountries().subscribe(result => {
      console.log(result);
      result.forEach(country => {
        this.clientCountryArray.push({
          value: country.id,
          label: country.name
        });
      });
    }, error => {
      console.log(error);
    });
    this._activatedRoute.params.subscribe(params => {
      this.clientId = params['clientId'];
      console.log(this.clientId);
      this._clientsService.getClientCompanyInformationsById(this.clientId).subscribe( results => {
        console.log(results);
        this.clientForm.patchValue(results);
        this.companyForm.patchValue(results.company);
        this.companyForm.patchValue({country: results.company.country.id});
      });
    });
  }

  disableEdit() {
    this.clientForm.controls.firstName.disable();
    this.clientForm.controls.lastName.disable();
    this.clientForm.controls.firstEmail.disable();
    this.clientForm.controls.secondEmail.disable();
    this.clientForm.controls.firstPhone.disable();
    this.clientForm.controls.secondPhone.disable();
    this.clientForm.controls.notes.disable();

    this.companyForm.controls.name.disable();
    this.companyForm.controls.firstEmail.disable();
    this.companyForm.controls.secondEmail.disable();
    this.companyForm.controls.firstPhone.disable();
    this.companyForm.controls.secondPhone.disable();
    this.companyForm.controls.fiscalCode.disable();
    this.companyForm.controls.vat.disable();
    this.companyForm.controls.pecEmail.disable();
    this.companyForm.controls.isdNumber.disable();
    this.companyForm.controls.address.disable();
    this.companyForm.controls.postalCode.disable();
    this.companyForm.controls.city.disable();
    this.companyForm.controls.country.disable();
  }
}

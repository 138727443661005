import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventOffer } from 'src/app/shared/models/EventOffer';
import { HttpUtils } from 'src/app/shared/http-utils';
import { URL_BASE } from 'src/app/core/constants';
import { EventCost } from 'src/app/shared/models/EventCost';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { EventOfferSearchCriteria } from './EventOfferSearchCriteria';
import { AdditionalCost } from 'src/app/shared/models/AdditionalCost';

class HardwareCount {
  public printerCount: number;
  public routerCount: number;
  public tabletCount: number;
  public phoneCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private _http: HttpClient) { }

  /**
   * get all the companies from the database
   * ERROR 600 -> No companies in the database
   */
  public search(page: number, size: number, criteria: EventOfferSearchCriteria): Observable<SearchObjectResult<EventOffer>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(URL_BASE + '/admin/eventOffer/search', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, EventOffer.fromDto);
      })
    );
  }

  /**
   * insertEventOffer
   */
  public insertEventOffer(eventOffer: EventOffer, sendEmailToAdmin: boolean,
    sendEmailToOrganization: boolean, emailToUse: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/eventOffer/insert',
      { eventOffer, sendEmailToAdmin, sendEmailToOrganization, emailToUse }, { headers }).pipe(
        map((res: any) => res));
  }

  /**
   * updateEventOffer
   */
  public updateEventOffer(eventOffer: EventOffer): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/eventOffer/update', { eventOffer }, { headers }).pipe(
      map((res: any) => res));
  }

  /**
   * calculateEventOfferPrice
   */
  public calculateEventOfferPrice(eventOffer: EventOffer, days: number, pickupPointId: number, returnObject: boolean): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/admin/eventOffer/calculatePrice', { eventOffer, days, pickupPointId }, { headers: headers }).pipe(
      map((res: any) => (returnObject ? EventCost.fromDto(res) : EventCost.fromDtoPrice(res))));
  }

  /**
   * getEventOfferById
   */
  public getEventOfferById(eventOfferId: string): Observable<EventOffer> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/eventOffer/get', { headers: headers, params: { eventOfferId } }).pipe(
      map((result: any) => EventOffer.fromDto(result))
    );
  }
  /**
   * getEventOfferByLongId
   */
  public getEventOffersByLongId(eventOfferLongId: string): Observable<EventOffer[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/eventOffer/getOffersByLongId', { headers: headers, params: { eventOfferLongId } }).pipe(
      map((result: any[]) => result.map(res => EventOffer.fromDto(res)))
    );
  }

  /**
   * getEventOfferById
   */
  public changeEventOfferStatus(eventOfferId: string, status: string, negativeNotes: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/eventOffer/changeStatus', {
      headers: headers, params:
        { eventOfferId, status, negativeNotes }
    }).pipe(
      map((result: any) => result)
    );
  }

  public downloadEventOfferPdf(offerId: string, versionLetter: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/admin/eventOffer/download', { offerId, versionLetter }, {
      responseType: 'blob',
      headers: headers
    });
  }
  /**
   * deleteEventOffer
   */
  public deleteEventOffer(offerId: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.delete(URL_BASE + '/admin/eventOffer/delete', {
      headers: headers, params: { offerId }, reportProgress: true
    }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * getEventOffersByMonth
   */
  public getEventOffersByMonth(month: string, year: string): Observable<EventOffer[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/eventOffer/getEventOffersByMonth', { headers: headers, params: { month, year } }).pipe(
      map((result: any[]) => result.map(res => EventOffer.fromDto(res)))
    );
  }

  /**
   * convertEventOfferToEvent
   */
  public convertEventOfferToEvent(eventOfferId: string, sendConvertionEmail: boolean): Observable<EventOffer> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/eventOffer/convertToEvent',
      { eventOfferId, sendEmail: sendConvertionEmail }, { headers }).pipe(
        map((result: any) => EventOffer.fromDto(result))
      );
  }

  /**
   * getEventOffersByMonth
   */
  public sendEventConfermationEmail(id: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/eventOffer/sendEventConfermationEmail', { headers: headers, params: { id } }).pipe(
      map((result: any) => result)
    );
  }


  /**
   * getAviableHardwareCount
   */
  public getAviableHardwareCount(): Observable<HardwareCount> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/hardware/getAviableHardwareCount', { headers: headers }).pipe(
      map((result: any) => result)
    );
  }

  public updateEventOfferPickupReturnDate(eventOfferId: string, pickUpDate: string, returnDate: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/eventOffer/updatePickupReturnDate', {
      headers: headers,
      params: { eventOfferId, pickUpDate, returnDate }
    }).pipe(
      map((result: any) => result)
    );
  }

  /**
   * updateEventOfferNotes
   */
  public updateEventOfferNotes(eventOfferId: string, notes: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/eventOffer/updateNotes', { eventOfferId, notes }, { headers }).pipe(
      map((result: any) => result)
    );
  }

  /**
  * getAdditionalCosts
  */
  public getAdditionalCosts(): Observable<AdditionalCost[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/additionalCosts/get', { headers }).pipe(
      map((result: any[]) => result.map(res => AdditionalCost.fromDto(res)))
    );
  }

  /**
  * getAdditionalCostsAssigned
  */
  public getAdditionalCostsAssigned(eventOfferId: string): Observable<any[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/additionalCosts/getAssigned', { headers: headers, params: { eventOfferId } }).pipe(
      map((result: any[]) => result)
    );
  }

}

import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { ClientsComponent } from './clients.component';
import { ClientListComponent } from './client-list/client-list.component';
import { UpdateClientComponent } from './update-client/update-client.component';

export const CLIENTS_ROUTES: Routes = [
  {
    path: 'clients', component: ClientsComponent, canActivate: [AuthGuard], children: [
      { path: '', component: ClientListComponent },
      { path: ':methodType', component: UpdateClientComponent, canActivate: [AuthGuard] },
      { path: ':methodType/:clientId', component: UpdateClientComponent, canActivate: [AuthGuard] },
      { path: '**', redirectTo: 'clients' }
    ]
  }
];

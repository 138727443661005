
import * as moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class DatePickerUtils {

    public static fromModel(date: moment.Moment): NgbDateStruct {
        if (!date) {
            return null;
        }
        return { day: date.date(), month: date.month() + 1, year: date.year() };
    }

    public static toModel(date: NgbDateStruct): moment.Moment {
        if (!date) {
            return null;
        }
        return moment(date.day + '-' + date.month + '-' + date.year, 'DD-MM-YYYY');
    }
}

import { RequestedRouterType } from './RequestedRouterType';
import { RouterManufacturer } from './RouterManufacturer';
import { Router } from './Router';

export class RouterType {
    public id: number;
    public name: number;
    public price: string;
    public status: number;
    public routers: Router[] = [];
    public requestedRouterTypes: RequestedRouterType;
    public routerManufacturer: RouterManufacturer;

    static fromDto(r): RouterType {
        const routerType = new RouterType();

        if (!r) {
            return null;
        }

        routerType.id = r.id;
        routerType.name = r.name;
        routerType.price = r.price;
        routerType.status = r.status;
        routerType.requestedRouterTypes = r.requestedRouterTypes;
        routerType.routerManufacturer = r.routerManufacturer;

        if(r.routers) {
            routerType.routers = r.routers.map(router => Router.fromDto(router));
        }
        routerType.routerManufacturer = RouterManufacturer.fromDto(r.printerManufacturers);
        return r;
    }
}

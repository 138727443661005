import { Component, OnInit, Input } from '@angular/core';
import { Country } from 'src/app/shared/models/Country';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.css']
})

export class CountriesComponent implements OnInit {

  @Input()
  countriesList: Country[];

  constructor() { }

  ngOnInit() {
  }
}


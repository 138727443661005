import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtils } from './http-utils';
import { URL_BASE } from '../core/constants';
import { map } from 'rxjs/operators';
import { Village } from './models/Village';
import { Province } from './models/Province';
import { Region } from './models/Region';
import { Country } from './models/Country';
import { Event } from './models/Event';
import { PriceCalculation } from './request-event-hardware-software/request-event-hardware-software.component';
import { EventCost } from './models/EventCost';
import * as moment from 'moment';
import { PickupPoint } from './models/PickupPoint';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private _http: HttpClient) { }

  /**
   * get all the villages from the database
   * ERROR 600 -> No villages in the database
   */
  public getAllVillages(): Observable<Village[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/villages/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Village.fromDto(res)))
    );
  }
  /**
   * get all the provinces from the database
   * ERROR 601 -> No provinces in the database
   */
  public getAllProvinces(): Observable<Province[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/provinces/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Province.fromDto(res)))
    );
  }
  /**
   * get all the regions from the database
   * ERROR 600 -> No regions in the database
   */
  public getAllRegions(): Observable<Region[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/regions/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Region.fromDto(res)))
    );
  }
  /**
   * get all the countries from the database
   * ERROR 600 -> No countries in the database
   */
  public getAllCountries(): Observable<Country[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/countries/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Country.fromDto(res)))
    );
  }

  /**
  * getPickupPoints
  */
  public getPickupPoints(): Observable<PickupPoint[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/pickupPoints/get', { headers }).pipe(
      map((result: any[]) => result.map(res => PickupPoint.fromDto(res)))
    );
  }

  public calculateEventPrice(event: Event, startDate, endDate, returnObject: boolean): Observable<any> {
    startDate = moment(moment(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
    endDate = moment(moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
    const duration = moment.duration(endDate.diff(startDate));
    const priceRequest: PriceCalculation = new PriceCalculation();
    priceRequest.days = duration.asDays() + 1;
    priceRequest.countryId = event.clientUser.company.country.id;
    priceRequest.requestedCables = event.requestedCable;
    priceRequest.requestedDevices = event.requestedDevices;
    priceRequest.requestedPrinterTypes = event.requestedPrinterTypes;
    priceRequest.requestedRouterTypes = event.requestedRouterTypes;
    priceRequest.requestedSoftwareTypes = event.requestedSoftwareTypes;
    priceRequest.requestedUps = event.requestedUps;
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/admin/event/calculatePrice', { event: priceRequest }, { headers: headers }).pipe(
      map((res: any) => (returnObject ? EventCost.fromDto(res) : EventCost.fromDtoPrice(res))));
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PrinterStatusEnum } from 'src/app/shared/enums/PrinterStatusEnums';
import { PrinterType } from 'src/app/shared/models/PrinterType';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { PrintersService } from '../shared/printers.service';
import { Printer } from 'src/app/shared/models/Printer';
import { PrintersSearchCriteria } from '../shared/PrintersSearchCriteria';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ValidationUtils } from 'src/app/clients/shared/validation.utils';

@Component({
  selector: 'app-update-printer',
  templateUrl: './update-printer.component.html',
  styleUrls: ['./update-printer.component.css']
})
export class UpdatePrinterComponent implements OnInit {

  filters: PrintersSearchCriteria = {};

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Printer>> = null;

  private _method: string;
  private _printerId: string;
  public printer: Printer;
  public printerFormGroup: FormGroup;
  public printerTypes: PrinterType[] = [];
  public validationErrorsArray = [];

  public printerStatusArray: Option[] = [
    { value: PrinterStatusEnum.Available, label: 'Available' },
    { value: PrinterStatusEnum.Damaged, label: 'Damaged' },
    { value: PrinterStatusEnum.Deleted, label: 'Deleted' },
    { value: PrinterStatusEnum.Rented, label: 'Rented' },
  ];

  constructor(private _toastr: ToastrService, private _printerService: PrintersService, private _route: ActivatedRoute,
    private _router: Router, private _formBuilder: FormBuilder) {

    this.printerFormGroup = this._formBuilder.group({
      id: [''],
      identifier: ['', Validators.required],
      name: ['', Validators.required],
      status: [1, Validators.required],
      printerType: [, Validators.required],
      notes: ['']
    });
  }


  ngOnInit() {
    this._route.params.subscribe(params => {
      this._method = params['methodType'];
      this._printerId = params['printerId'];
      this._printerService.getAllPrinterTypes().subscribe(result => {
        this.printerTypes = result;
      });
      if (this._method !== 'update' && this._method !== 'insert') {
        this._router.navigate(['/hardware/printers']);
      }
      if (this._method === 'update') {
        this._printerService.getPrinterInformationsById(this._printerId).subscribe(result => {
          this.printerFormGroup.patchValue(result);
          this.printerFormGroup.get('printerType').setValue(result.printerType.id);
        });
      }
    });
  }

  onPrinterFormsSubmit() {
    if (this.printerFormGroup.valid) {
      this.printerFormGroup.value.printerType = this.printerTypes.filter(printerType =>
        printerType.id.toString() === this.printerFormGroup.value.printerType.toString())[0];
      this.printer = Printer.fromDto(this.printerFormGroup.value);
      this.printer.notes = (this.printer.notes !== '') ? this.printer.notes : null;
      this._printerService.updatePrinterInformations(this.printer).subscribe(result => {
        this._toastr.success((this._method === 'update' ? 'Printer updated with success' : 'Printer inserted with success'));
        if (this._method === 'insert') {
          this.printerFormGroup.reset({ status: 1 });
        }
      });
    }
    this.validationErrorsArray = ValidationUtils.getFormValidationErrors(this.printerFormGroup, 'printer');
  }
}


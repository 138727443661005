import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OFFERS_ROUTES } from './offers.routes';
import { SharedModule } from '../shared/shared.module';
import { OffersComponent } from './offers.component';
import { OffersListComponent } from './offers-list/offers-list.component';
import { CreateOfferComponent } from './create-offer/create-offer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { OfferDetailsComponent } from './offer-details/offer-details.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(OFFERS_ROUTES),
    SharedModule,
    NgSelectModule
  ],
  declarations: [
    OffersComponent,
    OffersListComponent,
    CreateOfferComponent,
    OfferDetailsComponent
  ],
  providers: [
  ]
})
export class OffersModule { }

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { Device } from 'src/app/shared/models/Device';
import { DataTableColumn } from 'src/app/shared/data-table/data-table.component';
import { DevicesService } from '../shared/devices.service';
import { DevicesSearchCriteria } from '../shared/DevicesSearchCriteria';
import { ToastrService } from 'ngx-toastr';
import { DeviceType } from 'src/app/shared/models/DeviceType';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { DeviceStatusEnum } from 'src/app/shared/enums/DeviceStatusEnum';
import { DeviceManufacturers } from 'src/app/shared/models/DeviceManufacturers';
import { DomSanitizer } from '@angular/platform-browser';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-devices-list',
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.css']
})
export class DevicesListComponent implements OnInit {

  public defaultStartDate = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  public defaultEndDate = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  public defaultPageSize = '99999';

  public pageSizeOptionArray: Option[] = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '10' },
    { value: '99999', label: 'All' },
  ];

  filters: DevicesSearchCriteria = {startdate: this.defaultStartDate, enddate: this.defaultEndDate, pageSize: this.defaultPageSize};

  public deviceManufacturersVals: DeviceManufacturers[] = [];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Device>> = null;

  public deviceStatusArray: Option[] = [
    { value: undefined, label: 'All' },
    { value: DeviceStatusEnum.Deleted, label: 'Deleted' },
    { value: DeviceStatusEnum.Available, label: 'Available' },
    { value: DeviceStatusEnum.Rented, label: 'Rented' },
    { value: DeviceStatusEnum.Damaged, label: 'Damaged' },
  ];

  @ViewChild('id')
  id: TemplateRef<any>;

  @ViewChild('name')
  name: TemplateRef<any>;

  @ViewChild('deviceManufacturer')
  deviceManufacturer: TemplateRef<any>;

  @ViewChild('deviceType')
  deviceType: TemplateRef<any>;

  @ViewChild('notes')
  notes: TemplateRef<any>;

  @ViewChild('status')
  status: TemplateRef<any>;

  @ViewChild('deviceAssignedCount')
  deviceAssignedCount: TemplateRef<any>;

  @ViewChild('assignedEvent')
  assignedEvent: TemplateRef<any>;

  @ViewChild('actionsColumn')
  actionsColumn: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private _devicesService: DevicesService, private _toastr: ToastrService, private _sanitizer: DomSanitizer) {
    this.loadPage = (page: number, pageSize: number) => this._devicesService.search(page, pageSize, this.filters);
  }

  ngOnInit() {
    this._devicesService.getAllDeviceManufacturers().subscribe(result => {
      this.deviceManufacturersVals = result;
    });
    this.columns = [
      {
        name: 'name',
        displayed: true,
        translationKey: 'name',
        template: this.name,
        sortable: true
      },
      {
        name: 'deviceType',
        displayed: true,
        translationKey: 'Device Type',
        template: this.deviceType,
        sortable: true
      },
      {
        name: 'status',
        displayed: true,
        translationKey: 'status',
        template: this.status,
        sortable: true
      },
      {
        name: 'deviceManufacturer',
        displayed: true,
        translationKey: 'Device Manufacturer',
        template: this.deviceManufacturer,
        sortable: true
      },
      {
        name: 'notes',
        displayed: true,
        translationKey: 'Notes',
        template: this.notes,
        sortable: true
      },
      {
        name: 'deviceAssignedCount',
        displayed: true,
        translationKey: 'Ass. Count',
        template: this.deviceAssignedCount,
        sortable: false
      },
      {
        name: 'assignedEvent',
        displayed: true,
        translationKey: 'Ass. Event',
        template: this.assignedEvent,
        sortable: false
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actionsColumn,
        sortable: true,
        additionalClass: 'text-right'
      }
    ];
  }
  softDeleteDevice(id: string, force: string) {
    this._devicesService.deleteDevice(id, force).subscribe(result => {
      this.loadPage = (page: number, pageSize: number) => this._devicesService.search(page, pageSize, this.filters);
      this._toastr.success('Device succesfully deleted');
    });
  }

  showAssignedEvent (eventString: string): any {
    if (eventString) {
      const html = this._sanitizer.bypassSecurityTrustHtml(eventString.replace(/,/g, '<br />'));
      return html;
    } else {
      return '';
    }
  }
  /*
  softDeviceTypeDelete(id: string, force: string) {
    this._devicesService.deleteDeviceTypeApi(id, force).subscribe(deleteResult => {
      this._devicesService.getAllDeviceTypes().subscribe(result => {
        //this.deviceManufacturersVals = result;
        this._toastr.success('Device type succesfully deleted');
      });
    });
  }
  */
}

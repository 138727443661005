import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PrintersSearchCriteria } from './PrintersSearchCriteria';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { Printer } from 'src/app/shared/models/Printer';
import { Observable } from 'rxjs';
import { HttpUtils } from 'src/app/shared/http-utils';
import { URL_BASE } from 'src/app/core/constants';
import { map } from 'rxjs/operators';
import { PrinterType } from 'src/app/shared/models/PrinterType';
import { PrinterManufacturer } from 'src/app/shared/models/PrinterManufacturers';



@Injectable({
  providedIn: 'root'
})
export class PrintersService {
  private _headers: any;

  constructor(private _http: HttpClient) {
    this._headers = HttpUtils.createHeaders();
  }

  public search(page: number, size: number, criteria: PrintersSearchCriteria): Observable<SearchObjectResult<Printer>> {


    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }

    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };

    console.log('params: ', params);

    return this._http.get(URL_BASE + '/admin/printers/search', { headers, params }).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, Printer.fromDto);
      })
    );
  }


  public deletePrinter(id: string, force: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/printer/delete', {
      headers,
      params: { id, force }
    }).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }));
  }

  public getPrinterInformationsById(printerId: string): Observable<Printer> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/printer/get', { headers, params: { id: printerId } }).pipe(
      map((res: any) => Printer.fromDto(res)));
  }

  public getPrinterTypeInformationsById(printerTypeId: string): Observable<PrinterType> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/printerType/get', { headers, params: { id: printerTypeId } }).pipe(
      map((res: any) => PrinterType.fromDto(res)));
  }

  public getAllPrinterTypes(): Observable<PrinterType[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/printerType/get', { headers }).pipe(
      map((result: any[]) => result.map(res => PrinterType.fromDto(res))));
  }

  public updatePrinterInformations(printer: Printer): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/printer/update', { printer: printer }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  public insertPrinterTypeInformations(printerType: PrinterType): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/printerType/insert', { printerType: printerType }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  public updatePrinterTypeInformations(printerType: PrinterType): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/printerType/update', { printerType: printerType }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  public getPrinterManufacturers(): Observable<PrinterManufacturer[]> {
    return this._http.get(URL_BASE + '/admin/printerManufacturer/get', { headers: this._headers }).pipe(
      map((result: any[]) => result.map(res => PrinterManufacturer.fromDto(res)))
    );
  }
}


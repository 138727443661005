import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DeviceUser } from 'src/app/shared/models/DeviceUser';
import { Option } from 'src/app/shared/select/select.component';
import { PriceList } from 'src/app/shared/models/PriceList';
import { Device } from 'src/app/shared/models/Device';
import { EventsService } from 'src/app/events/shared/events.service';
import { ToastrService } from 'ngx-toastr';
import { EventDetailsComponent } from '../../event-details.component';
import { Event } from 'src/app/shared/models/Event';

@Component({
  selector: 'app-device-users-card',
  templateUrl: './device-users-card.component.html',
  styleUrls: ['./device-users-card.component.css']
})
export class DeviceUsersCardComponent implements OnInit, OnChanges {
  public deviceUser: DeviceUser = new DeviceUser();
  public deviceUserErrorArray = new Array();
  public priceListId = undefined;
  public deviceId = undefined;
  public hideMainContainer = true;
  public hideGenerateDeviceUserBtn = true;
  public showEditContent = false;
  public assignedPriceListOptions: Option[] = [{ value: undefined, label: 'No Price List' }];

  @Input()
  deviceUsers: DeviceUser[];

  @Input()
  assignedPriceListObjects: PriceList[];

  @Input()
  devicesListOptions: Option[];

  @Input()
  availableDevicesObjects: Device[];

  @Input()
  eventId: string;

  @Input()
  eventLanguage: string;

  @Input()
  eventFinished: boolean;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onDeviceUserChange = new EventEmitter<DeviceUser[]>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onDeviceUserGenereted = new EventEmitter<DeviceUser[]>();

  constructor(private _eventsService: EventsService, private _toastr: ToastrService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deviceUsers) {
      if (changes.deviceUsers.currentValue.length === 0) {
        this.hideGenerateDeviceUserBtn = false;
      } else {
        this.hideGenerateDeviceUserBtn = true;
        this.hideMainContainer = false;
      }
    }
    if (changes.assignedPriceListObjects) {
      this.populateEventPriceListSelect(changes.assignedPriceListObjects.currentValue);
    }
  }

  showUpdateDeviceUserContent(deviceUserId: number) {
    this.priceListId = undefined;
    this.deviceId = undefined;
    this.deviceUser = { ...this.deviceUsers.filter(deviceUser => deviceUser.id === deviceUserId)[0] };
    if (this.deviceUser.priceList) {
      this.priceListId = this.deviceUser.priceList.id;
    }
    if (this.deviceUser.device) {
      this.deviceId = this.deviceUser.device.id;
    }
    this.showEditContent = true;
  }

  cancelDeviceUserUpdate() {
    this.priceListId = undefined;
    this.deviceUser = new DeviceUser();
    this.priceListId = undefined;
    this.deviceId = undefined;
    this.showEditContent = false;
  }

  onPriceListChangeEvent() {
    if (this.priceListId !== undefined) {
      this.deviceUser.priceList = this.assignedPriceListObjects.filter(item => item.id === this.priceListId)[0];
    } else {
      this.deviceUser.priceList = null;
    }
  }

  onDeviceChangeEvent() {
    if (this.deviceId !== undefined) {
      this.deviceUser.device = this.availableDevicesObjects.filter(item => item.id === this.deviceId)[0];
    } else {
      this.deviceUser.device = null;
    }
  }

  updateDeviceUser() {
    this._eventsService.updateDeviceUser(this.deviceUser, this.eventId).subscribe(result => {
      this.deviceUser.username = result.username;
      this._toastr.success('Device User:: "' + this.deviceUser.name + '" updated!');
      const existingObject = this.deviceUsers.filter(devUsr => devUsr.id === this.deviceUser.id)[0];
      this.deviceUsers[this.deviceUsers.indexOf(existingObject)] = DeviceUser.fromDto(this.deviceUser);
      this.cancelDeviceUserUpdate();
      this.onDeviceUserChange.emit(this.deviceUsers);
    }, error => {
      console.log(error);
      this._toastr.error('Error updating Device User');
    });
  }

  generateNewDeviceUsers() {
    this.hideGenerateDeviceUserBtn = true;
    this.hideMainContainer = true;
    this._eventsService.generateNewDeviceUsersByEventId(this.eventId, this.eventLanguage).subscribe(newDeviceUsers => {
      this._toastr.success('Generated new Device Users for this Event');
      this.deviceUsers = newDeviceUsers;
      this.hideGenerateDeviceUserBtn = true;
      this.hideMainContainer = false;
      this.onDeviceUserGenereted.emit(newDeviceUsers);
    }, error => {
      this._toastr.error('Error generating new Device Users for this Event');
      console.log(error);
    });
  }

  populateEventPriceListSelect(priceLists: PriceList[]) {
    this.assignedPriceListOptions = [{ value: undefined, label: 'No Price List' }];
    priceLists.forEach(priceList => {
      this.assignedPriceListOptions.push({ value: priceList.id, label: priceList.name });
    });
  }

  logoutDeviceUser(deviceUser: DeviceUser) {
    deviceUser.pinCodeUse = false;
    this._eventsService.updateDeviceUser(deviceUser, this.eventId).subscribe(result => {
      console.log(result);
      this._toastr.success('Device User "' + deviceUser.name + '" logged out successfully');
    });
  }

}

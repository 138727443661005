import { Pipe, PipeTransform } from '@angular/core';
import { PrinterTypeStatusEnum } from 'src/app/shared/enums/PrinterTypeStatusEnum';

@Pipe({
  name: 'printerTypeStatus'
})
export class PrinterTypeStatusPipe implements PipeTransform {

  transform(printerTypeStatus: any): any {
    if (printerTypeStatus === 1) {
      return PrinterTypeStatusEnum.ONE;
    } else {
      return PrinterTypeStatusEnum.ZERO;
    }
  }

}

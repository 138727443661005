import { Event } from './Event';
import { SoftwareType } from './SoftwareType';

export class RequestedSoftwareType {
    public id: number;
    public quantity: number;
    public parentRequestId: number;
    public requestedAt: string;
    public event: Event;
    public softwareType: SoftwareType;

    static fromDto(r): RequestedSoftwareType {
        const requestedSoftwareType = new RequestedSoftwareType();

        if (!r) {
            return null;
        }

        requestedSoftwareType.id = r.id;
        requestedSoftwareType.quantity = r.quantity;
        requestedSoftwareType.parentRequestId = r.parentRequestId;
        requestedSoftwareType.requestedAt = r.requestedAt;
        requestedSoftwareType.event = Event.fromDto(r.event);
        requestedSoftwareType.softwareType = SoftwareType.fromDto(r.softwareType);

        return requestedSoftwareType;
    }
}

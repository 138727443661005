import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ProductOption } from 'src/app/shared/models/ProductOption';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { Product } from 'src/app/shared/models/Product';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PriceListService } from 'src/app/price-lists/shared/price-list.service';

@Component({
  selector: 'app-product-option-update-form',
  templateUrl: './product-option-update-form.component.html',
  styleUrls: ['./product-option-update-form.component.css']
})
export class ProductOptionUpdateFormComponent implements OnInit, OnChanges {

  public editProductOptionForm: FormGroup;
  public productOptionErrorArray = new Array();
  private _newProductOption: ProductOption = new ProductOption();

  @Input()
  productOption: ProductOption;

  @Input()
  product: Product;

  @Output()
  emitCancelProductOptionEditMood: EventEmitter<void> = new EventEmitter<void>();

  constructor(private _formBuilder: FormBuilder, private _priceListService: PriceListService, private _toastr: ToastrService,
    private _translate: TranslateService) {
    this.editProductOptionForm = this._formBuilder.group({
      name: ['', [<any>Validators.required, <any>Validators.maxLength(32)]],
      extraInfo: ['', <any>Validators.maxLength(32)],
      price: ['0.00', <any>Validators.pattern(/^\-*\d{0,8}(\.\d{1,4})?$/)]
    }, {
        validator: ValidationUtils.checkStringLength('name', 'extraInfo')
      });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.productOption.extraInfo) {
      this.productOption.extraInfo = '';
    }
    this.productOption.price = parseFloat(this.productOption.price.toString().replace(/,/g, '.'));
    console.log('productOption: ', this.productOption);
    this.editProductOptionForm.patchValue(this.productOption);
  }

  onProductOptionEditSubmit() {
    this.editProductOptionForm.controls['price'].setValue(
      parseFloat(this.editProductOptionForm.controls['price'].value.toString().replace(/,/g, '.'))
    );
    if (this.editProductOptionForm.valid) {
      this._newProductOption = ProductOption.fromDto(this.editProductOptionForm.value);
      this._newProductOption.status = this.productOption.status;
      this._newProductOption.id = this.productOption.id;
      this._newProductOption.product = this.product;
      this._priceListService.updateProductOption(this._newProductOption).subscribe(result => {
        this.emitCancelProductOptionEditMood.emit();
        this.product.productOptions = result.productOptions;
        this.editProductOptionForm.reset({name: '', extraInfo: '', price: '0.00'});
        this._toastr.success(this._translate.instant('price-list.manage.productOptionSuccessUpdateMsg'));
      }, error => {
        console.log(error);
      });
    }
    this.productOptionErrorArray = ValidationUtils.getFormValidationErrors(this.editProductOptionForm, 'price-list.manage');
  }

  cancelEditButtonClick() {
    this.emitCancelProductOptionEditMood.emit();
  }

}

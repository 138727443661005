import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { PriceListService } from '../../shared/price-list.service';
import { ActivatedRoute } from '@angular/router';
import { PriceList } from 'src/app/shared/models/PriceList';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-price-list-summery-paper',
  templateUrl: './price-list-summery-paper.component.html',
  styleUrls: ['./price-list-summery-paper.component.css']
})
export class PriceListSummeryPaperComponent implements OnInit {

  public today = moment();
  public priceList: PriceList = new PriceList();

  @ViewChild('container')
  container: ElementRef;

  constructor(private _priceListService: PriceListService, private _route: ActivatedRoute) { }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this._priceListService.gatPriceListsById(params['priceListId']).subscribe(result => {
        this.priceList = result;
      });
    });
  }

  printPriceListLayout() {
    window.print();
  }

  downloadPdfPriceListLayout() {
    html2canvas(this.container.nativeElement).then(canvas => {
      const pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
      pdf.save(this.priceList.name + '.pdf');
    });
  }
}

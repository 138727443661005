import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { COMPANIES_ROUTES } from './companies.routes';
import { CompaniesComponent } from './companies.component';
import { CompaniesListComponent } from './companies-list/companies-list.component';
import { CompaniesService } from './shared/companies.service';
import { UpdateCompanyComponent } from './update-company/update-company.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CompanyDetailsComponent } from './company-details/company-details.component';
@NgModule({
    imports: [
        RouterModule.forChild(COMPANIES_ROUTES),
        SharedModule,
        NgSelectModule
    ],
    declarations: [
        CompaniesComponent,
        CompaniesListComponent,
        UpdateCompanyComponent,
        CompanyDetailsComponent,
    ],
    providers: [
        CompaniesService
    ]
})
export class CompaniesModule { }

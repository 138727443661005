import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CORE_ROUTES } from './core.routes';
import { SigninComponent } from './signin/signin.component';
import { HomeComponent } from './home/home.component';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from './guards/auth.guard';
import { NavigationService } from '../shared/navigation.service';
import { NavComponent } from './nav/nav.component';
import { CommonModule } from '@angular/common';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    RouterModule.forChild(CORE_ROUTES),
    SharedModule

  ],
  declarations: [
    SigninComponent,
    HomeComponent,
    NavComponent,
    RecoverPasswordComponent,
    DashboardComponent,
    ResetPasswordComponent
  ],
  exports: [
    NavComponent
  ],
  providers: [AuthGuard, NavigationService]
})
export class CoreModule {
}

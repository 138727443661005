import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Event } from 'src/app/shared/models/Event';
import { RequestedSoftwareType } from 'src/app/shared/models/RequestedSoftwareType';
import { RequestedDevice } from 'src/app/shared/models/RequestedDevice';
import { RequestedPrinterType } from 'src/app/shared/models/RequestedPrinterType';
import { RequestedRouterType } from 'src/app/shared/models/RequestedRouterType';
import { RequestedUps } from 'src/app/shared/models/RequestedUps';
import { ClientsService } from 'src/app/clients/shared/clients.service';
import { Option } from 'src/app/shared/select/select.component';
import { SoftwareType } from 'src/app/shared/models/SoftwareType';
import { RequestedCable } from 'src/app/shared/models/RequestedCable';
import { DeviceType } from 'src/app/shared/models/DeviceType';
import { ToastrService } from 'ngx-toastr';
import { Cable } from 'src/app/shared/models/Cable';
import { PrinterType } from 'src/app/shared/models/PrinterType';
import { EventsService } from '../../../events/shared/events.service';
import { RouterType } from 'src/app/shared/models/RouterType';
import { Ups } from 'src/app/shared/models/Ups';
import { Client } from '../../models/Client';
import { Router } from '@angular/router';
import { LanguageEnum } from 'src/app/shared/enums/LanguageEnum';
import { SharedService } from 'src/app/shared/shared.service';
import { Village } from 'src/app/shared/models/Village';

export class CableSizes {
  one = 0;
  five = 0;
  ten = 0;
  fifteen = 0;
  twenty = 0;
  thirty = 0;
  fifty = 0;
}

@Component({
  selector: 'app-request-event-modal',
  templateUrl: './request-event-modal.component.html',
  styleUrls: ['./request-event-modal.component.css'],
  providers: [NgbModalConfig, NgbModal]
})

export class RequestEventModalComponent implements OnInit {

  public event: Event = new Event();
  public startDate = moment().format('DD-MM-YYYY');
  public endDate = moment().format('DD-MM-YYYY');
  public pickUpDate = moment().subtract(1, 'day').format('DD-MM-YYYY');
  public returnDate = moment().add(1, 'day').format('DD-MM-YYYY');
  public selectedClient = undefined;
  public waiterQuantity = 0;
  public cashRegisterQuantity = 0;
  public mainDeviceQuantity = 0;
  public phoneQuantity = 0;
  public tabletQuantity = 0;
  public printerQuantity = 0;
  public routerQuantity = 0;
  public upsQuantity = 0;
  public printerSoftwareQuantity = 0;
  public cableQuantity: CableSizes = new CableSizes();
  public villageOptions: Option[] = [];

  private _routerTypesArray: RouterType[];
  private _printerTypesArray: PrinterType[];
  private _deviceTypesArray: DeviceType[];
  private _softwareTypesArray: SoftwareType[];
  private _upsArray: Ups[];
  private _cableArray: Cable[];
  private _villageObjects: Village[];
  private _companyChoosen = false;

  public clientLanguageArray: Option[] = [
    { value: 'de', label: LanguageEnum.DE },
    { value: 'it', label: LanguageEnum.IT },
    { value: 'en', label: LanguageEnum.EN },
  ];

  constructor(private _eventsService: EventsService,
    private _modalService: NgbModal, modalConfig: NgbModalConfig, private _toastr: ToastrService,
    private _translate: TranslateService, private _router: Router, private _sharedService: SharedService) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
    this.event.pickUpTime = '09:00:00';
    this.event.returnTime = '09:00:00';
  }

  ngOnInit() {
  }

  open(content) {
    this._modalService.open(content, { size: 'lg', windowClass: 'request-event-modal' }).result.then((result) => {
    }, (reason) => {
      console.log(reason);
      this._resetComponent();
    });
    this._eventsService.getAllRouterTypes().subscribe(result => { this._routerTypesArray = result; }, error => { console.error(error); });
    this._eventsService.getAllPrinterTypes().subscribe(result => { this._printerTypesArray = result; }, error => { console.error(error); });
    this._eventsService.getAllDeviceTypes().subscribe(result => { this._deviceTypesArray = result; }, error => { console.error(error); });
    // tslint:disable-next-line:max-line-length
    this._eventsService.getAllSoftwareTypes().subscribe(result => { this._softwareTypesArray = result; }, error => { console.error(error); });
    this._eventsService.getAllUps().subscribe(result => { this._upsArray = result; }, error => { console.error(error); });
    this._eventsService.getAllCables().subscribe(result => { this._cableArray = result; }, error => { console.error(error); });
    this._sharedService.getAllVillages().subscribe(result => {
      this._villageObjects = result;
      result.forEach(village => {
        this.villageOptions.push({ value: village.id, label: village.name });
      });
      this.villageOptions = [...this.villageOptions];
    });
  }

  onStartDateChange($event) {
    console.log($event);
  }

  public submitNewEventRequest() {
    if (this.event.eventName === '' || this.event.eventName === undefined) {
      this._toastr.error(this._translate.instant('request-event-modal.missing-event-name-error'));
      return;
    }

    if (!this._companyChoosen) {
      this._toastr.error(this._translate.instant('request-event-modal.missing-company-error'));
      return;
    }

    if (!this.event.language) {
      this._toastr.error('Please select the event language');
      return;
    }

    if (!this.event.village) {
      this._toastr.error('Please select the event village');
      return;
    }

    this.event.startDate = moment(this.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.event.endDate = moment(this.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.event.pickUpDate = moment(this.pickUpDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.event.returnDate = moment(this.returnDate, 'DD-MM-YYYY').format('YYYY-MM-DD');

    if (this.waiterQuantity > 0) {
      this._addNewSoftwareType(this.waiterQuantity, 1);
    }
    if (this.cashRegisterQuantity > 0) {
      this._addNewSoftwareType(this.cashRegisterQuantity, 2);
    }
    if (this.mainDeviceQuantity > 0) {
      this._addNewSoftwareType(this.mainDeviceQuantity, 3);
    }
    if (this.printerSoftwareQuantity > 0) {
      this._addNewSoftwareType(this.printerSoftwareQuantity, 4);
    }
    if (this.phoneQuantity > 0) {
      this._addNewDeviceType(this.phoneQuantity, 1);
    }
    if (this.tabletQuantity > 0) {
      this._addNewDeviceType(this.tabletQuantity, 2);
    }

    if (this.printerQuantity > 0) {
      const printerRequest = new RequestedPrinterType();
      printerRequest.quantity = this.printerQuantity;
      printerRequest.printerType = this._printerTypesArray.filter(printerType => printerType.id === 1)[0];
      this.event.requestedPrinterTypes.push(printerRequest);
    }

    if (this.routerQuantity > 0) {
      const routerRequest = new RequestedRouterType();
      routerRequest.quantity = this.routerQuantity;
      routerRequest.routerTypes = this._routerTypesArray.filter(routerType => routerType.id === 1)[0];
      this.event.requestedRouterTypes.push(routerRequest);
    }

    if (this.upsQuantity > 0) {
      const upsRequest = new RequestedUps();
      upsRequest.quantity = this.upsQuantity;
      upsRequest.ups = this._upsArray.filter(ups => ups.id === 1)[0];
      this.event.requestedUps.push(upsRequest);
    }

    if (Object.keys(this.event.clientUser).length === 1) {
      this._toastr.error(this._translate.instant('request-event-modal.missing-client-error'));
      console.error('Select a client');
      return;
    }
    this._addNewCables();
    const eventVillageObject: any = this.event.village;
    this.event.village = this._villageObjects.filter(village => village.id === eventVillageObject.value)[0];

    console.log(this.event);
    this._eventsService.insertEventRequest(this.event).subscribe(result => {
      this._modalService.dismissAll();
      this._toastr.success(this._translate.instant('request-event-modal.event-added-success'));
      this._router.navigate(['/events/all'], { queryParams: { eventId: result.id.toString() } });
    }, error => {
      console.log(error);
    });
  }

  onClientSelectedEvent(client: Client) {
    this._companyChoosen = true;
    this.event.clientUser = Client.fromDto(client);
  }

  private _addNewCables() {
    let counter = 1;
    for (const key in this.cableQuantity) {
      if (this.cableQuantity.hasOwnProperty(key)) {
        const quantity = this.cableQuantity[key];
        if (quantity > 0) {
          const cableRequest = new RequestedCable();
          cableRequest.quantity = quantity;
          cableRequest.cables = this._cableArray.filter(cable => cable.id === counter)[0];
          this.event.requestedCable.push(cableRequest);
        }
        counter++;
      }
    }
  }

  private _addNewSoftwareType(quantity: number, softwareTypeId: number) {
    const softwareTypeRequest = new RequestedSoftwareType();
    softwareTypeRequest.quantity = quantity;
    softwareTypeRequest.softwareType = this._softwareTypesArray.filter(softwareType => softwareType.id === softwareTypeId)[0];
    this.event.requestedSoftwareTypes.push(softwareTypeRequest);
  }

  private _addNewDeviceType(quantity: number, deviceTypeId: number) {
    const deviceRequest = new RequestedDevice();
    deviceRequest.quantity = quantity;
    deviceRequest.deviceTypes = this._deviceTypesArray.filter(deviceType => deviceType.id === deviceTypeId)[0];
    this.event.requestedDevices.push(deviceRequest);
  }

  private _resetComponent() {
    this.event = new Event();
    this.selectedClient = undefined;
    this.waiterQuantity = 0;
    this.cashRegisterQuantity = 0;
    this.mainDeviceQuantity = 0;
    this.phoneQuantity = 0;
    this.tabletQuantity = 0;
    this.printerQuantity = 0;
    this.routerQuantity = 0;
    this.upsQuantity = 0;
    this.printerSoftwareQuantity = 0;
    this.cableQuantity = new CableSizes();
    this._routerTypesArray = [];
    this._printerTypesArray = [];
    this._deviceTypesArray = [];
    this._softwareTypesArray = [];
    this._upsArray = [];
    this._cableArray = [];
    this.startDate = moment().format('DD-MM-YYYY');
    this.endDate = moment().format('DD-MM-YYYY');
    this.pickUpDate = moment().subtract(1, 'day').format('DD-MM-YYYY');
    this.returnDate = moment().add(1, 'day').format('DD-MM-YYYY');
    this.event.pickUpTime = '09:00:00';
    this.event.returnTime = '09:00:00';
    this.villageOptions = [];
    this._companyChoosen = false;
  }
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SearchObjectResult } from '../../shared/models/SearchObject';
import { Observable } from 'rxjs';
import { DataTableColumn } from '../../shared/data-table/data-table.component';
import { ClientsService } from '../shared/clients.service';
import { ClientSearchCriteria } from '../shared/client-search-criteria.model';
import { Client } from '../../shared/models/Client';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { ClientStatusEnum } from 'src/app/shared/enums/ClientStatusEnum';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CLIENT_URL_BASE } from 'src/app/core/constants';
import { CryptUtils } from 'src/app/shared/crypt-utils';
import { CookieService } from 'src/app/shared/cookie.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {

  public clientStatusOptionArray: Option[] = [
    { value: ClientStatusEnum.Enabled.toString(), label: 'Enabled' },
    { value: ClientStatusEnum.Disabled.toString(), label: 'Disabled' },
    { value: ClientStatusEnum.Deleted.toString(), label: 'Deleted' },
    { value: ClientStatusEnum.RecoveryMode.toString(), label: 'Recovery Mode' },
    { value: ClientStatusEnum.Registration.toString(), label: 'Registration' }
  ];
  public tBodyTrAdditionalClass = 'alternateBgColor';
  private _checkedClients = Array();

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Client>> = null;
  filters: ClientSearchCriteria = {};

  @ViewChild('selectColumn')
  selectColumn: TemplateRef<any>;

  @ViewChild('idColumn')
  idColumn: TemplateRef<any>;

  @ViewChild('customerTitle')
  customerTitle: TemplateRef<any>;

  @ViewChild('customerName')
  customerName: TemplateRef<any>;

  @ViewChild('emailColumn')
  emailColumn: TemplateRef<any>;

  @ViewChild('phoneColumn')
  phoneColumn: TemplateRef<any>;

  @ViewChild('statusColumn')
  statusColumn: TemplateRef<any>;

  @ViewChild('firstLogin')
  firstLogin: TemplateRef<any>;

  // @ViewChild('newsletterColumn')
  // newsletterColumn: TemplateRef<any>;

  @ViewChild('createdAtColumn')
  createdAtColumn: TemplateRef<any>;

  @ViewChild('companyColumn')
  companyColumn: TemplateRef<any>;

  @ViewChild('companyStatusColumn')
  companyStatusColumn: TemplateRef<any>;

  @ViewChild('notesColumn')
  notesColumns: TemplateRef<any>;

  @ViewChild('notesTmpl')
  notesTmpl: TemplateRef<any>;

  @ViewChild('actionsColumn')
  actionsColumn: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private _clientService: ClientsService, private _toastr: ToastrService, private _router: Router,
    private _cookieService: CookieService) {
    this.loadPage = (page: number, pageSize: number) => this._clientService.search(page, pageSize, this.filters);
  }

  ngOnInit() {
    this.columns = [
      {
        name: 'select',
        displayed: true,
        translationKey: '',
        template: this.selectColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'id',
        displayed: true,
        translationKey: 'client-list.id.label',
        template: this.idColumn,
        sortable: true
      },
      {
        name: 'title',
        displayed: true,
        translationKey: 'title',
        template: this.customerTitle,
        sortable: true
      },
      {
        name: 'customerName',
        displayed: true,
        translationKey: 'Customer name',
        template: this.customerName,
        sortable: true
      },
      {
        name: 'email',
        displayed: true,
        translationKey: 'client-list.email.label',
        template: this.emailColumn,
        sortable: true
      },
      {
        name: 'phone',
        displayed: true,
        translationKey: 'Phone',
        template: this.phoneColumn,
        sortable: true
      },
      {
        name: 'status',
        displayed: true,
        translationKey: 'Status',
        template: this.statusColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'firstLogin',
        displayed: true,
        translationKey: 'Made Login',
        template: this.firstLogin,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      // {
      //   name: 'newsletter',
      //   displayed: true,
      //   translationKey: 'Newsletter',
      //   template: this.newsletterColumn,
      //   sortable: true,
      //   additionalClass: 'text-center',
      //   trAddidionalClass: 'text-center'
      // },
      {
        name: 'registration',
        displayed: true,
        translationKey: 'Registration',
        template: this.createdAtColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'company',
        displayed: true,
        translationKey: 'client-list.company.label',
        template: this.companyColumn,
        sortable: true
      },
      {
        name: 'companyStatus',
        displayed: true,
        translationKey: 'Company status',
        template: this.companyStatusColumn,
        sortable: true
      },
      {
        name: 'notes',
        displayed: true,
        translationKey: 'Notes',
        template: this.notesColumns,
        sortable: true
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actionsColumn,
        sortable: true,
        additionalClass: 'text-right'
      }
    ];
  }

  onClientCheckboxSelected(event: any) {
    const clientId = event.target.defaultValue;
    if (event.target.checked) {
      this._checkedClients.push(clientId);
    } else {
      const index = this._checkedClients.findIndex(client => client === clientId);
      this._checkedClients.splice(index, 1);
    }
  }

  changeStatusSelectedClients(newStatus: number) {
    if (this._checkedClients.length > 0) {
      if (newStatus === 1) {
        this._clientService.enableMultipleClients(this._checkedClients).subscribe(result => {
          this.loadPage = (page: number, pageSize: number) => this._clientService.search(page, pageSize, this.filters);
        });
      } else {
        this._clientService.disableMultipleClients(this._checkedClients).subscribe(result => {
          console.log(result);
          this.loadPage = (page: number, pageSize: number) => this._clientService.search(page, pageSize, this.filters);
        });
      }
      this._checkedClients = Array();
    }
  }

  softDeleteSelectedClients() {
    if (this._checkedClients.length > 0) {
      this._clientService.softDeleteClients(this._checkedClients).subscribe(result => {
        this.loadPage = (page: number, pageSize: number) => this._clientService.search(page, pageSize, this.filters);
      });
      this._checkedClients = Array();
    }
  }

  softDeleteClient(clientId: string) {
    const array = Array();
    array.push(clientId.toString());
    this._clientService.softDeleteClients(array).subscribe(result => {
      console.log(result);
      this.loadPage = (page: number, pageSize: number) => this._clientService.search(page, pageSize, this.filters);
    });
  }

  openToaster(company: any) {
    this._toastr.info(company.notes, company.name,
      {
        timeOut: 10000
      }
    );
  }

  editNotes(company: any) {
    this._router.navigate(['/companies/edit', company.id]);
  }

  accessClientFrontend(clientId: number) {
    const clientCryptCode = clientId * 256577556571162887;
    this._cookieService.setCookie({
      name: 'clientCryptCode',
      value: clientCryptCode,
      session: false,
    });
    window.open(CLIENT_URL_BASE + 'login', '_blank');
  }
}

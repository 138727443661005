import { FormGroup, ValidationErrors } from '@angular/forms';
import { DatePickerUtils } from './datepicker-utils';

export class ValidationUtils {

    /**
     *
     *
     */
    public static getFormValidationErrors(form: FormGroup, translationGroup: string): Array<any> {
        const errorArray = new Array();
        Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            errorArray[key] = null;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                    let errorMsg;
                    switch (keyError) {
                        case 'email':
                            errorMsg = translationGroup + '.errorMsg.invalideEmail';
                            break;
                        case 'minlength':
                            errorMsg = translationGroup + '.errorMsg.shortLength';
                            break;
                        case 'notEquivalent':
                            errorMsg = translationGroup + '.errorMsg.passwordNotEqual';
                            break;
                        case 'required':
                            errorMsg = translationGroup + '.errorMsg.required';
                            break;
                        case 'notTerms':
                            errorMsg = translationGroup + '.errorMsg.missingTerms';
                            break;
                        case 'fiscalCodeVatRequired':
                            errorMsg = translationGroup + '.errorMsg.fiscalCodeVatRequired';
                            break;
                        case 'maxlength':
                            errorMsg = translationGroup + '.errorMsg.maxlength';
                            break;
                        case 'startDateError':
                            errorMsg = translationGroup + '.errorMsg.startDateError';
                            break;
                        case 'pickUpDateAfterReturnDateError':
                            errorMsg = translationGroup + '.errorMsg.pickUpDateAfterReturnDateError';
                            break;
                        case 'pickUpDateAfterStartDateError':
                            errorMsg = translationGroup + '.errorMsg.pickUpDateAfterStartDateError';
                            break;
                        case 'returnDateAfterEndDateError':
                            errorMsg = translationGroup + '.errorMsg.returnDateAfterEndDateError';
                            break;
                        default:
                            errorMsg = translationGroup + '.errorMsg.default';
                            break;
                    }
                    errorArray[key] = errorMsg;
                });
            }
        });
        // console.log(errorArray);
        return errorArray;
    }

    public static checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string, passwordConfirmRequired = true) {
        return (group: FormGroup) => {
            const passwordInput = group.controls[passwordKey],
                passwordConfirmationInput = group.controls[passwordConfirmationKey];
            if (passwordConfirmRequired) {
                if (passwordConfirmationInput.value === undefined) {
                    return passwordConfirmationInput.setErrors({ required: true });
                }
            }
            if (passwordInput.value !== passwordConfirmationInput.value) {
                return passwordConfirmationInput.setErrors({ notEquivalent: true });
            } else {
                return passwordConfirmationInput.setErrors(null);
            }
        };
    }

    public static checkStringLength(nameKey: string, extraInfo: string): any {
        console.log(nameKey);
        console.log(extraInfo);
        return (group: FormGroup) => {
            const nameInput = group.controls[nameKey],
                extraInfoInput = group.controls[extraInfo];
            if (nameInput.value.length === 0) {
                nameInput.setErrors({ required: true });
                return;
            }
            if ((nameInput.value.length + extraInfoInput.value.length) > 32) {
                nameInput.setErrors({ maxlength: true });
                extraInfoInput.setErrors({ maxlength: true });
            } else {
                nameInput.setErrors(null);
                extraInfoInput.setErrors(null);
            }
        };
    }

    public static eventDatesValidation(startDateKey: string, endDateKey: string, pickUpDateKey: string, returnDateKey: string) {
        return (group: FormGroup) => {
            const startDate = DatePickerUtils.toModel(group.controls[startDateKey].value),
                endDate = DatePickerUtils.toModel(group.controls[endDateKey].value),
                pickUpDate = DatePickerUtils.toModel(group.controls[pickUpDateKey].value),
                returnDate = DatePickerUtils.toModel(group.controls[returnDateKey].value);
            if (startDate === null || endDate === null || pickUpDate === null || returnDate === null) {
                return;
            }
            if (startDate > endDate) {
                group.controls[startDateKey].setErrors({startDateError: true});
                return;
            }
            if (pickUpDate > returnDate) {
                group.controls[pickUpDateKey].setErrors({pickUpDateAfterReturnDateError: true});
                return;
            }
            if (pickUpDate > startDate) {
                group.controls[pickUpDateKey].setErrors({pickUpDateAfterStartDateError: true});
                return;
            }
            if (returnDate < endDate) {
                group.controls[returnDateKey].setErrors({returnDateAfterEndDateError: true});
                return;
            }
        };
    }
}

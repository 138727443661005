import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { RequestedCable } from 'src/app/shared/models/RequestedCable';
import { RequestedDevice } from 'src/app/shared/models/RequestedDevice';
import { RequestedPrinterType } from 'src/app/shared/models/RequestedPrinterType';
import { RequestedRouterType } from 'src/app/shared/models/RequestedRouterType';
import { RequestedSoftwareType } from 'src/app/shared/models/RequestedSoftwareType';
import { RequestedUps } from 'src/app/shared/models/RequestedUps';

class TableRow {
  date: string;
  softWaiter = 0;
  softCashRegister = 0;
  softMainDevice = 0;
  softPrinter = 0;
  hardPhones = 0;
  hardTables = 0;
  hardPrinters = 0;
  hardRouters = 0;
  ups = 0;
  oneCable = 0;
  fiveCable = 0;
  tenCable = 0;
  fifteenCable = 0;
  twentyCable = 0;
  thirtyCable = 0;
  fiftyCable = 0;
}

@Component({
  selector: 'app-requested-hardware-software-summary',
  templateUrl: './requested-hardware-software-summary.component.html',
  styleUrls: ['./requested-hardware-software-summary.component.css']
})
export class RequestedHardwareSoftwareSummaryComponent implements OnInit, OnChanges {

  public tableRows: TableRow[] = [];

  @Input()
  requestedCable: RequestedCable[];

  @Input()
  requestedDevices: RequestedDevice[];

  @Input()
  requestedPrinterTypes: RequestedPrinterType[];

  @Input()
  requestedRouterTypes: RequestedRouterType[];

  @Input()
  requestedSoftwareTypes: RequestedSoftwareType[];

  @Input()
  requestedUps: RequestedUps[];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.requestedDevices.forEach(device => {
      const tableRows = this._getTableRow(moment(device.requestedAt).format('DD-MM-YYYY'));
      if (device.deviceTypes.id === 1) {
        tableRows.hardPhones += device.quantity;
      } else {
        tableRows.hardTables += device.quantity;
      }
    });
    this.requestedRouterTypes.forEach(router => {
      const tableRows = this._getTableRow(moment(router.requestedAt).format('DD-MM-YYYY'));
      tableRows.hardRouters += router.quantity;
    });
    this.requestedPrinterTypes.forEach(printer => {
      const tableRows = this._getTableRow(moment(printer.requestedAt).format('DD-MM-YYYY'));
      tableRows.hardPrinters += printer.quantity;
    });
    this.requestedUps.forEach(ups => {
      const tableRows = this._getTableRow(moment(ups.requestedAt).format('DD-MM-YYYY'));
      tableRows.ups += ups.quantity;
    });
    this.requestedSoftwareTypes.forEach(softwareType => {
      const tableRows = this._getTableRow(moment(softwareType.requestedAt).format('DD-MM-YYYY'));
      switch (softwareType.softwareType.id) {
        case 2:
          tableRows.softCashRegister += softwareType.quantity;
          break;
        case 3:
          tableRows.softMainDevice += softwareType.quantity;
          break;
        case 4:
          tableRows.softPrinter += softwareType.quantity;
          break;
        default:
          tableRows.softWaiter += softwareType.quantity;
          break;
      }
    });

    this.requestedCable.forEach(cable => {
      const tableRows = this._getTableRow(moment(cable.requestedAt).format('DD-MM-YYYY'));
      switch (cable.cables.cableType.length) {
        case 5:
          tableRows.fiveCable += cable.quantity;
          break;
        case 10:
          tableRows.tenCable += cable.quantity;
          break;
        case 15:
          tableRows.fifteenCable += cable.quantity;
          break;
        case 20:
          tableRows.twentyCable += cable.quantity;
          break;
        case 30:
          tableRows.thirtyCable += cable.quantity;
          break;
        case 50:
          tableRows.fiftyCable += cable.quantity;
          break;
        default:
          tableRows.oneCable += cable.quantity;
          break;
      }
    });
  }

  _getTableRow(date: string) {
    let existingTableRow = this.tableRows.filter(tableRow => tableRow.date === date)[0];
    if (!existingTableRow) {
      existingTableRow = new TableRow();
      existingTableRow.date = date;
      this.tableRows.push(existingTableRow);
    }
    return existingTableRow;
  }

}

import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _translate: TranslateService) {
    super();
  }
  getDayAriaLabel(date: import('@ng-bootstrap/ng-bootstrap').NgbDateStruct): string {
    return '';
  }

  getWeekdayShortName(weekday: number): string {
    return this._translate.instant('calendar.weekdays')[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return this._translate.instant('calendar.months')[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
        return { day: this.toInteger(dateParts[0]), month: null, year: null };
      } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
        return { day: this.toInteger(dateParts[0]), month: this.toInteger(dateParts[1]), year: null };
      } else if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
        return { day: this.toInteger(dateParts[0]), month: this.toInteger(dateParts[1]), year: this.toInteger(dateParts[2]) };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ?
      `${this.isNumber(date.day) ? this.padNumber(date.day) : ''}-${this.isNumber(date.month) ?
        this.padNumber(date.month) : ''}-${date.year}` :
      '';
  }

  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  isNumber(value: any): value is number {
    return !isNaN(this.toInteger(value));
  }

  padNumber(value: number) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }
}

@Component({
  selector: 'app-form-group-datepicker-validation',
  templateUrl: './form-group-datepicker-validation.component.html',
  styleUrls: ['./form-group-datepicker-validation.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class FormGroupDatepickerValidationComponent implements OnInit {

  @Input()
  placeHolder = 'dd-mm-yyyy';

  @Input()
  label: string;

  @Input()
  model: string;

  @Input()
  maxDate;

  @Input()
  minDate;

  @Input()
  error = '';

  @Input()
  helpText = '';

  @Input()
  inline = false;

  @Input()
  name: string;

  @Input()
  id: string;

  @Input()
  formControlNameValue: string;

  @Input()
  formGroup: FormGroup;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  userChange: EventEmitter<any> = new EventEmitter<any>();


  constructor() {
  }

  ngOnInit() {
  }

  change() {
    this.modelChange.emit(this.model);
  }
  fnUserIsChange() {
    this.userChange.emit(this.model);
  }

}

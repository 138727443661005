import { RouterType } from './RouterType';
import { RouterExtension } from './RouterExtension';
import { Event } from './Event';

export class Router {
    public id: number;
    public name: string;
    public networkName: string;
    public networkPassword: string;
    public routerIp: string;
    public routerUsername: string;
    public routerPassword: string;
    public status: number;
    public channel: string;
    public master: number;
    public notes: string;
    public routerType: RouterType = new RouterType();
    public events: Event;
    public routerAssignedCount: string;
    public assignedEvent: string;

    static fromDto(r): Router {
        const router = new Router();

        if (!r) {
            return null;
        }

        router.id = r.id;
        router.name = r.name;
        router.networkName = r.networkName;
        router.networkPassword = r.networkPassword;
        router.routerIp = r.routerIp;
        router.routerUsername = r.routerUsername;
        router.routerPassword = r.routerPassword;
        router.status = r.status;
        router.channel = r.channel;
        router.master = r.master;
        router.notes = r.notes;
        router.routerType = r.routerType;
        router.events = r.events;
        router.routerAssignedCount = r.routerAssignedCount;
        router.assignedEvent = r.assignedEvent;

        return router;
    }
}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { NavigationService } from '../../shared/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  private _activationCode: string;
  public loginErrorCode: number;
  constructor(private _authService: AuthService, private navigationService: NavigationService, private _route: ActivatedRoute,
    private _toastr: ToastrService, private _router: Router, private _spinner: SpinnerVisibilityService) {
    this._route.queryParams.subscribe(params => {
      if (params['activateCode']) {
        this._activationCode = params['activateCode'];
      }
    });
  }

  ngOnInit() {
    if (this._authService.isAuthenticated()) {
      if (this._activationCode) {
        return this.redirectToHome({ queryParams: { 'activateCode': this._activationCode } });
      } else {
        this._router.navigate(['offers']);
      }
    }
  }
  onSignin(form: NgForm) {
    this.loginErrorCode = null;
    const username = form.value.username;
    const password = form.value.password;
    this._spinner.show();
    this._authService.authenticate(username, password).subscribe(data => {
      if (data) {
        if (this._activationCode) {
          this.redirectToHome({ queryParams: { 'activateCode': this._activationCode } });
        } else {
          setTimeout(() => {
            this._spinner.hide();
            this._router.navigate(['offers']);
          }, 1000);
        }
      }
    }, err => {
      this.loginErrorCode = err.status;
      this._spinner.hide();
    });
  }

  redirectToHome(params?: any) {
    this.navigationService.home(params);
  }

  redirectToRecovery() {
    this.navigationService.recovery().go();
  }
}

import {Component, OnInit} from '@angular/core';
import {AuthService} from './core/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, Router} from '@angular/router';
import {Spinkit} from 'ng-http-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public spinkit = Spinkit;

  constructor(translate: TranslateService, router: Router) {
    translate.addLangs(['en', 'de', 'it']);
    translate.setDefaultLang('en');

    const locale = localStorage.getItem('locale');
    if (locale && translate.getLangs().findIndex(l => l === locale) !== -1) {
      translate.use(locale);
    }
  }
}

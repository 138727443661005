import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { EventsComponent } from './events.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { AllEventsComponent } from './all-events/all-events.component';
import { DeliveryPaperComponent } from './print-layouts/delivery-paper/delivery-paper.component';
import { EventsCalendarComponent } from './events-calendar/events-calendar.component';
import { PreparationPaperComponent } from './print-layouts/preparation-paper/preparation-paper.component';
import { RequestEventComponent } from './request-event/request-event.component';
import { AdvertiseEventComponent } from './event-details/advertise-event/advertise-event.component';

export const EVENTS_ROUTES: Routes = [
  {
    path: 'events', component: EventsComponent, canActivate: [AuthGuard], children: [
      { path: 'all', component: AllEventsComponent },
      { path: 'calendar', component: EventsCalendarComponent },
      { path: 'details/:eventId', component: EventDetailsComponent },
      { path: 'delivery-paper/:eventId', component: DeliveryPaperComponent },
      { path: 'preparation-paper/:eventId', component: PreparationPaperComponent },
      { path: 'advertise-event/:eventId', component: AdvertiseEventComponent },
      { path: 'request', component: RequestEventComponent },
      { path: '**', redirectTo: 'events' }
    ]
  }
];

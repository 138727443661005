import { Injectable } from '@angular/core';
import { LOCAL_STORAGE, URL_BASE } from './constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpUtils } from '../shared/http-utils';



@Injectable({ providedIn: 'root' })
export class AuthService {

  private accessToken: string;

  constructor(private _http: HttpClient, public jwtHelper: JwtHelperService) {
    this.accessToken = this.getAccessToken();
  }

  public authenticate(username: string, password: string): Observable<boolean> {
    return this._http.post(URL_BASE + '/admin/rootUser/login', { username, password }).pipe(
      map((res: any) => {
        if (res.token) {
          this.setAccessToken(res.token);
          return true;
        } else {
          return false;
        }
      }));
  }

  resetPasswordRequest(email: string) {
    return this._http.get(`${URL_BASE}/admin/rootUser/passwordRecoveryRequest?rootUserEmail=${email}`, { responseType: 'text' }).pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          return true;
        } else {
          return false;
        }
      }));
  }

  resetPassword(recoveryCode: string, password: string) {
    return this._http.post(URL_BASE + '/admin/rootUser/changeRecoveryPassword',
      { recoveryCode: recoveryCode, rootUserPassword: password }).pipe(
        map((res: any) => {
          if (res) {
            console.log(res);
            return true;
          } else {
            return false;
          }
        }));
  }

  activateClientUser(activationCode: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/clientUser/activate', { headers: headers, params: { activationCode } }).pipe(
      map((res: any) => res));
  }

  public isAuthenticated(): boolean {
    const accessToken = this.getAccessToken();
    return !!accessToken && !this.jwtHelper.isTokenExpired();
  }
  public disconnect(): void {
    this.accessToken = null;
    for (const key of Object.keys(LOCAL_STORAGE)) {
      localStorage.removeItem(LOCAL_STORAGE[key]);
    }
  }

  private getAccessToken(): string {
    if (!this.accessToken) {
      this.accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    }
    return this.accessToken;
  }


  private setAccessToken(token: string) {
    this.accessToken = token;
    localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, token);
  }


}

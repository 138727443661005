import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-group-number-input',
  templateUrl: './form-group-number-input.component.html',
  styleUrls: ['./form-group-number-input.component.css']
})
export class FormGroupNumberInputComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  model = 0;

  @Input()
  disableInput = true;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  changeValueClickEvent(negative) {
    this.model = (negative ? (this.model >= 1 ? (this.model - 1) : this.model) : (this.model + 1));
    this.modelChange.emit(this.model);
  }

}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-data-filter-input',
  templateUrl: './data-filter-input.component.html',
  styleUrls: ['./data-filter-input.component.css']
})
export class DataFilterInputComponent implements OnInit, OnChanges {

  @Input()
  label: string;

  @Input()
  placeholder: string;

  @Input()
  model: any;

  @Input()
  isNumber = false;

  @Input()
  disabled = false;

  @Input()
  min: number;

  @Input()
  max: number;

  @Input()
  step: number;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && this.disabled) {
      this.model = null;
      this.change();
    }
  }

  change() {
    this.modelChange.emit(this.model);
  }

}

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DevicesService } from '../shared/devices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DeviceManufacturers } from 'src/app/shared/models/DeviceManufacturers';
import { ValidationUtils } from 'src/app/clients/shared/validation.utils';
import { DeviceStatusEnum } from 'src/app/shared/enums/DeviceStatusEnum';

@Component({
  selector: 'app-device-manufacturer-update',
  templateUrl: './device-manufacturer-update.component.html',
  styleUrls: ['./device-manufacturer-update.component.css']
})
export class DeviceManufacturerUpdateComponent implements OnInit {
  public deviceManufacturerFormGroup: FormGroup;
  private _deviceManufacturerId: string;
  private _method: string;
  public deviceManufacturerVals: DeviceManufacturers[] = [];
  public deviceManufacturer: DeviceManufacturers;
  public validationErrorsArray = [];


  public deviceManufacturerStatusArray: Option[] = [
    { value: DeviceStatusEnum.Deleted, label: 'Deleted' },
    { value: DeviceStatusEnum.Available, label: 'Available' },
  ];

  constructor(private _toastr: ToastrService, private _deviceService: DevicesService, private _router: Router,
    private _route: ActivatedRoute, private _formBuilder: FormBuilder) {
    this.deviceManufacturerFormGroup = this._formBuilder.group({
      id: [''],
      name: ['', Validators.required],
    });
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this._method = params['methodType'];
      this._deviceManufacturerId = params['deviceManufacturerId'];
      if (this._method !== 'update' && this._method !== 'insert') {
        this._router.navigate(['/hardware/devices']);
      }
      this._deviceService.getAllDeviceManufacturers().subscribe(result => {
        this.deviceManufacturerVals = result;
      });
      this._deviceService.geDeviceManufacturerInformationsById(this._deviceManufacturerId).subscribe(result => {
        this.deviceManufacturerFormGroup.patchValue(result);
      });
    });
  }

  onDeviceManufacturerFormSubmit() {
    if (this.deviceManufacturerFormGroup.valid) {
      this.deviceManufacturer = DeviceManufacturers.fromDto(this.deviceManufacturerFormGroup.value);
      if (this._method === 'update') {
        this._deviceService.updateDeviceManufacturerInformations(this.deviceManufacturer).subscribe(result => {
          this.deviceManufacturerFormGroup.patchValue(result);
          this._toastr.success('Device manufacturer updated with success');
        });
      }
      if (this._method === 'insert') {
        this.deviceManufacturer = DeviceManufacturers.fromDto(this.deviceManufacturerFormGroup.value);
        this._deviceService.insertDeviceManufacturersInformations(this.deviceManufacturer).subscribe(result => {
          this.deviceManufacturerFormGroup.patchValue(result);
          this._toastr.success('Device manufacturer inserted with success');
        });
      }
    }
    this.validationErrorsArray = ValidationUtils.getFormValidationErrors(this.deviceManufacturerFormGroup, 'deviceManufacturer');
  }
}

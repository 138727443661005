import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RegionsService } from './../shared/regions.service';
import { Country } from 'src/app/shared/models/Country';
import { Region } from 'src/app/shared/models/Region';

@Component({
  selector: 'app-edit-region-form',
  templateUrl: './edit-region-form.component.html',
  styleUrls: ['./edit-region-form.component.css']
})
export class EditRegionFormComponent implements OnInit {

  public editRegionForm: FormGroup;
  public editErrorArray = new Array();

  @Input()
  region: Region;

  @Input()
  regionsList: Region[];

  @Input()
  countriesList: Country[];

  @Input()
  countriesOptions: Option[];

  @Output()
  geResetRegionsList: EventEmitter<Region[]> = new EventEmitter<Region[]>();

  @Output()
  getCancelEditModeEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(private _formBuilder: FormBuilder, private _regionsService: RegionsService, private _toastr: ToastrService,
    private _translate: TranslateService) {

    this.editRegionForm = this._formBuilder.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      countryId: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.editRegionForm.patchValue({
      regionId: this.region.country.id,
      name: this.region.name,
      id: this.region.id
    });
  }

  ngOnInit() {
    this.regionsList;
  }

  onRegionSubmit() {
    const region = Region.fromDto(this.editRegionForm.value);
    region.country = this.countriesList.filter(country =>
      country.id.toString() === this.editRegionForm.value.countryId.toString())[0];
    this._regionsService.updateRegion(region).subscribe(result => {
      this.regionsList = result;
      this.geResetRegionsList.emit(this.regionsList);
      this.getCancelEditModeEvent.emit();
      this._toastr.success(this._translate.instant('Region updated with success'));
    }, error => {
      console.log(error);
    });
    this.editErrorArray = ValidationUtils.getFormValidationErrors(this.editRegionForm, 'region');
  }

  cancelEditButtonClick() {
    this.getCancelEditModeEvent.emit();
  }

}

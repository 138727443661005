import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-loading-dialog-modal',
  templateUrl: './loading-dialog-modal.component.html',
  styleUrls: ['./loading-dialog-modal.component.css']
})
export class LoadingDialogModalComponent implements OnInit {

  @ViewChild('loadingDialogModal')
  private loadingDialogModal: TemplateRef<any>;

  constructor(private modalService: NgbModal, modalConfig: NgbModalConfig) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
  }

  ngOnInit() {
  }

  openDialog(content) {
    this.modalService.open(this.loadingDialogModal, { centered: true });
  }

  closeDialog() {
    this.modalService.dismissAll();
  }

}

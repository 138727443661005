import { Product } from './Product';
import { Event } from './Event';
import { Client } from './Client';
import { ProductCategory } from './ProductCategory';
import { DeviceUser } from './DeviceUser';
import { Company } from './Company';

export class PriceList {
    public id: number;
    public name: string;
    public createdAt: string;
    public status: number;
    public productCategories: ProductCategory[];
    public event: Event;
    public deviceUser: DeviceUser;
    public clientUser: Client = new Client();
    public company: Company = new Company();

    static fromDto(p): PriceList {
        const priceList = new PriceList();
        if (!p) {
            return null;
        }

        priceList.id = p.id;
        priceList.name = p.name;
        priceList.createdAt = p.createdAt;
        priceList.status = p.status;
        if (p.productCategories) {
            priceList.productCategories = p.productCategories.map(category => ProductCategory.fromDto(category));
        }
        priceList.event = Event.fromDto(p.event);
        priceList.clientUser = Client.fromDto(p.clientUser);
        priceList.deviceUser = DeviceUser.fromDto(p.deviceUser);
        priceList.company = Company.fromDto(p.company);

        return priceList;
    }
}

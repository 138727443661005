import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {NavigationService} from '../../shared/navigation.service';
import {Language} from '../language';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  languages = Language.getAvailableLanguages();
  currentLanguage;
  constructor(public authService: AuthService, private navigation: NavigationService, private translate: TranslateService) {
    this.currentLanguage = Language.fromLocale(this.translate.currentLang) || Language.fromLocale(this.translate.getDefaultLang());

  }

  ngOnInit() {
  console.log(this.currentLanguage);
  }

  requestPasswordChange() {

  }

  logout() {
    event.preventDefault();
    this.authService.disconnect();
    this.navigation.login().go();
  }

  changeLanguage(language: Language) {
    this.currentLanguage = language;
    this.translate.use(this.currentLanguage.locale);
    localStorage.setItem('locale', this.currentLanguage.locale);
  }
}

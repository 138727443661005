import { Pipe, PipeTransform } from '@angular/core';
import { EventOfferStatusEnum } from '../enums/EventOfferStatusEnum';

@Pipe({
  name: 'eventOfferStatus'
})
export class EventOfferStatusPipe implements PipeTransform {

  transform(status: any): any {
    let humanStatus = '';
    switch (status) {
      case EventOfferStatusEnum.Rejected:
        humanStatus = 'Rejected';
        break;
      case EventOfferStatusEnum.Pending:
        humanStatus = 'Pending';
        break;
      case EventOfferStatusEnum.Accepted:
        humanStatus = 'Accepted';
        break;
      case EventOfferStatusEnum.Canceled:
        humanStatus = 'Canceled';
        break;
      case EventOfferStatusEnum.NewOfferRequested:
        humanStatus = 'New offer requested';
        break;
      default:
        humanStatus = 'No Status';
        break;
    }
    return humanStatus;
  }

}

import { Event } from './Event';
import { DeviceType } from './DeviceType';

export class RequestedDevice {
    public id: number;
    public quantity: number;
    public parentRequestId: number;
    public requestedAt: string;
    public event: Event;
    public deviceTypes: DeviceType;

    static fromDto(r): RequestedDevice {
        const requestedDevice = new RequestedDevice();

        if (!r) {
            return null;
        }

        requestedDevice.id = r.id;
        requestedDevice.quantity = r.quantity;
        requestedDevice.parentRequestId = r.parentRequestId;
        requestedDevice.requestedAt = r.requestedAt;
        requestedDevice.event = Event.fromDto(r.event);
        requestedDevice.deviceTypes = DeviceType.fromDto(r.deviceTypes);

        return requestedDevice;
    }
}

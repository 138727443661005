import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ROUTERS_ROUTES } from './routers.routes';
import { RoutersComponent } from './routers.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UpdateRouterComponent } from './update-router/update-router.component';
import { RoutersListComponent } from './routers-list/routers-list.component';
import { UpdateRouterTypeComponent } from './update-router-type/update-router-type.component';


@NgModule({
    imports: [
        RouterModule.forChild(ROUTERS_ROUTES),
        SharedModule,
        NgSelectModule
    ],
    declarations: [
        RoutersComponent,
        UpdateRouterComponent,
        RoutersListComponent,
        UpdateRouterTypeComponent,

    ],
    providers: [
    ]
})
export class RoutersModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from 'src/app/shared/http-utils';
import { URL_BASE, URL_IMAGES } from '../../core/constants';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PrinterType } from 'src/app/shared/models/PrinterType';
import { RouterType } from 'src/app/shared/models/RouterType';
import { DeviceType } from 'src/app/shared/models/DeviceType';
import { SoftwareType } from 'src/app/shared/models/SoftwareType';
import { Ups } from 'src/app/shared/models/Ups';
import { Cable } from 'src/app/shared/models/Cable';
import { Event } from 'src/app/shared/models/Event';
import { DeviceUserType } from 'src/app/shared/models/DeviceUserType';
import { DeviceUser } from 'src/app/shared/models/DeviceUser';
import { Printer } from 'src/app/shared/models/Printer';
import { Router } from 'src/app/shared/models/Router';
import { EventSearchCriteria } from './event-search-criteria.model';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { PriceList } from 'src/app/shared/models/PriceList';
import { Device } from 'src/app/shared/models/Device';
import { EventCost } from 'src/app/shared/models/EventCost';
import * as moment from 'moment';
import { PrintersCategories } from 'src/app/shared/models/PrintersCategories';
import { PickupPoint } from 'src/app/shared/models/PickupPoint';
import { DeliveryAddress } from 'src/app/shared/models/DeliveryAddress';
import { OnlineEvent } from 'src/app/shared/models/OnlineEvent';
import { DateUtils } from 'src/app/shared/date-utils';
import { AdditionalCost } from 'src/app/shared/models/AdditionalCost';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private _http: HttpClient) { }

  /**
   * get all the companies from the database
   * ERROR 600 -> No companies in the database
   */
  public search(page: number, size: number, criteria: EventSearchCriteria): Observable<SearchObjectResult<Event>> {
    const criteriaCopy = { ...criteria };
    if (criteria.startDate) {
      criteriaCopy.startDate = moment(criteriaCopy.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    if (criteria.endDate) {
      criteriaCopy.endDate = moment(criteriaCopy.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    if (criteria.pickUpDate) {
      criteriaCopy.pickUpDate = moment(criteriaCopy.pickUpDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    if (criteria.returnDate) {
      criteriaCopy.returnDate = moment(criteriaCopy.returnDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };

    console.log('params: ', params);
    return this._http.get(URL_BASE + '/admin/event/search', { headers, params }).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, Event.fromDto);
      })
    );
  }

  /**
   * getAllPrinterTypes
   */
  public getAllPrinterTypes(): Observable<PrinterType[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/printerType/get', { headers }).pipe(
      map((result: any[]) => result.map(res => PrinterType.fromDto(res)))
    );
  }

  /**
   * getAllRouterTypes
   */
  public getAllRouterTypes(): Observable<RouterType[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/routerType/get', { headers }).pipe(
      map((result: any[]) => result.map(res => RouterType.fromDto(res)))
    );
  }

  /**
   * getAllDeviceTypes
   */
  public getAllDeviceTypes(): Observable<DeviceType[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/deviceType/get', { headers }).pipe(
      map((result: any[]) => result.map(res => DeviceType.fromDto(res)))
    );
  }

  /**
   * getAllDevices
   */
  public getAllDevices(): Observable<Device[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/device/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Device.fromDto(res)))
    );
  }

  /**
   * getAllSoftwareTypes
   */
  public getAllSoftwareTypes(): Observable<SoftwareType[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/softwareType/get', { headers }).pipe(
      map((result: any[]) => result.map(res => SoftwareType.fromDto(res)))
    );
  }

  /**
   * getAllUps
   */
  public getAllUps(): Observable<Ups[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/ups/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Ups.fromDto(res)))
    );
  }

  /**
   * getAllCables
   */
  public getAllCables(): Observable<Cable[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/cable/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Cable.fromDto(res)))
    );
  }

  /**
   * insertEventRequest
   */
  public insertEventRequest(event: Event): Observable<Event> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/insert', { event }, { headers }).pipe(
      map((res: any) => Event.fromDto(res)));
  }

  /**
   * getEventById
   */
  public getEventById(eventId: string): Observable<Event> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/event/get', { headers: headers, params: { id: eventId } }).pipe(
      map((res: any) => Event.fromDto(res)));
  }
  /**
   * getEventById
   */
  public getEvents(): Observable<Event[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/event/get', { headers: headers }).pipe(
      map((result: any[]) => result.map(res => Event.fromDto(res)))
    );
  }

  /**
   * getDeviceUserTypes
   */
  public getDeviceUserTypes(): Observable<DeviceUserType[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/deviceUserType/get', { headers: headers }).pipe(
      map((result: any[]) => result.map(res => DeviceUserType.fromDto(res)))
    );
  }

  /**
   * getAllDeviceUsers
   */
  public getAllDeviceUsersByEventId(eventId: string): Observable<DeviceUser[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/deviceUser/get', { headers: headers, params: { id: eventId } }).pipe(
      map((result: any[]) => result.map(res => DeviceUser.fromDto(res)))
    );
  }

  /**
   * generateNewDeviceUsersByEventId
   */
  public generateNewDeviceUsersByEventId(eventId: string, language: string): Observable<DeviceUser[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/deviceUser/generateDeviceUser', { headers: headers, params: { eventId, language } }).pipe(
      map((result: any[]) => result.map(res => DeviceUser.fromDto(res)))
    );
  }

  /**
   * updateDeviceUser
   */
  public updateDeviceUser(deviceUser: DeviceUser, eventId: string): Observable<DeviceUser> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/deviceUser/update', { deviceUser, eventId }, { headers }).pipe(
      map((res: any) => DeviceUser.fromDto(res)));
  }

  /**
   * getAllPrinters
   */
  public getAllPrinters(): Observable<Printer[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/printer/get', { headers: headers }).pipe(
      map((result: any[]) => result.map(res => Printer.fromDto(res)))
    );
  }

  /**
   * assignPrinterToEventByIds
   */
  public assignPrinterToEventByIds(eventId: string, printers: Printer[]): Observable<Event> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/assignPrinter', { eventPrinter: { eventId, printers } }, { headers }).pipe(
      map((res: any) => Event.fromDto(res)));
  }

  /**
   * unAssignPrinterFromEventById
   */
  public unAssignPrinterFromEventById(eventId: string, printers: Printer[]): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/unAssignPrinter', { eventPrinter: { eventId, printers } }, { headers }).pipe(
      map((res: any) => res));
  }

  /**
   * getAllRouters
   */
  public getAllRouters(): Observable<Router[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/router/get', { headers: headers }).pipe(
      map((result: any[]) => result.map(res => Router.fromDto(res)))
    );
  }

  /**
   * assignRouterToEventByIds
   */
  public assignRouterToEventByIds(eventId: string, routers: Router[]): Observable<Event> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/assignRouter', { eventRouter: { eventId, routers } }, { headers }).pipe(
      map((res: any) => Event.fromDto(res)));
  }

  /**
   * unAssignRouterFromEventById
   */
  public unAssignRouterFromEventById(eventId: string, routers: Router[]): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/unAssignRouter', { eventRouter: { eventId, routers } }, { headers }).pipe(
      map((res: any) => res));
  }

  /**
   * uploadNewPriceListToEvent
   */
  public uploadNewPriceListToEvent(fileToUpload: File, clientId: string, priceListName: string): Observable<any> {
    console.log({ clientId, 'file': fileToUpload, priceListName });
    const formData = new FormData();
    formData.append('clientId', clientId);
    formData.append('priceListName', priceListName);
    formData.append('file', fileToUpload, fileToUpload.name);
    const headers = HttpUtils.createHeaders();
    console.log(headers);
    return this._http.post(URL_BASE + '/admin/priceList/upload', formData, { headers }).pipe(
      map((res: any) => res));
  }

  /**
   * getUnusedPriceListsByCompanyId
   */
  public getUnusedPriceListsByCompanyId(companyId: string): Observable<PriceList[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/priceList/getAllUnusedPriceLists', { headers: headers, params: { companyId } }).pipe(
      map((result: any[]) => result.map(res => PriceList.fromDto(res)))
    );
  }

  /**
   * assignPriceListToSpecifcEvent
   */
  public assignPriceListToSpecifcEvent(eventId: string, priceListId: string): Observable<PriceList> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/assignPriceList',
      { eventPriceList: { eventId, priceListId } }, { headers }).pipe(
        map((res: PriceList) => res));
  }

  /**
   * assignPriceListToSpecifcEvent
   */
  public unassignPriceListFromSpecifcEvent(eventId: string, priceListId: string): Observable<PriceList> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/unassignPriceList',
      { eventId, priceListId }, { headers }).pipe(
        map((res: PriceList) => res));
  }

  /**
   * getAssignedPriceListsOfEvent
   */
  public getAssignedPriceListsOfEvent(clientId: string, eventId: string): Observable<PriceList[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/priceList/getAllAssignedPriceLists', { headers: headers, params: { clientId, eventId } }).pipe(
      map((result: any[]) => result.map(res => PriceList.fromDto(res)))
    );
  }

  /**
   * approveEvent
  */
  public approveEvent(eventId: number) {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/approveEvent', { eventId }, { headers }).pipe(
      map((res: any) => res));
  }

  /**
   * cancelEvent
   */
  public cancelEvent(eventId: number) {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/cancelEvent', { eventId }, { headers }).pipe(
      map((res: any) => res));
  }

  /**
   * updateEvent
   */
  public updateEvent(event: any) {
    console.log('myEventToUpdate', event);
    if ((event.eventAdditionalCosts) && (event.eventAdditionalCosts.length > 0)) {
      event.additionalCosts = [...event.eventAdditionalCosts] ;
    }
    console.log('myEventToUpdate2', event);
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/update', { event }, { headers }).pipe(
      map((res: any) => res));
  }

  /**
   *  preparingEvent
   */
  public preparingEvent(eventId: string, preparingDate: string) {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/preparingEvent', { eventId, preparingDate }, { headers }).pipe(
      map((res: any) => res));
  }

  /**
   * getAllCables
   */
  public calculateModifiedPrice(event: any): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/admin/event/calculateModifiedPrice', { event }, { headers: headers }).pipe(
      map((res: any) => EventCost.fromDto(res)));
  }

  /**
   * generateRequestedDeviceUsers
   */
  public generateRequestedDeviceUsers(eventId: number, requestedDeviceUserTypes: any, language: string): Observable<DeviceUser[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/admin/deviceUser/generateRequestedDeviceUsers', { eventId, requestedDeviceUserTypes, language },
      { headers: headers }).pipe(
        map((result: any[]) => result.map(res => DeviceUser.fromDto(res)))
      );
  }

  /**
   *  insertPrintersCategories
   */
  public insertPrintersCategories(printersCategories: any[], eventId: string) {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/printersCategories/insert', { printersCategories, eventId }, { headers }).pipe(
      map((res: any) => res));
  }

  /**
   * getPrintersCategoriesByEventId
   */
  public getPrintersCategoriesByEventId(eventId: string): Observable<PrintersCategories[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/printersCategories/get', { headers: headers, params: { eventId } }).pipe(
      map((result: any[]) => result.map(res => PrintersCategories.fromDto(res)))
    );
  }

  /**
   * getPrintersCategoriesByEventIdV2
   */
   public getPrintersCategoriesByEventIdV2(eventId: string): Observable<any[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/printersCategories/deliveryPaper/get', { headers: headers, params: { eventId } }).pipe(
      map((res: any[]) => res)
    );
  }

  /**
   * updateEventLocation
   */
  public updateEventLocation(eventId: number, latitude: string, longitude: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/updateLocation', { eventId, latitude, longitude }, { headers }).pipe(
      map((res: any) => res));
  }

  /**
   * updateSimpleEventSpecs
   */
  public updateSimpleEventSpecs(event: Event): Observable<any> {
    console.log('updateSimpleEventSpecs: ', event);
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/updateSpecs', { event }, { headers }).pipe(
      map((res: any) => res));
  }

  /**
   * getNextAndPreviousEventIds
   */
  public getNextAndPreviousEventIds(eventId: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/event/nextAndPreviousIds', { headers: headers, params: { eventId } }).pipe(
      map((res: any) => res));
  }

  /**
   * deletePriceList
   */
  public forceDeleteEvent(eventId: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.delete(URL_BASE + '/admin/event/delete', { // event/hardDeleteEvent
      headers,
      params: { id: eventId }
    }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * getNextAndPreviousEventIds
   */
  public getPickupPointByEventId(eventId: string): Observable<PickupPoint> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/event/getPickupPoint', { headers: headers, params: { eventId } }).pipe(
      map((res: any) => PickupPoint.fromDto(res)));
  }

  /**
   * getDeliveryAddressByEventId
   */
  public getDeliveryAddressByEventId(eventId: string): Observable<DeliveryAddress> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/event/getDeliveryAddress', { headers: headers, params: { eventId } }).pipe(
      map((res: any) => DeliveryAddress.fromDto(res)));
  }


  /**
   * updateDeliveryMethodEvent
   */
  public updateDeliveryMethodEvent(eventId: string, delivery: boolean, pickupPointId: number, deliveryAddress: any): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/event/updateDeliveryInformations',
      { eventId, delivery, pickupPointId, deliveryAddress }, { headers }).pipe(
        map((res: any) => res));
  }

  /**
   * publishEventOnline
   */
  public publishEventOnline(status: string, id: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/admin/onlineEvent/changePublishValue', { id, status }, { headers }).pipe(
      map((result: any[]) => result)
    );
  }


  public getOnlineEventByEventId(eventId: string): Observable<OnlineEvent> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/onlineEvent/getByEventId', { headers: headers, params: { eventId } }).pipe(
      map((result: any) => OnlineEvent.fromDto(result))
    );
  }

  public changePublishValue(id: string, status: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/admin/onlineEvent/changePublishValue', { id, status }, { headers }).pipe(
      map((result: any) => result)
    );
  }

  /**
   * uploadNewPriceListToEvent
   */
  public insertOnlineEvent(fileToUpload: File, imageName: string, eventId: string, onlineEvent: OnlineEvent,
    imageRemoved: boolean): Observable<any> {
    const formData = new FormData();
    formData.append('eventOfferId', '0');
    formData.append('eventId', eventId);
    formData.append('onlineEvent', JSON.stringify(onlineEvent));
    if (fileToUpload) {
      formData.append('imageName', imageName);
      formData.append('file', fileToUpload, fileToUpload.name);
    } else {
      console.log('pictureName', onlineEvent.pictureName);
      if (!imageRemoved && onlineEvent.pictureName) {
        const myImageName = this.replaceSubstring(onlineEvent.pictureName, URL_IMAGES + eventId + '/', '');
        formData.append('imageName', myImageName);
      }
    }
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/admin/onlineEvent/insert', formData, { headers }).pipe(
      map((res: any) => res));
  }


  public replaceSubstring(inSource, inToReplace, inReplaceWith) {
    const outString = [];
    const repLen = inToReplace.length;

    while (true) {
      const idx = inSource.indexOf(inToReplace);
        if (idx === -1) {
            outString.push(inSource);
            break;
        }

        outString.push(inSource.substring(0, idx));
        outString.push(inReplaceWith);

        inSource = inSource.substring(idx + repLen);
    }

    return outString.join('');
  }


  /**
  * getAdditionalCosts
  */
  public getAdditionalCosts(): Observable<AdditionalCost[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/additionalCosts/get', { headers }).pipe(
      map((result: any[]) => result.map(res => AdditionalCost.fromDto(res)))
    );
  }

  /**
  * getAdditionalCostsAssigned
  */
  public getAdditionalCostsAssigned(eventId: string): Observable<any[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/additionalCosts/getAssigned', { headers: headers, params: { eventId } }).pipe(
      map((result: any[]) => result)
    );
  }
}

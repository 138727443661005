import { Routes } from '@angular/router';
import { CompaniesComponent } from './companies.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { CompaniesListComponent } from './companies-list/companies-list.component';
import { UpdateCompanyComponent } from './update-company/update-company.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';


export const COMPANIES_ROUTES: Routes = [
    {
        path: 'companies', component: CompaniesComponent, canActivate: [AuthGuard], children: [
            { path: '', component: CompaniesListComponent, canActivate: [AuthGuard] },
            { path: 'details/:companyId', component: CompanyDetailsComponent, canActivate: [AuthGuard] },
            { path: ':methodType', component: UpdateCompanyComponent, canActivate: [AuthGuard] },
            { path: ':methodType/:companyId', component: UpdateCompanyComponent, canActivate: [AuthGuard] }
        ]
    }
];

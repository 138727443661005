import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Event } from 'src/app/shared/models/Event';
import { RequestedPrinterType } from 'src/app/shared/models/RequestedPrinterType';
import { RequestedUps } from 'src/app/shared/models/RequestedUps';
import { RequestedCable } from 'src/app/shared/models/RequestedCable';
import { RequestedDevice } from 'src/app/shared/models/RequestedDevice';
import { RequestedRouterType } from 'src/app/shared/models/RequestedRouterType';
import { RequestedSoftwareType } from 'src/app/shared/models/RequestedSoftwareType';
import { EventsService } from 'src/app/events/shared/events.service';
import { RouterType } from '../models/RouterType';
import { PrinterType } from '../models/PrinterType';
import { DeviceType } from '../models/DeviceType';
import { SoftwareType } from '../models/SoftwareType';
import { Ups } from '../models/Ups';
import { Cable } from '../models/Cable';
import { forkJoin, Observable } from 'rxjs';
import { CableStringToInt } from '../models/CablesStringToInt';
import { ToastrService } from 'ngx-toastr';
import { DeviceUser } from '../models/DeviceUser';
import { PrintersCardComponent } from 'src/app/events/event-details/cards/printers-card/printers-card.component';
import { RoutersCardComponent } from 'src/app/events/event-details/cards/routers-card/routers-card.component';
export class PriceCalculation {
  public eventId: number;
  public days: number;
  public countryId: number;
  public requestedPrinterTypes: RequestedPrinterType[] = [];
  public requestedUps: RequestedUps[] = [];
  public requestedCables: RequestedCable[] = [];
  public requestedDevices: RequestedDevice[] = [];
  public requestedRouterTypes: RequestedRouterType[] = [];
  public requestedSoftwareTypes: RequestedSoftwareType[] = [];
}

class RequestedDeviceUserType {
  public quantity: number;
  public deviceUserType: DeviceUserType = new DeviceUserType();
}

class DeviceUserType {
  public id: number;
}

@Component({
  selector: 'app-request-event-hardware-software',
  templateUrl: './request-event-hardware-software.component.html',
  styleUrls: ['./request-event-hardware-software.component.css']
})
export class RequestEventHardwareSoftwareComponent implements OnInit, OnChanges {

  public requestFormGroup: FormGroup;
  public loading = true;
  public currentEventPrice = 0.00;
  public newRequestPrice = 0.00;
  public priceDifference = 0.00;

  private _routerTypesArray: RouterType[];
  private _printerTypesArray: PrinterType[];
  private _deviceTypesArray: DeviceType[];
  private _softwareTypesArray: SoftwareType[];
  private _upsArray: Ups[];
  private _cableArray: Cable[];
  private _eventCopy: Event = new Event();
  private _cableStringToInt = new CableStringToInt();
  private _formSubmitted = false;
  private _defaultFormValues = '';
  private _requestedDeviceUserTypes: RequestedDeviceUserType[] = [];
  private _modifiedPriceCalculation: PriceCalculation = new PriceCalculation();

  @Input()
  currentEvent = undefined;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onEventUpdate: EventEmitter<Event> = new EventEmitter<Event>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onDeviceUserGenereated: EventEmitter<DeviceUser[]> = new EventEmitter<DeviceUser[]>();

  constructor(private _formBuilder: FormBuilder, private _eventsService: EventsService, private _toastr: ToastrService) {
    this.requestFormGroup = this._formBuilder.group({
      softwares: this._formBuilder.group({
        waiters: new FormControl(0),
        cashRegister: new FormControl(0),
        mainDevice: new FormControl(0),
        printers: new FormControl(0),
      }),
      hardware: this._formBuilder.group({
        phones: new FormControl(0),
        tablets: new FormControl(0),
        printer: new FormControl(0),
        routers: new FormControl(0),
        ups: new FormControl(0),
      }),
      cables: this._formBuilder.group({
        one: new FormControl(0),
        five: new FormControl(0),
        ten: new FormControl(0),
        fifteen: new FormControl(0),
        twenty: new FormControl(0),
        thirty: new FormControl(0),
        fifty: new FormControl(0)
      })
    });
    this._defaultFormValues = JSON.stringify(this.requestFormGroup.getRawValue());
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentEvent.currentValue) {
      this._eventCopy = JSON.parse(JSON.stringify(changes.currentEvent.currentValue));
      if (!changes.currentEvent.firstChange) {
        // this._priceCalculation(changes.currentEvent.currentValue).subscribe(currentPrice => {
        //   this.currentEventPrice = currentPrice;
        // });
      }
    }
  }

  ngOnInit() {
    forkJoin([
      this._eventsService.getAllRouterTypes(),
      this._eventsService.getAllPrinterTypes(),
      this._eventsService.getAllDeviceTypes(),
      this._eventsService.getAllSoftwareTypes(),
      this._eventsService.getAllUps(),
      this._eventsService.getAllCables()
    ]).subscribe(responseList => {
      this._routerTypesArray = responseList[0];
      this._printerTypesArray = responseList[1];
      this._deviceTypesArray = responseList[2];
      this._softwareTypesArray = responseList[3];
      this._upsArray = responseList[4];
      this._cableArray = responseList[5];
      this.loading = false;
    });
  }

  onFormChanges() {
    this.requestFormGroup.valueChanges.subscribe(value => {
      this._eventCopy = JSON.parse(JSON.stringify(this.currentEvent));
      this._modifiedPriceCalculation = new PriceCalculation();
      this._requestedDeviceUserTypes = [];
      if (value.softwares.waiters > 0) {
        this._addNewSoftwareType(value.softwares.waiters, 1);
        this._addNewRequestedDeviceUserType(value.softwares.waiters, 1);
      }
      if (value.softwares.cashRegister > 0) {
        this._addNewSoftwareType(value.softwares.cashRegister, 2);
        this._addNewRequestedDeviceUserType(value.softwares.cashRegister, 2);
      }
      if (value.softwares.mainDevice > 0) {
        this._addNewSoftwareType(value.softwares.mainDevice, 3);
        this._addNewRequestedDeviceUserType(value.softwares.mainDevice, 3);
      }
      if (value.softwares.printers > 0) {
        this._addNewSoftwareType(value.softwares.printers, 4);
      }
      if (value.hardware.phones > 0) {
        console.log('phones value', value.hardware.phones);
        this._addNewDeviceType(value.hardware.phones, 1);
      }
      if (value.hardware.tablets > 0) {
        console.log('tablets value', value.hardware.tablets);
        this._addNewDeviceType(value.hardware.tablets, 2);
      }
      if (value.hardware.printer > 0) {
        let parentRequestId = null;
        if (this._eventCopy.requestedPrinterTypes.length > 0) {
          parentRequestId = this._eventCopy.requestedPrinterTypes[this._eventCopy.requestedPrinterTypes.length - 1].parentRequestId;
          if (parentRequestId === null) {
            parentRequestId = this._eventCopy.requestedPrinterTypes[this._eventCopy.requestedPrinterTypes.length - 1].id;
          }
        }
        const printerRequest = new RequestedPrinterType();
        printerRequest.quantity = value.hardware.printer;
        printerRequest.parentRequestId = parentRequestId;
        printerRequest.requestedAt = moment().format('YYYY-MM-DD HH:mm:ss');
        printerRequest.printerType = this._printerTypesArray.filter(printerType => printerType.id === 1)[0];
        this._eventCopy.requestedPrinterTypes.push(printerRequest);
        this._modifiedPriceCalculation.requestedPrinterTypes.push(printerRequest);
      }
      if (value.hardware.routers > 0) {
        let parentRequestId = null;
        if (this._eventCopy.requestedRouterTypes.length > 0) {
          parentRequestId = this._eventCopy.requestedRouterTypes[this._eventCopy.requestedRouterTypes.length - 1].parentRequestId;
          if (parentRequestId === null) {
            parentRequestId = this._eventCopy.requestedRouterTypes[this._eventCopy.requestedRouterTypes.length - 1].id;
          }
        }
        const routerRequest = new RequestedRouterType();
        routerRequest.quantity = value.hardware.routers;
        routerRequest.parentRequestId = parentRequestId;
        routerRequest.requestedAt = moment().format('YYYY-MM-DD HH:mm:ss');
        routerRequest.routerTypes = this._routerTypesArray.filter(routerType => routerType.id === 1)[0];
        this._eventCopy.requestedRouterTypes.push(routerRequest);
        this._modifiedPriceCalculation.requestedRouterTypes.push(routerRequest);
      }

      if (value.hardware.ups > 0) {
        let parentRequestId = null;
        if (this._eventCopy.requestedUps.length > 0) {
          parentRequestId = this._eventCopy.requestedUps[this._eventCopy.requestedUps.length - 1].parentRequestId;
          if (parentRequestId === null) {
            parentRequestId = this._eventCopy.requestedUps[this._eventCopy.requestedUps.length - 1].id;
          }
        }
        const upsRequest = new RequestedUps();
        upsRequest.quantity = value.hardware.ups;
        upsRequest.parentRequestId = parentRequestId;
        upsRequest.requestedAt = moment().format('YYYY-MM-DD HH:mm:ss');
        upsRequest.ups = this._upsArray.filter(ups => ups.id === 1)[0];
        this._eventCopy.requestedUps.push(upsRequest);
        this._modifiedPriceCalculation.requestedUps.push(upsRequest);
      }

      for (const key in value.cables) {
        if (value.cables.hasOwnProperty(key)) {
          const quantity = value.cables[key];
          const cableLenght = this._cableStringToInt[key];
          if (quantity > 0) {
            const requestedCables = this._eventCopy.requestedCable.filter(cable => cable.cables.cableType.length === cableLenght);
            let parentRequestId = null;
            if (requestedCables.length > 0) {
              parentRequestId = this._eventCopy.requestedCable[this._eventCopy.requestedCable.length - 1].parentRequestId;
              if (parentRequestId === null) {
                parentRequestId = this._eventCopy.requestedCable[this._eventCopy.requestedCable.length - 1].id;
              }
            }
            const cableRequest = new RequestedCable();
            cableRequest.quantity = quantity;
            cableRequest.parentRequestId = parentRequestId;
            cableRequest.requestedAt = moment().format('YYYY-MM-DD HH:mm:ss');
            cableRequest.cables = this._cableArray.filter(cable => cable.cableType.length === cableLenght)[0];
            this._eventCopy.requestedCable.push(cableRequest);
            this._modifiedPriceCalculation.requestedCables.push(cableRequest);
          }
        }
      }
      // if (!this._formSubmitted) {
      //   this._calculationModifiedPrice(this._eventCopy).subscribe(newPrice => {
      //     this.newRequestPrice = newPrice;
      //     this.priceDifference = (this.newRequestPrice - this.currentEventPrice);
      //   });
      // }
    });
  }

  private _priceCalculation(event: Event): any {
    // tslint:disable-next-line:no-shadowed-variable
    // const priceObservable = new Observable(observable => {
    //   let price = 0.00;
    //   const duration = moment.duration(moment(event.endDate, 'YYYY-MM-DD').diff(moment(event.startDate, 'YYYY-MM-DD')));
    //   const priceRequest: PriceCalculation = new PriceCalculation();
    //   priceRequest.days = duration.asDays() + 1;
    //   priceRequest.countryId = event.clientUser.company.country.id;
    //   priceRequest.requestedCables = event.requestedCable;
    //   priceRequest.requestedDevices = event.requestedDevices;
    //   priceRequest.requestedPrinterTypes = event.requestedPrinterTypes;
    //   priceRequest.requestedRouterTypes = event.requestedRouterTypes;
    //   priceRequest.requestedSoftwareTypes = event.requestedSoftwareTypes;
    //   priceRequest.requestedUps = event.requestedUps;
    //   this._eventsService.calculatePrice(priceRequest).subscribe(result => {
    //     price += result.totalPrinterPrice;
    //     price += result.totalUpsTypePrice;
    //     price += result.totalDeviceTypePrice;
    //     price += result.totalRouterTypePrice;
    //     price += result.totalSoftwareTypePrice;
    //     observable.next(price);
    //   }, error => {
    //     console.log(error);
    //   });
    //   observable.next(null);
    // });
    // return priceObservable;
    return 0;
  }

  private _calculationModifiedPrice(event: Event): any {
    const priceObservable = new Observable(observable => {
      let price = 0.00;
      const duration = moment.duration(moment(event.endDate, 'YYYY-MM-DD').diff(moment(event.startDate, 'YYYY-MM-DD')));
      this._modifiedPriceCalculation.days = duration.asDays() + 1;
      this._modifiedPriceCalculation.eventId = event.id;
      this._eventsService.calculateModifiedPrice(this._modifiedPriceCalculation).subscribe(result => {
        price += result.totalPrinterPrice;
        price += result.totalUpsTypePrice;
        price += result.totalDeviceTypePrice;
        price += result.totalRouterTypePrice;
        price += result.totalSoftwareTypePrice;
        observable.next(price);
      }, error => {
        console.log(error);
      });
      observable.next(null);
    });
    return priceObservable;
  }

  addNewHardwareSoftware() {
    this._eventsService.updateEvent(this._eventCopy).subscribe(result => {
      if (this._requestedDeviceUserTypes.length > 0) {
        this._eventsService.generateRequestedDeviceUsers(this._eventCopy.id, this._requestedDeviceUserTypes, this._eventCopy.language)
          .subscribe(deviceUsers => {
            this.onDeviceUserGenereated.emit(deviceUsers);
            this._toastr.success('Generated additional Device Users');
          });
      }
      this._formSubmitted = true;
      this.currentEvent = this._eventCopy;
      this.newRequestPrice = 0.00;
      this.priceDifference = 0.00;
      // this._priceCalculation(this.currentEvent).subscribe(currentPrice => {
      //   if (currentPrice != null) {
      //     this.currentEventPrice = currentPrice;
      //   }
      // });
      this.requestFormGroup.reset(JSON.parse(this._defaultFormValues));
      this._formSubmitted = false;
      this.onEventUpdate.emit(this.currentEvent);
      console.log(this.currentEvent);
      this._toastr.success('Requested Device / Software successfully added');
    });
  }

  private _addNewRequestedDeviceUserType(quantity: number, softwareTypeId: number) {
    const requestedDeviceUserType = new RequestedDeviceUserType();
    requestedDeviceUserType.quantity = quantity;
    requestedDeviceUserType.deviceUserType.id = softwareTypeId;
    this._requestedDeviceUserTypes.push(requestedDeviceUserType);
  }

  private _addNewSoftwareType(quantity: number, softwareTypeId: number) {
    const requestsArray = this._eventCopy.requestedSoftwareTypes.filter(req => req.softwareType.id === softwareTypeId);
    let lastSoftwareTypeRequestId = null;
    if (requestsArray.length > 0) {
      lastSoftwareTypeRequestId = requestsArray[requestsArray.length - 1].parentRequestId;
      if (lastSoftwareTypeRequestId === null) {
        lastSoftwareTypeRequestId = requestsArray[requestsArray.length - 1].id;
      }
    }
    const softwareTypeRequest = new RequestedSoftwareType();
    softwareTypeRequest.quantity = quantity;
    softwareTypeRequest.parentRequestId = lastSoftwareTypeRequestId;
    softwareTypeRequest.requestedAt = moment().format('YYYY-MM-DD HH:mm:ss');
    softwareTypeRequest.softwareType = this._softwareTypesArray.filter(softwareType => softwareType.id === softwareTypeId)[0];
    this._eventCopy.requestedSoftwareTypes.push(softwareTypeRequest);
    this._modifiedPriceCalculation.requestedSoftwareTypes.push(softwareTypeRequest);
  }

  private _addNewDeviceType(quantity: number, deviceTypeId: number) {
    const requestsArray = this._eventCopy.requestedDevices.filter(req => req.deviceTypes.id === deviceTypeId);
    let lastDeviceTypeRequestId = null;
    if (requestsArray.length > 0) {
      lastDeviceTypeRequestId = requestsArray[requestsArray.length - 1].parentRequestId;
      if (lastDeviceTypeRequestId === null) {
        lastDeviceTypeRequestId = requestsArray[requestsArray.length - 1].parentRequestId;
      }
    }
    const deviceRequest = new RequestedDevice();
    deviceRequest.quantity = quantity;
    deviceRequest.parentRequestId = lastDeviceTypeRequestId;
    deviceRequest.requestedAt = moment().format('YYYY-MM-DD HH:mm:ss');
    deviceRequest.deviceTypes = this._deviceTypesArray.filter(deviceType => deviceType.id === deviceTypeId)[0];
    this._eventCopy.requestedDevices.push(deviceRequest);
    this._modifiedPriceCalculation.requestedDevices.push(deviceRequest);
  }
}

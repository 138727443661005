import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from 'src/app/shared/http-utils';
import { Device } from 'src/app/shared/models/Device';
import { URL_BASE } from 'src/app/core/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DevicesSearchCriteria } from './DevicesSearchCriteria';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { DeviceType } from 'src/app/shared/models/DeviceType';
import { DeviceManufacturers } from 'src/app/shared/models/DeviceManufacturers';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  public devicesArray: any[] = [];

  constructor(private _http: HttpClient) { }

  public search(page: number, size: number, criteria: DevicesSearchCriteria): Observable<SearchObjectResult<Device>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(URL_BASE + '/admin/devices/search', { headers, params }).pipe(
      map((res: any) => {
        this.devicesArray = res;

        return SearchObjectResult.fromDto(res, Device.fromDto);
      })
    );
  }

  // get all devices
  public getAlldDevices(): Observable<Device> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/device/get', { headers }).pipe(
      map((res: Device) => {
        return res;
      }));
  }

  // all
  public getAllDeviceTypes(): Observable<DeviceType[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/deviceType/get', { headers }).pipe(
      map((res: DeviceType[]) => {
        return res;
      }));
  }

  public getAllDeviceManufacturers(): Observable<DeviceManufacturers[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/deviceManufacturer/get', { headers }).pipe(
      map((res: DeviceManufacturers[]) => {
        return res;
      }));
  }

  public geDeviceManufacturerInformationsById(deviceManufacturerId: string): Observable<DeviceManufacturers> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/deviceManufacturer/get', { headers, params: { id: deviceManufacturerId } }).pipe(
      map((res: DeviceManufacturers) => {
        return res;
      }));
  }
/*
  public getAllDeviceManufacturers(): Observable<DeviceManufacturers[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/deviceManufacturer/get', { headers }).pipe(
      map((res: DeviceManufacturers[]) => {
        return res;
      }));
  }
  */

  // devices by Id
  public getAlldDevicesById(deviceId: string): Observable<Device> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/device/get', { headers, params: { id: deviceId } }).pipe(
      map((res: Device) => {
        return res;
      }));
  }

  // update device informations
  public updateDevice(device: Device): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/device/update', { device: device }, { headers }).pipe(
      map((res: Device) => {
        return res;
      }));
  }

  // insert device
  public insertDevice(device: Device): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/device/insert', { device: device }, { headers }).pipe(
      map((res: Device) => {
        return res;
      }));
  }


  // delete device
  public deleteDevice(id: string, force: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.delete(URL_BASE + '/admin/device/delete', {
      headers,
      params: { id, force }
    }).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }));
  }

  public updateDeviceTypeInformations(deviceType: DeviceType): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/deviceType/update', { deviceType: deviceType }, { headers }).pipe(
      map((res: DeviceType) => {
        return res;
      }));
  }

  public updateDeviceManufacturerInformations(deviceManufacturer: DeviceManufacturers): Observable<any> {
    console.log('service update', deviceManufacturer);
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/deviceManufacturer/update', { deviceManufacturer: deviceManufacturer }, { headers }).pipe(
      map((res: DeviceManufacturers) => {
        return res;
      }));
  }

  public insertDeviceTypeInformations(deviceType: DeviceType): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/deviceType/insert', { deviceType: deviceType }, { headers }).pipe(
      map((res: DeviceType) => {
        return res;
      }));
  }

  public insertDeviceManufacturersInformations(deviceManufacturer: DeviceManufacturers): Observable<any> {
    console.log('service', deviceManufacturer);
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/deviceManufacturer/insert', { deviceManufacturer: deviceManufacturer }, { headers }).pipe(
      map((res: DeviceManufacturers) => {
        return res;
      }));
  }


  // delete deviceType
  public deleteDeviceTypeApi(id: string, force: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.delete(URL_BASE + '/admin/deviceType/delete', {
      headers,
      params: { id, force }
    }).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DpDatePickerModule } from 'ng2-date-picker';
import { SelectComponent } from './select/select.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { FormGroupInputComponent } from './form-group-input/form-group-input.component';
import { NgFormGroupTextareaComponent } from './ng-form-group-textarea/ng-form-group-textarea.component';
import { NgFormGroupSelectComponent } from './ng-form-group-select/ng-form-group-select.component';
import { NgFormGroupInputComponent } from './ng-form-group-input/ng-form-group-input.component';
import { FormGroupPwdGeneratorComponent } from './form-group-pwd-generator/form-group-pwd-generator.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroupPriceInputComponent } from './form-group-price-input/form-group-price-input.component';
import { FormGroupDatepickerComponent } from './form-group-datepicker/form-group-datepicker.component';
import { FormGroupNumberInputComponent } from './form-group-number-input/form-group-number-input.component';
import { FormGroupTimepickerComponent } from './form-group-timepicker/form-group-timepicker.component';
import { FormGroupSelectComponent } from './form-group-select/form-group-select.component';
import { RequestEventModalComponent } from './modals/request-event-modal/request-event-modal.component';
import { DataTableComponent } from './data-table/data-table.component';
import { DataTableColumnComponent } from './data-table/data-table-column/data-table-column.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DataFiltersComponent } from './data-filters/data-filters.component';
import { DataFilterGroupComponent } from './data-filters/data-filter-group/data-filter-group.component';
import { PaginationService } from './pagination.service';
import { DataFilterInputComponent } from './data-filters/data-filter-input/data-filter-input.component';
import { DataFilterButtonComponent } from './data-filters/data-filter-button/data-filter-button.component';
import { PriceListPreviewModalComponent } from './modals/price-list-preview-modal/price-list-preview-modal.component';
import { DataFilterSelectComponent } from './data-filters/data-filter-select/data-filter-select.component';
import { ClientCompanyModalComponent } from './modals/client-company-modal/client-company-modal.component';
import { DataTabsetComponent } from './modals/client-company-modal/data-tabset/data-tabset.component';
import { ClientTabComponent } from './modals/client-company-modal/data-tabset/client-tab/client-tab.component';
import { CompanyTabComponent } from './modals/client-company-modal/data-tabset/company-tab/company-tab.component';
import { CompanySelectModalComponent } from './modals/company-select-modal/company-select-modal.component';
import { ClientSelectModalComponent } from './modals/client-select-modal/client-select-modal.component';
import { ClientStatusPipe } from './pipes/client-status.pipe';
import { FormGroupInputValidationComponent } from './form-group-input-validation/form-group-input-validation.component';
import { ConfirmDialogModelComponent } from './confirm-dialog-model/confirm-dialog-model.component';
import { FormGroupDatepickerValidationComponent } from './form-group-datepicker-validation/form-group-datepicker-validation.component';
import { FormGroupNumberInputValidationComponent } from './form-group-number-input-validation/form-group-number-input-validation.component';
import { RequestEventHardwareSoftwareComponent } from './request-event-hardware-software/request-event-hardware-software.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectSearchComponent } from './select-search/select-search.component';
import { CompanyStatusPipe } from './pipes/company-status.pipe';
import { PrinterStatusPipe } from './pipes/printer-status.pipe';
import { RouterStatusPipe } from './pipes/router-status.pipe';
import { DeviceStatusPipe } from './pipes/device-status.pipe';
import { EventStatusPipe } from './pipes/event-status.pipe';
import { EndDatePipe } from './pipes/end-date.pipe';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DataFilterDatepickerComponent } from './data-filters/data-filter-datepicker/data-filter-datepicker.component';
import { EventOfferStatusPipe } from './pipes/event-offer-status.pipe';
import { LoadingDialogModalComponent } from './loading-dialog-modal/loading-dialog-modal.component';
import { ClientStatusBadgePipe } from './pipes/client-status-badge.pipe';
import { FormGroupSelectValidationComponent } from './form-group-select-validation/form-group-select-validation.component';
import { SelectLocationGroupComponent } from './select-location-group/select-location-group.component';
import { ReplaceLineBreaks } from './pipes/replace-line- breaks.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    DpDatePickerModule,
    NgbModule,
    NgSelectModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  declarations: [
    SelectComponent,
    PageTitleComponent,
    FormGroupInputComponent,
    NgFormGroupTextareaComponent,
    NgFormGroupSelectComponent,
    NgFormGroupInputComponent,
    FormGroupPwdGeneratorComponent,
    FormGroupPriceInputComponent,
    FormGroupDatepickerComponent,
    FormGroupNumberInputComponent,
    FormGroupTimepickerComponent,
    RequestEventModalComponent,
    FormGroupPriceInputComponent,
    DataTableComponent,
    DataTableColumnComponent,
    PaginationComponent,
    DataFiltersComponent,
    DataFilterGroupComponent,
    DataFilterInputComponent,
    DataFilterButtonComponent,
    PriceListPreviewModalComponent,
    DataFilterSelectComponent,
    ClientCompanyModalComponent,
    DataTabsetComponent,
    ClientTabComponent,
    CompanyTabComponent,
    CompanySelectModalComponent,
    ClientSelectModalComponent,
    ClientStatusPipe,
    FormGroupInputValidationComponent,
    FormGroupSelectComponent,
    ConfirmDialogModelComponent,
    FormGroupDatepickerValidationComponent,
    FormGroupNumberInputValidationComponent,
    RequestEventHardwareSoftwareComponent,
    SelectSearchComponent,
    CompanyStatusPipe,
    PrinterStatusPipe,
    RouterStatusPipe,
    DeviceStatusPipe,
    EventStatusPipe,
    EndDatePipe,
    DataFilterDatepickerComponent,
    EventOfferStatusPipe,
    LoadingDialogModalComponent,
    ClientStatusBadgePipe,
    FormGroupSelectValidationComponent,
    SelectLocationGroupComponent,
    ReplaceLineBreaks
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SelectComponent,
    PageTitleComponent,
    FormGroupInputComponent,
    NgFormGroupTextareaComponent,
    NgFormGroupSelectComponent,
    NgFormGroupInputComponent,
    FormGroupPwdGeneratorComponent,
    TranslateModule,
    FormGroupPriceInputComponent,
    FormGroupDatepickerComponent,
    FormGroupNumberInputComponent,
    FormGroupTimepickerComponent,
    FormGroupPriceInputComponent,
    DataTableComponent,
    PaginationComponent,
    DataFiltersComponent,
    DataFilterGroupComponent,
    DataFilterInputComponent,
    RequestEventModalComponent,
    PriceListPreviewModalComponent,
    DataFilterSelectComponent,
    ClientCompanyModalComponent,
    CompanySelectModalComponent,
    ClientSelectModalComponent,
    NgbModule,
    ClientStatusPipe,
    FormGroupInputValidationComponent,
    FormGroupSelectComponent,
    ConfirmDialogModelComponent,
    FormGroupDatepickerValidationComponent,
    FormGroupNumberInputValidationComponent,
    RequestEventHardwareSoftwareComponent,
    SelectSearchComponent,
    CompanyStatusPipe,
    PrinterStatusPipe,
    RouterStatusPipe,
    DeviceStatusPipe,
    EventStatusPipe,
    EndDatePipe,
    CalendarModule,
    DpDatePickerModule,
    DataFilterDatepickerComponent,
    EventOfferStatusPipe,
    LoadingDialogModalComponent,
    ClientStatusBadgePipe,
    FormGroupSelectValidationComponent,
    SelectLocationGroupComponent,
    ReplaceLineBreaks
  ],
  providers: [
    PaginationService
  ]
})
export class SharedModule {
}

import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable()
export class NavigationService {

  constructor(private router: Router) {
  }

  login(params?: NavigationExtras) {
    return new LoginNavigation(this.router).login(params);
  }

  home(params?: NavigationExtras) {
    console.log(params);
    // return new HomeNavigation(this.router).home(params);
    return new Navigate(this.router).goToOfferCalendarPage();
  }

  recovery() {
    return new CoreNavigation(this.router).recovery();
  }

  resetPassword() {
    return new CoreNavigation(this.router).reset();
  }

  deliveryPage(id: number) {
    return new Navigate(this.router).goToDeliveryPage(id);
  }
}

class Navigation {

  path: string[]           = [];
  params: NavigationExtras = {};
  router: Router;

  constructor(router: Router) {
    this.router = router;
  }
}
 class Navigate extends Navigation implements Navigable {

  static fromNavigation(navigation: Navigation) {
    const navigate: Navigate = new Navigate(navigation.router);
    navigate.path            = navigation.path;
    navigate.params          = navigation.params;

    return navigate;
  }

  constructor(router: Router) {
    super(router);
  }

  goToDeliveryPage(id: number) {
    this.router.navigate(['/events/delivery-paper/' + id]);
  }

  goToOfferCalendarPage() {
    this.router.navigate(['/offers']);
  }

  go() {
    this.router.navigate(this.path, this.params)
      .catch(error => console.log(error));
  }

  getUrl(): string {
    const domain = window.location.origin;
    const path = this.path.join('/');
    return `${domain}/${path}`;
  }
}

class CoreNavigation extends Navigation {

  constructor(router: Router) {
    super(router);
  }

  recovery(): Navigable {
    this.path = ['recovery'];
    return Navigate.fromNavigation(this);
  }

  reset() {
    this.path = ['reset-password'];
    return Navigate.fromNavigation(this);
  }
}

class LoginNavigation extends Navigation {

  constructor(router: Router) {
    super(router);
  }

  login(params?: NavigationExtras): Navigable {
    this.path   = ['signin'];
    this.params = params;
    return Navigate.fromNavigation(this);
  }
}

class HomeNavigation extends Navigation {

  constructor(router: Router) {
    super(router);
  }

  home(params?: NavigationExtras): Navigable {
    this.path   = ['home'];
    this.params = params;
    return Navigate.fromNavigation(this);
  }
}

interface Navigable {

  go();

  getUrl(): string;
}

import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthService} from '../auth.service';
import {NavigationService} from '../../shared/navigation.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {

  constructor(private toastr: ToastrService, private authService: AuthService, private navigationService: NavigationService) { }

  ngOnInit() {
  }
  onRecover(form: NgForm) {
    const email = form.value.email;
    this.authService.resetPasswordRequest(email).subscribe(data => {
      if (data) {
        this.toastr.success('Recovery code sent.');
        this.redirectToReset();
      }
    }, err => {
      console.log(err);
    });
  }

  redirectToReset() {
    this.navigationService.resetPassword().go();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateVillageName'
})
export class TranslateVillageNamePipe implements PipeTransform {

  transform(villageName: string, language: string): any {
    if (villageName.split('/').length === 1) {
      return villageName.split('/').pop();
    } else {
      if (language === 'de') {
        return villageName.split('/').pop();
      } else {
        return villageName.split('/').slice(0, -1).join('/');
      }
    }
  }

}

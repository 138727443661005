import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { PRINTERS_ROUTES } from './printers.routes';
import { PrintersComponent } from './printers.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PrintersListComponent } from './printers-list/printers-list.component';
import { UpdatePrinterComponent } from './update-printer/update-printer.component';
import { PrintersService } from './shared/printers.service';
import { UpdatePrinterTypesComponent } from './update-printer-types/update-printer-types.component';
import { PrinterTypeStatusPipe } from './shared/pipes/printer-type-status.pipe';

@NgModule({
    imports: [
        RouterModule.forChild(PRINTERS_ROUTES),
        SharedModule,
        NgSelectModule
    ],
    declarations: [
        PrintersComponent,
        PrintersListComponent,
        UpdatePrinterComponent,
        UpdatePrinterTypesComponent,
        PrinterTypeStatusPipe
    ],
    providers: [
        PrintersService
      ]
})
export class PrintersModule { }
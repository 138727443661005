import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { ValidationUtils } from 'src/app/clients/shared/validation.utils';
import { Province } from 'src/app/shared/models/Province';
import { Region } from 'src/app/shared/models/Region';
import { ProvincesService } from './../shared/provinces.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-new-province',
  templateUrl: './add-new-province.component.html',
  styleUrls: ['./add-new-province.component.css']
})
export class AddNewProvinceComponent implements OnInit {

  private provinceObj: Province;
  public provinceFormGroup: FormGroup;
  public validationErrorsArray = [];
  public resultProvince: Province;

  @Input()
  provincesList: Province[];

  @Input()
  regionsList: Region[];

  @Input()
  regionsOptions: Option[];


  constructor(private _provincesService: ProvincesService, private _formBuilder: FormBuilder, private _toastr: ToastrService, private _translate: TranslateService) {
    this.provinceFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      regionId: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  onProvinceFormsSubmit() {
    this.validationErrorsArray = [];
    if (this.provinceFormGroup.valid) {
      this.provinceObj = Province.fromDto(this.provinceFormGroup.value);
      this.provinceObj.region = this.regionsList.filter(region => region.id.toString() === this.provinceFormGroup.value.regionId)[0];
      this._provincesService.insertProvince(this.provinceObj).subscribe(result => {
        this.provincesList.push(result);
        this._toastr.success(this._translate.instant('Province added with success'));
      }, error => {
        console.log(error);
      });
    }
    this.validationErrorsArray = ValidationUtils.getFormValidationErrors(this.provinceFormGroup, 'province');
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { RouterStatusEnum } from '../enums/RouterStatusEnum';

@Pipe({
  name: 'routerStatus'
})
export class RouterStatusPipe implements PipeTransform {

  transform(status: any): any {
    let routerStatus = '';
    switch (status) {
      case RouterStatusEnum.Available:
        routerStatus = 'Available';
        break;
      case RouterStatusEnum.Rented:
        routerStatus = 'Rented';
        break;
      case RouterStatusEnum.Damaged:
        routerStatus = 'Damaged';
        break;
      default:
        routerStatus = 'Deleted';
        break;
    }
    return routerStatus;
  }

}
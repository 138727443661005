import { Routes } from '@angular/router';
import { RoutersComponent } from './routers.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { RoutersListComponent } from './routers-list/routers-list.component';
import { UpdateRouterComponent } from './update-router/update-router.component';
import { UpdateRouterTypeComponent } from './update-router-type/update-router-type.component';

export const ROUTERS_ROUTES: Routes = [
    {
        path: 'hardware/routers', component: RoutersComponent, canActivate: [AuthGuard], children: [
            { path: '', component: RoutersListComponent, canActivate: [AuthGuard] },
            { path: ':methodType', component: UpdateRouterComponent, canActivate: [AuthGuard] },
            { path: ':methodType/:routerId', component: UpdateRouterComponent, canActivate: [AuthGuard] }
        ]
    }, {
        path: 'hardware/router-type/:methodType', component: UpdateRouterTypeComponent, canActivate: [AuthGuard]
    }, {
        path: 'hardware/router-type/:methodType/:routerTypeId', component: UpdateRouterTypeComponent, canActivate: [AuthGuard]
    }
];

// import { PasswordChangeCallbackComponent } from './password-change-callback/password-change-callback.component';
import { Routes } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { HomeComponent } from './home/home.component';
import {AuthGuard} from './guards/auth.guard';
import {RecoverPasswordComponent} from './recover-password/recover-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

export const CORE_ROUTES: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'signin', component: SigninComponent },
  { path: 'recovery', component: RecoverPasswordComponent},
  { path: 'reset-password', component: ResetPasswordComponent}
  ];

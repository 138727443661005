import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { PriceList } from 'src/app/shared/models/PriceList';
import { Product } from 'src/app/shared/models/Product';
import { PriceListService } from '../../shared/price-list.service';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products-table',
  templateUrl: './products-table.component.html',
  styleUrls: ['./products-table.component.css']
})
export class ProductsTableComponent implements OnInit {

  public choosenEditProduct: Product = new Product();
  public editProductMood = false;
  public editProductOptionMood = false;
  public toggle = Array();
  public productIdsArray = new Array();
  private _choosenProductId: number;


  @ViewChild('confirmDeleteProductDialog')
  confirmDeleteProductDialog;

  @ViewChild('confirmDuplicateProductDialog')
  confirmDuplicateProductDialog;

  @Input()
  priceList: PriceList;

  @Input()
  productCategoryOptions: Option[];

  @Output()
  isProductEditMood: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _priceListService: PriceListService, private _toastr: ToastrService, private _translate: TranslateService) { }

  ngOnInit() {
  }

  getPriceListProductCategories(productCategories: ProductCategory[]) {
    console.log(productCategories);
    this.priceList.productCategories = productCategories;
    this.productIdsArray = new Array();
  }

  onCheckboxChange(event: any) {
    const productid = event.target.defaultValue;
    if (event.target.checked) {
      this.productIdsArray.push(productid);
    } else {
      const index = this.productIdsArray.findIndex(product => product === productid);
      this.productIdsArray.splice(index, 1);
    }
    console.log(this.productIdsArray);
  }

  changeProductPosition(productId: number, newPosition: number, productCategoryId: number) {
    this._priceListService.changeProductPosition(productId, newPosition, productCategoryId).subscribe(result => {
      this.priceList.productCategories.filter(category => category.id === result.id)[0].products = result.products;
    }, error => {
      console.log(error);
    });
  }

  enableEditProduct(product: Product, productCategory: ProductCategory) {
    this.choosenEditProduct = {...product};
    this.choosenEditProduct.productCategory = {...productCategory};
    this.editProductMood = true;
    this.isProductEditMood.emit(this.editProductMood);
    this.toggle = Array();
  }

  changeProductStatus(product: Product, newStatus: number, productCategory: ProductCategory) {
    product = Product.fromDto(product);
    product.status = newStatus;
    product.productCategory = productCategory;
    this._priceListService.updateProduct(product).subscribe(result => {
      this.priceList.productCategories = result;
      this._toastr.success(this._translate.instant('price-list.manage.' +
      (newStatus === 1 ? 'productEnabledSuccessMsg' : 'productDisabledSuccessMsg')));
    }, error => {
      console.log(error);
    });
  }

  cancelProductEditMode() {
    this.choosenEditProduct = new Product();
    this.editProductMood = false;
    this.isProductEditMood.emit(this.editProductMood);
  }

  showConfirmDeleteDialog(productId: number) {
    this._choosenProductId = productId;
    this.confirmDeleteProductDialog.openDialog();
  }

  showConfirmDuplicateDialog(productId: number) {
    this._choosenProductId = productId;
    this.confirmDuplicateProductDialog.openDialog();
  }

  getDeleteProductDecision(decision: boolean) {
    if (decision) {
      this._priceListService.deleteProduct(this._choosenProductId).subscribe(result => {
        this.priceList.productCategories.filter(category => category.id === result.id)[0].products = result.products;
        this._toastr.success(this._translate.instant('price-list.manage.productSuccessDeleteMsg'));
      }, error => {
        console.log(error);
      });
    }
    this._choosenProductId = undefined;
  }

  getDuplicateProductDecision(decision: boolean) {
    if (decision) {
      this._priceListService.duplicateProduct(this._choosenProductId).subscribe(result => {
        console.log(result);
        this.priceList.productCategories.filter(category => category.id === result.id)[0].products = result.products;
        this._toastr.success(this._translate.instant('price-list.manage.productSuccessDuplicateMsg'));
      }, error => {
        console.log(error);
      });
    }
    this._choosenProductId = undefined;
  }

  getEditProductOptionMood(value: boolean) {
    this.editProductMood = value;
    this.editProductOptionMood = value;
    this.isProductEditMood.emit(this.editProductMood);
  }

}

import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { PriceListsComponent } from './price-lists.component';
import { AllPriceListsComponent } from './all-price-lists/all-price-lists.component';
import { ManagePriceListComponent } from './manage-price-list/manage-price-list.component';
import { PriceListSummeryPaperComponent } from './print-layouts/price-list-summery-paper/price-list-summery-paper.component';

export const PRICE_LISTS_ROUTES: Routes = [
  {
    path: 'price-lists', component: PriceListsComponent, canActivate: [AuthGuard], children: [
      { path: 'all', component: AllPriceListsComponent },
      { path: 'edit/:priceListId', component: ManagePriceListComponent },
      { path: 'papers/summery/:priceListId', component: PriceListSummeryPaperComponent },
      { path: '**', redirectTo: 'price-lists' }
    ]
  }
];

import { Injectable } from '@angular/core';
import { HttpUtils } from 'src/app/shared/http-utils';
import { HttpClient } from '@angular/common/http';
import { URL_BASE } from 'src/app/core/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Province } from 'src/app/shared/models/Province';

@Injectable({
  providedIn: 'root'
})
export class ProvincesService {

  constructor(private _http: HttpClient) { }

  public deleteProvince(provinceId: number): Observable<Province> {
    const headers = HttpUtils.createHeaders();
    return this._http.delete(URL_BASE + '/admin/province/delete', {
      headers, params: { id: provinceId.toString(), force: '1' }
    }).pipe(
      map((res: any) => Province.fromDto(res)));
  }

  public updateProvince(province: Province): Observable<Province[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/province/update', { province: province, returnAll: true }, { headers }).pipe(
      map((result: any[]) => result.map(res => Province.fromDto(res))
      ));
  }

  public insertProvince(province: Province): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/province/insert', { province: province, returnAll: false }, { headers }).pipe(
      map((res: Province) => {
        return res;
      }));
  }
}

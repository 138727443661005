import { Injectable } from '@angular/core';
import { Region } from 'src/app/shared/models/Region';
import { HttpUtils } from 'src/app/shared/http-utils';
import { HttpClient } from '@angular/common/http';
import { URL_BASE } from 'src/app/core/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {

  constructor(private _http: HttpClient) { }

  public deleteRegion(regionId: number): Observable<Region> {
    const headers = HttpUtils.createHeaders();
    return this._http.delete(URL_BASE + '/admin/region/delete', {
      headers, params: { id: regionId.toString(), force: '1' }
    }).pipe(
      map((res: any) => Region.fromDto(res)));
  }

  public insertRegion(region: Region): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/region/insert', { region: region, returnAll: false }, { headers }).pipe(
      map((res: Region) => {
        return res;
      }));
  }


  public updateRegion(region: Region): Observable<Region[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/region/update', { region: region, returnAll: true }, { headers }).pipe(
      map((result: any[]) => result.map(res => Region.fromDto(res))
      ));
  }
}

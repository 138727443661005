import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../../shared/events.service';
import { ToastrService } from 'ngx-toastr';
import { Event } from 'src/app/shared/models/Event';
import { RequestedCable } from 'src/app/shared/models/RequestedCable';
import { TranslateService } from '@ngx-translate/core';
import { RequestedDevice } from 'src/app/shared/models/RequestedDevice';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { Printer } from 'src/app/shared/models/Printer';
import { DeviceUser } from 'src/app/shared/models/DeviceUser';
import { forkJoin } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';
import { PickupPoint } from 'src/app/shared/models/PickupPoint';
import { DeliveryAddress } from 'src/app/shared/models/DeliveryAddress';
import { AdditionalCost } from 'src/app/shared/models/AdditionalCost';


class CableSizes {
  public length: number;
  public amount: number;
}

class ProductRow {
  public productName: string;
  public productAmount: number;
}

class DeviceUserPrinterCategory {
  public deviceUser: DeviceUser;
  public printerCategories: PrinterCategories[] = [];
}

class PrinterCategories {
  public printer: Printer;
  public productCategory: ProductCategory[] = [];
}

@Component({
  selector: 'app-delivery-paper',
  templateUrl: './delivery-paper.component.html',
  styleUrls: ['./delivery-paper.component.css']
})
export class DeliveryPaperComponent implements OnInit {

  public event: Event = new Event();
  public cablesAmount: CableSizes[] = [];
  public customProductRows: ProductRow[] = [];
  public currentProduct: ProductRow = new ProductRow();
  public deviceUserPrinterCategory: DeviceUserPrinterCategory[] = [];
  public notesText = '';
  public receiverText = '';
  public pickupPoint: PickupPoint;
  public deliveryAddress: DeliveryAddress;
  public printCategoriesDeliveryPaperArray: any[];

  public mainDeviceSoftwareCount = 0;
  public cashRegisterSoftwareCount = 0;
  public waiterSoftwareCount = 0;
  public showWaiterSoftwareCount = false;

  public additionalCostsArray: AdditionalCost[];

  public translateMap: Map<string, string> = new Map();

  constructor(private _translate: TranslateService, private _route: ActivatedRoute,
    private _eventsService: EventsService, private _toastr: ToastrService, private _sharedService: SharedService) { }

  ngOnInit() {
    this._route.params.subscribe(params => {
      forkJoin([
        this._eventsService.getEventById(params['eventId']),
        this._eventsService.getPrintersCategoriesByEventId(params['eventId']),
        this._sharedService.getPickupPoints(),
        this._eventsService.getPrintersCategoriesByEventIdV2(params['eventId']),
        this._eventsService.getAdditionalCosts(),
        this._eventsService.getAdditionalCostsAssigned(params['eventId'])
      ]).subscribe(result => {
        console.log(result);
        result[1].forEach(printCat => {
          let existingDeviceUser = this.deviceUserPrinterCategory.filter(devUsr => devUsr.deviceUser.id === printCat.deviceUser.id)[0];
          if (!existingDeviceUser) {
            existingDeviceUser = new DeviceUserPrinterCategory();
            existingDeviceUser.deviceUser = printCat.deviceUser;
            this.deviceUserPrinterCategory.push(existingDeviceUser);
          }
          let existingDeviceUserPrinterCat = existingDeviceUser.printerCategories.filter(printer =>
            printer.printer.id === printCat.printer.id)[0];
          if (!existingDeviceUserPrinterCat) {
            existingDeviceUserPrinterCat = new PrinterCategories();
            existingDeviceUserPrinterCat.printer = printCat.printer;
            existingDeviceUser.printerCategories.push(existingDeviceUserPrinterCat);
          }
          existingDeviceUserPrinterCat.productCategory.push(printCat.productCategory);
        });
        this.event = result[0];
        console.log('BEFORE event.requestedDevices:', this.event.requestedDevices);
        const requestedDevices: RequestedDevice[] = [];
        this.event.requestedDevices.forEach(elem => {
          const existingDeviceType = requestedDevices.filter(deviceType => deviceType.deviceTypes.id === elem.deviceTypes.id)[0];
          if (!existingDeviceType) {
            requestedDevices.push(elem);
          } else {
            existingDeviceType.quantity += elem.quantity;
          }
        });
        this.event.requestedSoftwareTypes.forEach(elem => {
          if (elem.softwareType.id === 1) { // waiter phones
            this.waiterSoftwareCount = elem.quantity;
            this.showWaiterSoftwareCount = true;
            this.event.requestedDevices.forEach(device => {
              if (device.deviceTypes.id === 1 && device.quantity > 0) {
                if (device.quantity >= this.waiterSoftwareCount) {
                  this.showWaiterSoftwareCount = false;
                }
                this.waiterSoftwareCount = this.waiterSoftwareCount - device.quantity;
              }
            });
          }
          if (elem.softwareType.id === 2) { // cash register
            this.cashRegisterSoftwareCount = elem.quantity;
          }
          if (elem.softwareType.id === 3) { // main device
            this.mainDeviceSoftwareCount = elem.quantity;
          }
        });

        this.event.requestedDevices = requestedDevices;
        console.log('AFTER event.requestedDevices:', requestedDevices);
        this.calculateCableAmount(this.event.requestedCable);
        if (!this.event.delivery) {
          this._eventsService.getPickupPointByEventId(this.event.id.toString()).subscribe(pickupPoint => {
            this.pickupPoint = pickupPoint;
            console.log(this.pickupPoint);
          });
        } else {
          this._eventsService.getDeliveryAddressByEventId(this.event.id.toString()).subscribe(deliveryAddress => {
            this.deliveryAddress = deliveryAddress;
            console.log(this.deliveryAddress);
          });
        }
        this.printCategoriesDeliveryPaperArray = result[3];

        const additionalCostsArray = result[4];
        const additionalCostsAssignedArray = result[5];
        additionalCostsAssignedArray.forEach(rec => {
          additionalCostsArray.filter(item => item.id === rec.id)[0].quantityRequested = rec.quantity;
        });
        this.additionalCostsArray = additionalCostsArray.filter(item => item.quantityRequested > 0 && item.name !== 'cancellation');
        console.log('this.additionalCostsArray', this.additionalCostsArray);

        if (this.event.language === 'it') {
          this.translateMap.set('supplierTitle', 'Mittente');
          this.translateMap.set('supplierName', 'RR Solutions s.a.s di Rudolf Rienzner & Co.');
          this.translateMap.set('supplierAdress', 'Via Brennero 9/A');
          this.translateMap.set('supplierCity', '39100 Bolzano');
          this.translateMap.set('supplierMobile', 'Mobile 3927328761');
          this.translateMap.set('deliveryPaperTitle', 'Documento di trasporto');
          this.translateMap.set('deliveryPaperNr', 'NUM.');
          this.translateMap.set('deliveryPaperDate', 'DEL');
          this.translateMap.set('customerTitle', 'Destinatario');
          this.translateMap.set('eventTitle', 'Causale');
          this.translateMap.set('dateTitle', 'Data');
          this.translateMap.set('columnDescription', 'Descrizione prodotto');
          this.translateMap.set('columnQuantity', 'Quantità');
          this.translateMap.set('phone', 'Cellulare android: hardware e licenza');
          this.translateMap.set('phoneCharger', 'Caricabatterie cellulare');
          this.translateMap.set('tablet', 'Tablet android');
          this.translateMap.set('tabletCharger', 'Caricabatterie tablet');
          this.translateMap.set('routers', 'Router');
          this.translateMap.set('routerCharger', 'Alimentatore router');
          this.translateMap.set('printers', 'Stampante Epson');
          this.translateMap.set('printerCharger', 'Alimentatore stampante');
          this.translateMap.set('printerSoftware', 'Licenza software per l\'utilizzo della stampante');
          this.translateMap.set('waiterSoftware', 'Licenza software camerieri utilizzabile con i propri smartphone Android');
          this.translateMap.set('mainDeviceSoftware', 'Licenza software per dispositivo principale (per blocco prodotti, messaggi, ecc)');
          this.translateMap.set('cashRegisterSoftware', 'Licenza software per dispositivo di cassa');
          this.translateMap.set('cable', 'Cavo dati');
          this.translateMap.set('paperRolls', 'Rotolo di carta');
          this.translateMap.set('notes', 'Note');
          this.translateMap.set('transportTitle', 'Trasporto:');
          this.translateMap.set('pickupOurOffice', 'Ritiro del materiale nel nostro ufficio');
          this.translateMap.set('sendByPost', 'Spedizione del materiale con corriere');
          this.translateMap.set('transportDate', 'Data di inizio trasporto');
          this.translateMap.set('signatureCustomer', 'Firma destinatario');
          this.translateMap.set('signatureSupplier', 'Firma mittente');
          this.translateMap.set('warningMessage', '<strong>Dopo la Vostra festa ci sarà un\'altra festa!</strong> L\'attrezzatura noleggiata deve essere restituita pulita e non danneggiata alla data concordata. I danni devono essere segnalati immediatamente. La tariffa oraria per le pulizie è di 50 €/ora + IVA.');
          this.translateMap.set('videoQrCodeMessage', 'Prego leggere attentamente la guida all\'installazione.  Scannerizzare il codice Qr e aprire la guida rapida sotto i video di spiegazione sul sito web.');
          this.translateMap.set('videoQrCodeLink', 'qrcode_famulus_video_it.png');
        } else {
          this.translateMap.set('supplierTitle', 'Absender');
          this.translateMap.set('supplierName', 'RR Solutions KG des Rudolf Rienzner & Co.');
          this.translateMap.set('supplierAdress', 'Brennerstrasse 9/A');
          this.translateMap.set('supplierCity', '39100 Bozen');
          this.translateMap.set('supplierMobile', 'Mobile 3927328761');
          this.translateMap.set('deliveryPaperTitle', 'Lieferschein');
          this.translateMap.set('deliveryPaperNr', 'NR.');
          this.translateMap.set('deliveryPaperDate', 'VOM');
          this.translateMap.set('customerTitle', 'Empfänger');
          this.translateMap.set('eventTitle', 'Grund');
          this.translateMap.set('dateTitle', 'Datum');
          this.translateMap.set('columnDescription', 'Beschreibung Produkte');
          this.translateMap.set('columnQuantity', 'Menge');
          this.translateMap.set('phone', 'Android Smartphone: Hardware und Lizenz');
          this.translateMap.set('phoneCharger', 'Aufladegerät für Smartphone');
          this.translateMap.set('tablet', 'Android Tablet');
          this.translateMap.set('tabletCharger', 'Aufladegerät für Tablet');
          this.translateMap.set('routers', 'Router');
          this.translateMap.set('routerCharger', 'Router Netzteil');
          this.translateMap.set('printers', 'Epson Bon Drucker');
          this.translateMap.set('printerCharger', 'Drucker Netzteil');
          this.translateMap.set('printerSoftware', 'Lizenz Drucker');
          this.translateMap.set('waiterSoftware', 'Kellner-Software bei Verwendung eigener Android Smartphones');
          this.translateMap.set('mainDeviceSoftware', 'Lizenz für Hauptgerät zum Sperren der Produkte, u.v.m.');
          this.translateMap.set('cashRegisterSoftware', 'Lizenz für Selbstabholer-Kasse');
          this.translateMap.set('cable', 'Datenkabel');
          this.translateMap.set('paperRolls', 'Papierrollen');
          this.translateMap.set('notes', 'Notizen');
          this.translateMap.set('transportTitle', 'Transport durch');
          this.translateMap.set('pickupOurOffice', 'Abholung der Geräte in einem unserer Büros');
          this.translateMap.set('sendByPost', 'Zustellung der Geräte per Kurier');
          this.translateMap.set('transportDate', 'Datum Transportbeginn');
          this.translateMap.set('signatureCustomer', 'Unterschrift Empfänger');
          this.translateMap.set('signatureSupplier', 'Unterschrift Absender');
          this.translateMap.set('warningMessage', '<strong>Nach dem Fest ist vor dem Fest!</strong> Die geliehenen Geräte müssen sauber und unversehrt am vereinbarten Termin zurückgegeben werden. Schäden müssen umgehend gemeldet werden. Der Stundenpreis für Reinigung beträgt 50 €/Stunde.');
          this.translateMap.set('videoQrCodeMessage', 'Bitte lesen Sie die Kurzanleitung zur Installation genau durch. Scannen Sie einfach den Qr-Code und öffnen Sie auf der Website die Kurzanleitung unterhalb der Erklärvideos.');
          this.translateMap.set('videoQrCodeLink', 'qrcode_famulus_video_de.png');
        }

      }, error => {
        console.error('Event does not exist ---> Redirect', error);
      });
    });
  }

  calculateCableAmount(requestedCable: RequestedCable[]) {
    requestedCable.forEach(cable => {
      this.cablesAmount.push({ length: cable.cables.cableType.length, amount: cable.quantity });
    });
  }

  addNewCustomProduct() {
    if ((this.currentProduct.productName && this.currentProduct.productAmount) === undefined) {
      this._toastr.warning(this._translate.instant('Informations required'));
    } else {
      this.customProductRows.push(this.currentProduct);
      this.currentProduct = new ProductRow();
    }
  }

  deleteProduct(index) {
    this.customProductRows.splice(index, 1);
  }

  getadditionalCostLabel(name) {
    let label: string;
    switch (name) {
      case 'badWeather':
        label = (this.event.language === 'it') ? 'Assicurazione di disdetta' : 'Ausfallversicherung';
        break;
      case 'cancellation':
        label = (this.event.language === 'it') ? 'Cancellazione evento' : 'cancellation';
        break;
      case 'cashDrawer':
        label = (this.event.language === 'it') ? 'Cassa per contanti' : 'Kassenlade';
        break;
      case 'beltBag':
        label = (this.event.language === 'it') ? 'Marsupio per portafoglio' : 'Umhängetasche';
        break;
      case 'wallet':
        label = (this.event.language === 'it') ? 'Portafoglio' : 'Kellnerbrieftasche';
        break;
      case 'pickupInstalled':
        label = (this.event.language === 'it') ? 'Consegna dei dispositivi giá preinstallati' : 'Erhalt der Geräte bereits eingeloggt';
        break;
      case 'uploadingCheckingPricelist':
        label = (this.event.language === 'it') ? 'Controllo e caricamento del listino prezzi' : 'Kontrolle und Hochladen der Preisliste';
        break;
      case 'training':
        label = (this.event.language === 'it') ? 'Formazione al ritiro dei dispositivi' : 'Einschulung bei Abholung der Geräte';
        break;
    }
    return label;
  }

  translateLabel(code) {
    if (this.translateMap) {
      return this.translateMap.get(code);
    } else {
      return '';
    }
  }

}

import { Event } from './Event';
import { Ups } from './Ups';

export class RequestedUps {
    public id: number;
    public quantity: number;
    public parentRequestId: number;
    public requestedAt: string;
    public event: Event;
    public ups: Ups;

    static fromDto(r): RequestedUps {
        const requestedUps = new RequestedUps();

        if (!r) {
            return null;
        }

        requestedUps.id = r.id;
        requestedUps.quantity = r.quantity;
        requestedUps.parentRequestId = r.parentRequestId;
        requestedUps.requestedAt = r.requestedAt;
        requestedUps.event = Event.fromDto(r.event);
        requestedUps.ups = Ups.fromDto(r.ups);

        return requestedUps;
    }
}

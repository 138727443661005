import { Component, OnInit, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog-model',
  templateUrl: './confirm-dialog-model.component.html',
  styleUrls: ['./confirm-dialog-model.component.css']
})
export class ConfirmDialogModelComponent implements OnInit {

  @ViewChild('confirmDialogModel')
  private dialogContent: TemplateRef<any>;

  @Input()
  modalTitle = '';

  @Input()
  modalContent = '';

  @Input()
  denyText = 'Yes';

  @Input()
  acceptText = 'No';

  @Output()
  getResult: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private modalService: NgbModal, modalConfig: NgbModalConfig) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
  }

  ngOnInit() {
  }

  openDialog(content) {
    this.modalService.open(this.dialogContent, { centered: true });
  }

  decisionClickAction(decision: boolean) {
    this.getResult.emit(decision);
    this.modalService.dismissAll();
  }

}

import { Event } from './Event';
import { Company } from './Company';
import { PrinterType } from './PrinterType';
import { PrintersCategories } from './PrintersCategories';

export class Printer {
    public id: number;
    public name: string;
    public identifier: string;
    public status: number;
    public notes: string;
    public events: Event[] = [];
    public companies: Company[] = [];
    public printerType: PrinterType;
    public printersCategories: PrintersCategories[];
    public printerAssignedCount: string;
    public assignedEvent: string;

    static fromDto(p): Printer {
        const printer = new Printer();

        if (!p) {
            return null;
        }

        printer.id = p.id;
        printer.name = p.name;
        printer.identifier = p.identifier;
        printer.status = p.status;
        printer.notes = p.notes;
        printer.printerAssignedCount = p.printerAssignedCount;
        printer.assignedEvent = p.assignedEvent;

        if (p.events) {
            printer.events = p.events.map(event => Event.fromDto(event));
        }
        if (p.companies) {
            printer.companies = p.companies.map(company => Company.fromDto(company));
        }
        printer.printerType = PrinterType.fromDto(p.printerType);
        if (p.printersCategories) {
            printer.printersCategories = p.printersCategories.map(printerCategory => PrintersCategories.fromDto(printerCategory));
        }

        return printer;
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ClientStatusEnum } from '../enums/ClientStatusEnum';

@Pipe({
  name: 'clientStatus'
})
export class ClientStatusPipe implements PipeTransform {

  transform(status: any): string {
    let humanStatus = '';
    switch (status) {
      case ClientStatusEnum.Enabled:
        humanStatus = 'Enabled';
        break;
      case ClientStatusEnum.Disabled:
        humanStatus = 'Disabled';
        break;
      case ClientStatusEnum.RecoveryMode:
        humanStatus = 'Recovery Mode';
        break;
      case ClientStatusEnum.Registration:
        humanStatus = 'Registration';
        break;
      case ClientStatusEnum.Deleted:
        humanStatus = 'Deleted';
        break;
      default:
        humanStatus = 'No Status';
        break;
    }
    return humanStatus;
  }

}

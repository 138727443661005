import { Component, OnInit } from '@angular/core';
import { EventsService } from '../shared/events.service';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView
} from 'angular-calendar';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';

@Component({
  selector: 'app-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.css']
})
export class EventsCalendarComponent implements OnInit {
  public viewDate: Date = new Date();
  public view: CalendarView = CalendarView.Month;
  public calendarEvents: CalendarEvent[] = [];

  constructor(private _eventsService: EventsService) { }

  ngOnInit() {
    this._eventsService.getEvents().subscribe(result => {
      console.log(result);
      result.forEach(event => {
        console.log({
          title: event.eventName,
          start: new Date(event.startDate),
          end: new Date(event.endDate)
        });
        this.calendarEvents = [... this.calendarEvents, {
          title: event.eventName,
          start: new Date(event.startDate),
          end: new Date(event.endDate)
        }
        ];
      });
    });
  }

}

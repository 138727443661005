
export class SearchProduct {
    categoryId: number;
    categoryName: string;
    categoryStatus: number;
    productId: string;
    productName: string;
    productExtraInfo: string;
    productPrice: number;
    productStatus: number;
    optionsCount: number;

    static fromDto(c): SearchProduct {
        const searchProduct = new SearchProduct();

        if (!c) {
            return null;
        }

        searchProduct.categoryId = c.categoryId;
        searchProduct.categoryName = c.categoryName;
        searchProduct.categoryStatus = c.categoryStatus;
        searchProduct.productId = c.productId;
        searchProduct.productName = c.productName;
        searchProduct.productExtraInfo = c.productExtraInfo;
        searchProduct.productPrice = c.productPrice;
        searchProduct.productStatus = c.productStatus;
        searchProduct.optionsCount = c.optionsCount;


        return c;

    }
}

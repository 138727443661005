import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PriceList } from 'src/app/shared/models/PriceList';

@Component({
  selector: 'app-multiple-options-table',
  templateUrl: './multiple-options-table.component.html',
  styleUrls: ['./multiple-options-table.component.css']
})
export class MultipleOptionsTableComponent implements OnInit {

  public productIdsArray = new Array();
  public disableSaveButton = true;

  @Input()
  priceList: PriceList;

  @Output()
  afterOptionsAdded: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  onCheckboxChange(event: any) {
    const productid = event.target.defaultValue;
    if (event.target.checked) {
      this.productIdsArray.push(productid);
    } else {
      const index = this.productIdsArray.findIndex(product => product === productid);
      this.productIdsArray.splice(index, 1);
    }
    this.disableSaveButton = (this.productIdsArray.length > 0 ? false : true);
  }

  onOptionsAdded() {
    this.afterOptionsAdded.emit();
  }


}

import { RouterType } from './RouterType';

export class RouterManufacturer {
    public id: number;
    public name: string;
    public status: number;
    public routerTypes: RouterType;

    static fromDto(r): RouterManufacturer {
        const routerManufacturer = new RouterManufacturer();

        if (!r) {
            return null;
        }

        routerManufacturer.id = r.id;
        routerManufacturer.name = r.name;
        routerManufacturer.status = r.status;
        routerManufacturer.routerTypes = r.routerTypes;

        return r;
    }
}

import { Client } from './Client';
import { Country } from './Country';
import { Village } from './Village';
import { Printer } from './Printer';
import { RequestedPrinterType } from './RequestedPrinterType';

export class Company {
    public id: number;
    public name: string;
    public firstEmail: string;
    public secondEmail: string;
    public firstPhone: string;
    public secondPhone: string;
    public fiscalCode: string;
    public vat: string;
    public pecEmail: string;
    public isdNumber: string;
    public address: string;
    public postalCode: string;
    public city: string;
    public district: string;
    public notes: string;
    public createdAt: string;
    public status: number;
    public clientUsers: Client[] = [];
    public requestedPrinterTypes: RequestedPrinterType[] = [];
    public country: Country;
    public village: Village;
    public printers: Printer[] = [];

    static fromDto(c): Company {
        const company = new Company();

        if (!c) {
            return null;
        }

        company.id = c.id;
        company.name = c.name;
        company.firstEmail = c.firstEmail;
        company.secondEmail = c.secondEmail;
        company.firstPhone = c.firstPhone;
        company.secondPhone = c.secondPhone;
        company.fiscalCode = c.fiscalCode;
        company.vat = c.vat;
        company.pecEmail = c.pecEmail;
        company.isdNumber = c.isdNumber;
        company.address = c.address;
        company.postalCode = c.postalCode;
        company.city = c.city;
        company.village = Village.fromDto(c.village);
        company.district = c.district;
        company.notes = c.notes;
        company.createdAt = c.createdAt;
        company.status = c.status;
        if (c.printers) {
            company.printers = c.printers.map(printer => Printer.fromDto(printer));
        }
        if (c.clientUsers) {
            company.clientUsers = c.clientUsers.map(client => Client.fromDto(client));
        }
        if (c.requestedPrinterTypes) {
            company.requestedPrinterTypes = c.requestedPrinterTypes.map(printerTypes => RequestedPrinterType.fromDto(printerTypes));
        }
        company.country = Country.fromDto(c.country);

        return company;
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintersService } from '../shared/printers.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PrinterType } from 'src/app/shared/models/PrinterType';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { ToastrService } from 'ngx-toastr';
import { PrinterManufacturer } from 'src/app/shared/models/PrinterManufacturers';
import { ValidationUtils } from 'src/app/clients/shared/validation.utils';
import { PrinterTypeStatusEnum } from 'src/app/shared/enums/PrinterTypeStatusEnum';

@Component({
  selector: 'app-update-printer-types',
  templateUrl: './update-printer-types.component.html',
  styleUrls: ['./update-printer-types.component.css']
})
export class UpdatePrinterTypesComponent implements OnInit {
  private _method: string;
  private _printerTypeId: string;
  public printerTypesFormGroup: FormGroup;
  public printerManufacturer: PrinterManufacturer[] = [];
  public printerManufacturers: PrinterManufacturer[] = [];
  public printerType: PrinterType;
  public hiddenId = false;
  public validationErrorsArray = [];

  public printerTypeStatusArray: Option[] = [
    { value: 0, label: PrinterTypeStatusEnum.ZERO },
    { value: 1, label: PrinterTypeStatusEnum.ONE },
  ];

  public trueFalseArray: Option[] = [
    { value: false, label: 'False' },
    { value: true, label: 'True' },
  ];

  constructor(private _toastr: ToastrService, private _printerService: PrintersService,
    private _route: ActivatedRoute, private _formBuilder: FormBuilder) {

    this.printerTypesFormGroup = this._formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      lan: ['', Validators.required],
      bluetooth: ['', Validators.required],
      wifi: ['', Validators.required],
      usb: ['', Validators.required],
      status: [1],
      printerManufacturers: [, Validators.required]
    });
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this._method = params['methodType'];
      this._printerTypeId = params['printerTypeId'];
      this._printerService.getPrinterManufacturers().subscribe(result => {
        this.printerManufacturers = result;
      });
      if (this._method === 'update') {
        this._printerService.getPrinterTypeInformationsById(this._printerTypeId).subscribe(result => {
          this.printerTypesFormGroup.patchValue(result);
          this.printerTypesFormGroup.get('printerManufacturers').setValue(result.printerManufacturers.id);
        });
      }
      if (this._method === 'insert') {
        this.hiddenId = true;
        this.printerTypesFormGroup.reset({ status: 1 });
      }
    });
  }


  onPrinterTypeFormSubmit() {
    if (this.printerTypesFormGroup.valid) {
      this.printerTypesFormGroup.value.printerManufacturers = this.printerManufacturers.filter(printerManufacturer =>
        printerManufacturer.id.toString() === this.printerTypesFormGroup.value.printerManufacturers.toString())[0];
      this.printerType = PrinterType.fromDto(this.printerTypesFormGroup.value);
      this._printerService.updatePrinterTypeInformations(this.printerType).subscribe(result => {
        this.printerTypesFormGroup.patchValue(result);
        this._toastr.success('Printer type updated with success');
      });
      if (this._method === 'insert') {
        this._printerService.insertPrinterTypeInformations(this.printerType).subscribe(result => {
          this.printerTypesFormGroup.patchValue(result);
          this.printerTypesFormGroup.reset({ status: 1 });
        });
        this._toastr.success('Printer type inserted with success');
      }
    }
    this.validationErrorsArray = ValidationUtils.getFormValidationErrors(this.printerTypesFormGroup, 'printerType');
  }
}



import { Routes } from '@angular/router';
import { SettingsComponent } from './settings.component';
import { LocationsComponent } from './locations/locations.component';
import { AuthGuard } from '../core/guards/auth.guard';
export const SETTINGS_ROUTES: Routes = [
    {
        path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], children: [
            { path: 'locations', component: LocationsComponent, canActivate: [AuthGuard] }
        ]
    }
];

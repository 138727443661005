import { Component, OnInit } from '@angular/core';
import { CompanyStatusEnum } from 'src/app/shared/enums/CompanyStatusEnum';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from '../shared/clients.service';
import { ToastrService } from 'ngx-toastr';
import { ClientStatusEnum } from 'src/app/shared/enums/ClientStatusEnum';
import { Company } from 'src/app/shared/models/Company';
import { Client } from 'src/app/shared/models/Client';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { LanguageEnum } from 'src/app/shared/enums/LanguageEnum';
import { Village } from 'src/app/shared/models/Village';
import { SharedService } from 'src/app/shared/shared.service';
import { Survey } from 'src/app/shared/models/Survey';


enum ClientCompanyActionEnum {
  ZERO = 'No Company',
  ONE = 'New Company',
  TWO = 'Search Company'
}

@Component({
  selector: 'app-update-client',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.css']
})
export class UpdateClientComponent implements OnInit {

  public companyFormGroup: FormGroup;
  public clientFormGroup: FormGroup;
  public company: Company;

  public villageOptions: Option[] = [];
  public companyStatusArray: Option[] = [
    { label: 'Deleted', value: CompanyStatusEnum.Deleted.toString() },
    { label: 'Enabled', value: CompanyStatusEnum.Enabled.toString() },
    { label: 'Disabled', value: CompanyStatusEnum.Disabled.toString() }
  ];
  public clientStatusArray: Option[] = [
    { value: ClientStatusEnum.Deleted, label: 'Deleted' },
    { value: ClientStatusEnum.Enabled, label: 'Enabled' },
    { value: ClientStatusEnum.Disabled, label: 'Disabled' },
    { value: ClientStatusEnum.RecoveryMode, label: 'Recovery Mode' },
    { value: ClientStatusEnum.Registration, label: 'Registration' }
  ];

  public clientLanguageArray: Option[] = [
    { value: 'de', label: LanguageEnum.DE },
    { value: 'it', label: LanguageEnum.IT },
    { value: 'en', label: LanguageEnum.EN },
  ];

  public clientCompanyActionArray: Option[] = [
    { value: 0, label: ClientCompanyActionEnum.ZERO },
    { value: 1, label: ClientCompanyActionEnum.ONE },
    { value: 2, label: ClientCompanyActionEnum.TWO }
  ];

  public clientTitleArray: Option[] = [
    { value: 'Herr', label: 'Herr' },
    { value: 'Frau', label: 'Frau' }
  ];

  public companyNameRequireError = false;
  public firstEmailRequireError = false;
  public countryRequireError = false;

  public showCompanyForm = false;
  public showCompanySearch = false;

  public clientErrorArray = [];
  public companyErrorArray = [];

  public surveys: Survey[] = [];
  private _surveyAnswer: any;

  public villageObjects: Village[] = [];

  public locationGroupClass = 'col';
  public surveyGroupClass = 'row';

  public method: string;
  private _clientId: string;

  constructor(private _route: ActivatedRoute, private _formBuilder: FormBuilder,
    private _clientsService: ClientsService, private _toastr: ToastrService, private _router: Router,
    private _sharedService: SharedService) {
    this.companyFormGroup = this._formBuilder.group({
      id: [''],
      name: ['', [<any>Validators.required, <any>Validators.minLength(1)]],
      firstEmail: ['', [<any>Validators.required, <any>Validators.email]],
      secondEmail: ['', <any>Validators.email],
      firstPhone: [''],
      secondPhone: [''],
      fiscalCode: [''],
      vat: [''],
      pecEmail: ['', <any>Validators.email],
      isdNumber: [''],
      address: [''],
      postalCode: [''],
      city: [''],
      country: ['1', [<any>Validators.required]],
      status: ['1'],
      notes: [''],
      village: [''],
    });
    this.clientFormGroup = this._formBuilder.group({
      id: [null],
      title: ['Herr', [<any>Validators.required]],
      firstName: ['', [<any>Validators.required, <any>Validators.minLength(2)]],
      lastName: ['', [<any>Validators.required, <any>Validators.minLength(2)]],
      firstEmail: ['', [<any>Validators.required, <any>Validators.email]],
      secondEmail: [null, <any>Validators.email],
      firstPhone: [''],
      secondPhone: [''],
      username: [''],
      newPassword: [null],
      repeatPassword: [null],
      company: [null],
      companyAction: ['0'],
      status: ['1'],
      notes: [''],
      language: ['de', [<any>Validators.required]],
      newsletter: [false],
      survey: [0],
      surveyDescription: ['']
    });
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      console.log(params);
      this.method = params['methodType'];
      if (this.method !== 'create' && this.method !== 'edit' && this.method !== 'duplicate') {
        this._router.navigate(['/companies']);
      }
      this._clientId = params['clientId'];
      this._sharedService.getAllVillages().subscribe(result => {
        this.villageObjects = result;
        result.forEach(village => {
          this.villageOptions.push({ value: village.id, label: village.name });
        });
        this.villageOptions = [...this.villageOptions];
      });
      this._clientsService.getSurveyOptions().subscribe(result => {
        this.surveys = result;
        this.surveys.forEach(item => {
          item.name = item.nameDe;
        });
      });
      this.clientFormGroup.get('surveyDescription').setValue('');
      if (this.method === 'edit' || this.method === 'duplicate') {
        this._clientsService.getClientCompanyInformationsById(this._clientId).subscribe(result => {
          console.log(result);
          this.clientFormGroup.patchValue(result);
          if (result.company) {
            this.clientFormGroup.get('companyAction').setValue('2');
            this.company = result.company;
            this.onClientCompanyActionChange();
          }
        });
      }
    });
  }

  onClientCompanyActionChange() {
    this.companyFormGroup.reset();
    this.companyFormGroup.patchValue({ country: 1, status: 1 });
    switch (this.clientFormGroup.get('companyAction').value) {
      case '1':
        this.showCompanyForm = true;
        this.showCompanySearch = false;
        break;
      case '2':
        this.showCompanySearch = true;
        this.showCompanyForm = false;
        break;
      default:
        this.showCompanyForm = false;
        this.showCompanySearch = false;
        break;
    }
  }

  onCompanySelectedEvent(companyObject) {
    console.log(companyObject);
    this.company = Company.fromDto(companyObject);
  }


  onClientFormSubmit() {

    if (this.clientFormGroup.valid) {
      const client = Client.fromDto(this.clientFormGroup.value);

      let companyCheck = true;
      if (this.company) {
        client.company = Company.fromDto(this.company);
      }
      if (this.showCompanyForm) {
        if (this.companyFormGroup.valid) {
          client.company = Company.fromDto(this.companyFormGroup.value);
          client.company.village = this.villageObjects.filter(village => village.id === this.companyFormGroup.get('village').value)[0];
        } else {
          this.companyErrorArray = ValidationUtils.getFormValidationErrors(this.companyFormGroup, 'update-client');
          companyCheck = false;
        }
      }
      if ((this.method === 'create') && (this.clientFormGroup.controls['survey'].value === 0)) {
        this._toastr.error('Please select one survey option!');
        companyCheck = false;
      }
      if (companyCheck) {
        if (this.clientFormGroup.get('newPassword').value) {
          client.password = this.clientFormGroup.get('newPassword').value;
        }
        if (this.method === 'create' || this.method === 'duplicate') {
          client.answer.surveyId = this.clientFormGroup.controls['survey'].value;
          if (this.clientFormGroup.controls['survey'].value === 7) {
            client.answer.description = this.clientFormGroup.controls['surveyDescription'].value;
          } else {
            client.answer.description = '';
          }
          this._clientsService.addClient(client).subscribe(result => {
            this.clientFormGroup.reset({ companyAction: 0, status: 1, language: 'de' });
            this.onClientCompanyActionChange();
            if (this.method === 'duplicate') {
              this._toastr.success('Client duplicated successfully');
              this._router.navigate(['/clients']);
            } else {
              this._toastr.success('Client added successfully');
              this._router.navigate(['/clients']);
            }
          }, error => {
            console.log(error);
            switch (error.status) {
              case 600:
                this._toastr.error('Client Username already exist');
                break;
              case 601:
                this._toastr.error('Client First Email already exist');
                break;
              default:
                this._toastr.error('General Error');
                break;
            }
          });
        } else {
          this._clientsService.updateSpecificClientCompany(client).subscribe(result => {
            this._toastr.success('Client updated successfully');
            this._router.navigate(['/clients']);
          }, error => {
            switch(error.status) {
              case 601:
                this._toastr.error('Client First Email already exist');
                break;
              default:
                this._toastr.error('General Error');
                break;
            }
          });
        }
      }
    }
    this.clientErrorArray = ValidationUtils.getFormValidationErrors(this.clientFormGroup, 'update-client');
    console.log(this.clientErrorArray);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DeviceStatusEnum } from '../enums/DeviceStatusEnum';

@Pipe({
  name: 'deviceStatus'
})
export class DeviceStatusPipe implements PipeTransform {

  transform(status: any): any {
    let deviceStatus = '';
    switch (status) {
      case DeviceStatusEnum.Available:
        deviceStatus = 'Available';
        break;
      case DeviceStatusEnum.Rented:
        deviceStatus = 'Rented';
        break;
      case DeviceStatusEnum.Damaged:
        deviceStatus = 'Damaged';
        break;
      default:
        deviceStatus = 'Deleted';
        break;
    }
    return deviceStatus;
  }
}

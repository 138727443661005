import { Event } from './Event';
import { RouterType } from './RouterType';

export class RequestedRouterType {
    public id: number;
    public quantity: number;
    public parentRequestId: number;
    public requestedAt: string;
    public event: Event;
    public routerTypes: RouterType;

    static fromDto(r): RequestedRouterType {
        const requestedRouterType = new RequestedRouterType();

        if (!r) {
            return null;
        }

        requestedRouterType.id = r.id;
        requestedRouterType.quantity = r.quantity;
        requestedRouterType.parentRequestId = r.parentRequestId;
        requestedRouterType.requestedAt = r.requestedAt;
        requestedRouterType.event = Event.fromDto(r.event);
        requestedRouterType.routerTypes = RouterType.fromDto(r.routerTypes);

        return requestedRouterType;
    }
}

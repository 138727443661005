import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LocationsComponent } from './locations/locations.component';
import { SettingsComponent } from './../settings/settings.component';
import { SETTINGS_ROUTES } from './settings.routes';
import { AddNewVillageComponent } from './locations/villages/add-new-village/add-new-village.component';
import { VillagesComponent } from './locations/villages/villages.component';
import { ProvincesComponent } from './locations/provinces/provinces.component';
import { RegionsComponent } from './locations/regions/regions.component';
import { CountriesComponent } from './locations/countries/countries.component';
import { EditVillageFormComponent } from './locations/villages/edit-village-form/edit-village-form.component';
import { EditProvinceFormComponent } from './locations/provinces/edit-province-form/edit-province-form.component';
import { AddNewProvinceComponent } from './locations/provinces/add-new-province/add-new-province.component';
import { AddNewRegionComponent } from './locations/regions/add-new-region/add-new-region.component';
import { EditRegionFormComponent } from './locations/regions/edit-region-form/edit-region-form.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(SETTINGS_ROUTES),
    SharedModule,
    NgSelectModule
  ],
  declarations: [
    LocationsComponent,
    SettingsComponent,
    AddNewVillageComponent,
    VillagesComponent,
    ProvincesComponent,
    RegionsComponent,
    CountriesComponent,
    EditVillageFormComponent,
    EditProvinceFormComponent,
    AddNewProvinceComponent,
    AddNewRegionComponent,
    EditRegionFormComponent
  ],
  providers: [
  ]
})
export class SettingsModule { }
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyStatus'
})
export class CompanyStatusPipe implements PipeTransform {

  transform(status: any): any {
    let humanStatus = '';
    switch (status) {
      case 1:
        humanStatus = 'Enabled';
        break;
      case 2:
        humanStatus = 'Disabled';
        break;
      default:
        humanStatus = 'Deleted';
        break;
    }
    return humanStatus;
  }

}

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { PrintersSearchCriteria } from '../shared/PrintersSearchCriteria';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { Printer } from 'src/app/shared/models/Printer';
import { Observable } from 'rxjs';
import { PrintersService } from '../shared/printers.service';
import { DataTableColumn } from 'src/app/shared/data-table/data-table.component';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { PrinterStatusEnum } from 'src/app/shared/enums/PrinterStatusEnums';
import { PrinterType } from 'src/app/shared/models/PrinterType';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-printers-list',
  templateUrl: './printers-list.component.html',
  styleUrls: ['./printers-list.component.css']
})
export class PrintersListComponent implements OnInit {

  public defaultStartDate = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  public defaultEndDate = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  public defaultPageSize = '99999';

  public pageSizeOptionArray: Option[] = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '10' },
    { value: '99999', label: 'All' },
  ];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Printer>> = null;
  filters: PrintersSearchCriteria = {startdate: this.defaultStartDate, enddate: this.defaultEndDate, pageSize: this.defaultPageSize};
  public printerTypeVals: PrinterType[] = [];
  public printerStatusArray: Option[] = [
    { value: undefined, label: 'ALL' },
    { value: PrinterStatusEnum.Available, label: 'Available' },
    { value: PrinterStatusEnum.Damaged, label: 'Damaged' },
    { value: PrinterStatusEnum.Deleted, label: 'Deleted' },
    { value: PrinterStatusEnum.Rented, label: 'Rented' },
  ];

  @ViewChild('id')
  id: TemplateRef<any>;

  @ViewChild('name')
  name: TemplateRef<any>;

  @ViewChild('statusColumn')
  statusColumn: TemplateRef<any>;

  @ViewChild('identifier')
  identifier: TemplateRef<any>;

  @ViewChild('printerType')
  printerType: TemplateRef<any>;

  @ViewChild('lan')
  lan: TemplateRef<any>;

  @ViewChild('bluetooth')
  bluetooth: TemplateRef<any>;

  @ViewChild('wifi')
  wifi: TemplateRef<any>;

  @ViewChild('usb')
  usb: TemplateRef<any>;

  @ViewChild('notes')
  notes: TemplateRef<any>;

  @ViewChild('printerManufacturers')
  printerManufacturers: TemplateRef<any>;

  @ViewChild('companies')
  companies: TemplateRef<any>;

  @ViewChild('printerAssignedCount')
  printerAssignedCount: TemplateRef<any>;

  @ViewChild('assignedEvent')
  assignedEvent: TemplateRef<any>;

  @ViewChild('actionsColumn')
  actionsColumn: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private _printersService: PrintersService, private _toastr: ToastrService, private _sanitizer: DomSanitizer) {
    this.loadPage = (page: number, pageSize: number) =>
      this._printersService.search(page, pageSize, this.filters);
  }

  ngOnInit() {
    this._printersService.getAllPrinterTypes().subscribe(result => {
      this.printerTypeVals = result;
    });
    this.columns = [
      // {
      //   name: 'id',
      //   displayed: true,
      //   translationKey: 'check',
      //   template: this.id,
      //   sortable: true,
      //   additionalClass: 'text-center',
      //   trAddidionalClass: 'text-center'
      // },
      {
        name: 'name',
        displayed: true,
        translationKey: 'name',
        template: this.name,
        sortable: true
      },
      {
        name: 'companies',
        displayed: true,
        translationKey: 'companies',
        template: this.companies,
        sortable: true
      },
      {
        name: 'identifier',
        displayed: true,
        translationKey: 'identifier',
        template: this.identifier,
        sortable: true
      },
      {
        name: 'printerManufacturers',
        displayed: true,
        translationKey: 'printerManufacturers',
        template: this.printerManufacturers,
        sortable: true
      },
      {
        name: 'printerType',
        displayed: true,
        translationKey: 'printer type name',
        template: this.printerType,
        sortable: true
      },
      {
        name: 'lan',
        displayed: true,
        translationKey: 'lan',
        template: this.lan,
        sortable: true
      },
      {
        name: 'bluetooth',
        displayed: true,
        translationKey: 'bluetooth',
        template: this.bluetooth,
        sortable: true
      },
      {
        name: 'wifi',
        displayed: true,
        translationKey: 'wifi',
        template: this.wifi,
        sortable: true
      },
      {
        name: 'usb',
        displayed: true,
        translationKey: 'usb',
        template: this.usb,
        sortable: true
      },
      {
        name: 'notes',
        displayed: true,
        translationKey: 'notes',
        template: this.notes,
        sortable: true
      },
      {
        name: 'statusColumn',
        displayed: true,
        translationKey: 'Status',
        template: this.statusColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'printerAssignedCount',
        displayed: true,
        translationKey: 'Ass. Count',
        template: this.printerAssignedCount,
        sortable: false
      },
      {
        name: 'assignedEvent',
        displayed: true,
        translationKey: 'Ass. Event',
        template: this.assignedEvent,
        sortable: false
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actionsColumn,
        sortable: true,
        additionalClass: 'text-right'
      }
    ];
  }
  softDeletePrinter(id: string, force: string) {
    this._printersService.deletePrinter(id, force).subscribe(result => {
      this.loadPage = (page: number, pageSize: number) =>
        this._printersService.search(page, pageSize, this.filters);
      this._toastr.success('Printer succesfully deleted');
    });
  }
  formatCompanyName(companies: any[]): string {
    let companiesString = '';
    if (companies) {
      let index = 1;
     companies.forEach(company => {
        companiesString =  (index < companies.length) ? companiesString + company.name + ', ' : companiesString + company.name;
        index++;
      });

    }
    return companiesString.toUpperCase();
  }
  showAssignedEvent (eventString: string): any {
    if (eventString) {
      const html = this._sanitizer.bypassSecurityTrustHtml(eventString.replace(/,/g, '<br />'));
      return html;
    } else {
      return '';
    }
  }
}

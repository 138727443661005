import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { VillagesService } from './../shared/villages.service';
import { Village } from 'src/app/shared/models/Village';
import { Province } from 'src/app/shared/models/Province';

@Component({
  selector: 'app-edit-village-form',
  templateUrl: './edit-village-form.component.html',
  styleUrls: ['./edit-village-form.component.css']
})
export class EditVillageFormComponent implements OnInit {

  public editVillageForm: FormGroup;
  public editErrorArray = new Array();

  @Input()
  village: Village;

  @Input()
  provincesOptions: Option[];

  @Input()
  villagesList: Village[];

  @Input()
  provincesList: Province[];

  @Output()
  geResetVillagesList: EventEmitter<Village[]> = new EventEmitter<Village[]>();

  @Output()
  getCancelEditModeEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(private _formBuilder: FormBuilder, private _villagesService: VillagesService, private _toastr: ToastrService,
    private _translate: TranslateService) {
    this.editVillageForm = this._formBuilder.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      provinceId: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.editVillageForm.patchValue({
      provinceId: this.village.province.id,
      name: this.village.name,
    });
  }

  ngOnInit() {
  }

  onVillageSubmit() {
    const village = Village.fromDto(this.editVillageForm.value);
    village.id = this.village.id;
    village.province = this.provincesList.filter(province =>
      province.id.toString() === this.editVillageForm.value.provinceId.toString())[0];
    this._villagesService.updateVillage(village).subscribe(result => {
      this.villagesList = result;
      this.geResetVillagesList.emit(this.villagesList);
      this.getCancelEditModeEvent.emit();
      this._toastr.success(this._translate.instant('Village updated with success'));
    }, error => {
      console.log(error);
    });
    this.editErrorArray = ValidationUtils.getFormValidationErrors(this.editVillageForm, 'village');
  }

  cancelEditButtonClick() {
    this.getCancelEditModeEvent.emit();
  }
}

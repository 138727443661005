import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-price-lists',
  templateUrl: './price-lists.component.html',
  styleUrls: ['./price-lists.component.css']
})
export class PriceListsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

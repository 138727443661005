import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-group-datepicker',
  templateUrl: './form-group-datepicker.component.html',
  styleUrls: ['./form-group-datepicker.component.css']
})
export class FormGroupDatepickerComponent implements OnInit {

  datePickerConfig = {
    format: 'DD-MM-YYYY',
    firstDayOfWeek: 'mo'
  };

  @Input()
  label: string;

  @Input()
  model;

  @Input()
  disabled = false;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  change() {
    this.modelChange.emit(this.model);
  }

}

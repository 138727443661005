import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PriceList } from 'src/app/shared/models/PriceList';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { PriceListService } from '../../shared/price-list.service';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-category-table',
  templateUrl: './category-table.component.html',
  styleUrls: ['./category-table.component.css']
})
export class CategoryTableComponent implements OnInit {

  public toEditProductCategory: ProductCategory = new ProductCategory();
  public editProductMood = false;
  private _selectedProductCategoryId;


  @ViewChild('confirmDeleteProductCategoryDialog')
  confirmDeleteProductCategoryDialog;

  @ViewChild('confirmDuplicateProductCategoryDialog')
  confirmDuplicateProductCategoryDialog;

  @Input()
  priceList: PriceList;

  constructor(private _priceListService: PriceListService, private _toast: ToastrService, private _translate: TranslateService) { }

  ngOnInit() {
  }

  changeCategoryStatus(productCategory: ProductCategory, status: number) {
    productCategory.status = status;
    this._priceListService.updateProductCategory(productCategory).subscribe(result => {
      if (status === 0) {
        this._toast.success(this._translate.instant('price-list.manage.productCategoryDisabledSuccessMsg'));
      } else {
        this._toast.success(this._translate.instant('price-list.manage.productCategoryEnabledSuccessMsg'));
      }
    }, error => {
      console.log(error);
    });
  }

  getDeleteProductCategoryDecision(decision: boolean) {
    if (decision) {
      this._priceListService.deleteProductCategory(this._selectedProductCategoryId).subscribe(result => {
        this.priceList.productCategories = result;
        this._toast.success(this._translate.instant('price-list.manage.productCategorySuccessDeleteMsg'));
      }, error => {
        console.log(error);
      });
    }
    this._selectedProductCategoryId = undefined;
  }

  deleteProductCategory(productCategoryId: number) {
    this.confirmDeleteProductCategoryDialog.openDialog();
    this._selectedProductCategoryId = productCategoryId;
  }

  enableEditProductCategory(productCategory: ProductCategory) {
    this.toEditProductCategory = { ...productCategory };
    this.editProductMood = true;
  }

  disableEditMode() {
    this.toEditProductCategory = new ProductCategory();
    this.editProductMood = false;
  }

  editProductCategory() {
    this.toEditProductCategory.priceList = new PriceList();
    this.toEditProductCategory.priceList.id = this.priceList.id;
    this._priceListService.updateProductCategory(this.toEditProductCategory).subscribe(result => {
      const productCategory = this.priceList.productCategories.filter(cat => cat.id === result.id)[0];
      productCategory.name = result.name;
      this.toEditProductCategory = new ProductCategory();
      this.editProductMood = false;
      this._toast.success(this._translate.instant('price-list.manage.productCategorySuccessUpdateMsg'));
    }, error => {
      console.log(error);
    });
  }

  changeCategoryPosition(categoryId: number, position: number) {
    this._priceListService.changeProductCategoryPosition(categoryId, position, this.priceList.id).subscribe(result => {
      this.priceList.productCategories = result;
    }, error => {
      console.log(error);
    });
  }

  getDuplicateProductCategoryDecision(decision: boolean) {
    if (decision) {
      this._priceListService.duplicateProductCategory(this._selectedProductCategoryId, this.priceList.id).subscribe(result => {
        this._toast.success(this._translate.instant('price-list.manage.productCategorySuccessDuplicateMsg'));
        this.priceList.productCategories = result;
      }, error => {
        console.log(error);
      });
    }
    this._selectedProductCategoryId = undefined;
  }

  duplicateProductCategory(categoryId: number) {
    this._selectedProductCategoryId = categoryId;
    this.confirmDuplicateProductCategoryDialog.openDialog();
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoutersService } from '../shared/routers.service';
import { ActivatedRoute } from '@angular/router';
import * as RouterObject from 'src/app/shared/models/Router';
import { RouterStatusEnum } from 'src/app/shared/enums/RouterStatusEnum';
import { RouterType } from 'src/app/shared/models/RouterType';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { ToastrService } from 'ngx-toastr';
import { ValidationUtils } from 'src/app/clients/shared/validation.utils';
import { Router } from 'src/app/shared/models/Router';


@Component({
  selector: 'app-update-router',
  templateUrl: './update-router.component.html',
  styleUrls: ['./update-router.component.css']
})
export class UpdateRouterComponent implements OnInit {

  public routerFormGroup: FormGroup;
  public routerTypeListApi: RouterType[] = [];
  private _routerId: string;
  private _method: string;
  public validationErrorsArray = [];
  public router: Router;

  public channelNo: Option[] = [
    { value: 'auto', label: 'Auto' },
    { value: 1, label: 'Channel 1' },
    { value: 2, label: 'Channel 2' },
    { value: 3, label: 'Channel 3' },
    { value: 4, label: 'Channel 4' },
    { value: 5, label: 'Channel 5' },
    { value: 6, label: 'Channel 6' },
    { value: 7, label: 'Channel 7' },
    { value: 8, label: 'Channel 8' },
    { value: 9, label: 'Channel 9' },
    { value: 10, label: 'Channel 10' },
  ];

  public routerStatus: Option[] = [
    { value: RouterStatusEnum.Deleted, label: 'Deleted' },
    { value: RouterStatusEnum.Available, label: 'Available' },
    { value: RouterStatusEnum.Rented, label: 'Rented' },
    { value: RouterStatusEnum.Damaged, label: 'Damaged' }
  ];

  constructor(private _toastr: ToastrService, private _formBuilder: FormBuilder,
    private _routersService: RoutersService, private _route: ActivatedRoute, ) {
    this.routerFormGroup = this._formBuilder.group({
      id: [],
      name: ['', Validators.required],
      status: [1],
      routerType: [, Validators.required],
      channel: ['', Validators.required],
      master: ['', Validators.required],
      networkName: ['', Validators.required],
      networkPassword: ['', Validators.required],
      routerIp: ['', Validators.required],
      routerUsername: ['', Validators.required],
      routerPassword: ['', Validators.required],
      notes: ['']
    });
  }

  ngOnInit() {
    this._routersService.getAllRouterTypes().subscribe(result => {
      this.routerTypeListApi = result;
    });
    this._route.params.subscribe(params => {
      this._routerId = params['routerId'];
      this._method = params['methodType'];

      if (this._method === 'update') {
        this._routersService.getAllRoutersById(this._routerId).subscribe(result => {
          this.routerFormGroup.patchValue(result);
          this.routerFormGroup.get('routerType').setValue(result.routerType.id);
        });
      }
    });
  }

  onRouterFormSubmit() {
    if (this.routerFormGroup.valid) {
      this.router = RouterObject.Router.fromDto(this.routerFormGroup.value);
      this.router.notes = (this.router.notes !== '') ? this.router.notes : null;
      this.routerFormGroup.value.routerType = this.routerTypeListApi.filter(routerType =>
        routerType.id.toString() === this.routerFormGroup.value.routerType.toString())[0];
      this._routersService.updateRouter(this.router).subscribe(result => {
      });
      this._toastr.success((this._method === 'update' ? 'Router updated with success' : 'Router inserted with success'));
      if (this._method === 'insert') {
        this._routersService.insertRouter(this.router).subscribe(result => {
        });
        this.routerFormGroup.reset({ status: 1 });
      }
    }
    this.validationErrorsArray = ValidationUtils.getFormValidationErrors(this.routerFormGroup, 'router');
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-group-price-input',
  templateUrl: './form-group-price-input.component.html',
  styleUrls: ['./form-group-price-input.component.css']
})
export class FormGroupPriceInputComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  model: number;

  @Input()
  priceStep = 0.10;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  changePriceValueClickEvent(negative) {
    this.model = (Math.round((negative ? (this.model - this.priceStep) : (this.model + this.priceStep)) * 100) / 100);
    this.modelChange.emit(this.model);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from './core/core.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { APP_VERSION } from './core/constants';
import { ClientsModule } from './clients/clients.module';
import { PriceListsModule } from './price-lists/price-lists.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { EventsModule } from './events/events.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompaniesModule } from './companies/companies.module';
import { PrintersModule } from './printers/printers.module';
import { DevicesModule } from './devices/devices.module';
import { RoutersModule } from './routers/routers.module';
import { OffersModule } from './offers/offers.module';
import { SettingsModule } from './settings/settings.module';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' }
];

const translationConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
};

const toastrConfig = {
  closeButton: true,
  progressBar: true,
  positionClass: 'toast-bottom-left',
  timeOut: 3000
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    TranslateModule.forRoot(translationConfig),
    ToastrModule.forRoot(toastrConfig),
    BrowserAnimationsModule,
    JwtModule.forRoot({ config: { tokenGetter: tokenGetter, headerName: 'X-Access-Token' } }),
    NgHttpLoaderModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    LeafletModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ClientsModule,
    CoreModule,
    PriceListsModule,
    EventsModule,
    CompaniesModule,
    PrintersModule,
    DevicesModule,
    RoutersModule,
    OffersModule,
    SettingsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?version=${APP_VERSION}`);
}

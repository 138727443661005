import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { ValidationUtils } from 'src/app/clients/shared/validation.utils';
import { Country } from 'src/app/shared/models/Country';
import { Region } from 'src/app/shared/models/Region';
import { RegionsService } from './../shared/regions.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-new-region',
  templateUrl: './add-new-region.component.html',
  styleUrls: ['./add-new-region.component.css']
})
export class AddNewRegionComponent implements OnInit {

  private regionObj: Region;
  public regionFormGroup: FormGroup;
  public validationErrorsArray = [];

  @Input()
  regionsList: Region[];

  @Input()
  countriesList: Country[];

  @Input()
  countriesOptions: Option[];

  constructor(private _regionsService: RegionsService, private _formBuilder: FormBuilder, private _toastr: ToastrService, private _translate: TranslateService) {
    this.regionFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      countryId: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  onRegionFormsSubmit() {
    this.validationErrorsArray = [];
    if (this.regionFormGroup.valid) {
      this.regionObj = Region.fromDto(this.regionFormGroup.value);
      this.regionObj.country = this.countriesList.filter(country => country.id.toString() === this.regionFormGroup.value.countryId)[0];
      this._regionsService.insertRegion(this.regionObj).subscribe(result => {
        this.regionsList.push(result);
        this._toastr.success(this._translate.instant('Region added with success'));
      }, error => {
        console.log(error);
      });
    }
    this.validationErrorsArray = ValidationUtils.getFormValidationErrors(this.regionFormGroup, 'region');
  }

}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from 'src/app/shared/models/Router';
import { Event } from 'src/app/shared/models/Event';
import { Option } from 'src/app/shared/select/select.component';
import { EventsService } from 'src/app/events/shared/events.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-routers-card',
  templateUrl: './routers-card.component.html',
  styleUrls: ['./routers-card.component.css']
})
export class RoutersCardComponent implements OnInit {

  public routerId = undefined;
  public selectedRouter: Router = new Router();
  public routerExtension = 'Not present';

  @Input()
  event: Event = new Event();

  @Input()
  availableRouterOptions: Option[];

  @Input()
  availableRouterObjects: Router[];

  @Input()
  eventFinished = false;

  @Input()
  freeRealRouterAmount = 0;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onRouterAddEvent = new EventEmitter<void>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onRouterRemoveEvent = new EventEmitter<void>();

  constructor(private _translate: TranslateService, private _toastr: ToastrService, private _eventsService: EventsService) { }

  ngOnInit() {
  }


  associateRouterToEventClick() {
    let maxAmount = 0;
    if (this.routerId) {
      this.event.requestedRouterTypes.forEach(routerType => {
        maxAmount += routerType.quantity;
      });
      if (this.event.routers.length <= (maxAmount - 1)) {
        this.event.routers.push(this.selectedRouter);
        this._eventsService.assignRouterToEventByIds(this.event.id.toString(), this.event.routers).subscribe(result => {
          this.selectedRouter = new Router();
          this.routerId = undefined;
          this.onRouterAddEvent.emit();
        }, error => {
          console.log(error);
        });
      } else {
        this._toastr.warning(this._translate.instant('Cannot exceed the requested number of routers'));
      }
    }
  }

  unassociateRouterFromEventClick(router) {
    this.routerId = router.id;
    this._eventsService.unAssignRouterFromEventById(this.event.id.toString(), [router]).subscribe(result => {
      this.selectedRouter = new Router();
      this.routerId = undefined;
      this._removeRouterFromArray(router.id);
      this.onRouterRemoveEvent.emit();
    }, error => {
      console.log(error);
    });
  }

  private _removeRouterFromArray(routerId) {
    const requestedRouter = this.event.routers.filter(currentRouter => currentRouter.id === routerId)[0];
    const index = this.event.routers.indexOf(requestedRouter);
    this.event.routers.splice(index, 1);
  }

  onRouterSelectChange() {
    console.log('Change');
    if (this.routerId !== undefined) {
      this.selectedRouter = this.availableRouterObjects.filter(router => router.id === this.routerId)[0];
    } else {
      this.selectedRouter = new Router();
    }
  }

}

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router as AgularRouter } from '@angular/router';
import { EventsService } from '../shared/events.service';
import { Event } from 'src/app/shared/models/Event';
import { Option } from 'src/app/shared/select/select.component';
import { ToastrService } from 'ngx-toastr';
import { Printer } from 'src/app/shared/models/Printer';
import { Device } from 'src/app/shared/models/Device';
import { Router } from 'src/app/shared/models/Router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/shared/navigation.service';
import { Subject, forkJoin } from 'rxjs';
import { DeviceUser } from 'src/app/shared/models/DeviceUser';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { SharedUtils } from 'src/app/shared/shared-utils';
import { RouterStatusEnum } from 'src/app/shared/enums/RouterStatusEnum';
import { PickupPoint } from 'src/app/shared/models/PickupPoint';
import { SharedService } from 'src/app/shared/shared.service';
import { DeliveryAddress } from 'src/app/shared/models/DeliveryAddress';
import { Country } from 'src/app/shared/models/Country';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { PrinterStatusEnum } from 'src/app/shared/enums/PrinterStatusEnums';
import { DeviceStatusEnum } from 'src/app/shared/enums/DeviceStatusEnum';
import { isThisISOWeek } from 'date-fns';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {

  public event: Event = new Event();
  public printerListOptions: Option[];
  public availablePrinterObjects: Printer[];
  public routerListOptions: Option[];
  public availableRouterObjects: Router[];
  public devicesListOptions: Option[];
  public availableDevicesObjects: Device[] = [];
  public priceListOptions: Option[];
  public countriesOptions: Option[] = [];
  public eventFinished = false;
  public refreshPrinterCard = false;
  public refreshRouterCard = false;
  public freeRealRouterAmount = 0;
  public freeRealPrinterAmount = 0;
  public preparationDate = '';
  public printerCategoriesComponentNotifier = new Subject<DeviceUser[]>();
  public nextPreviousEventIds = { nextId: undefined, previousId: undefined };
  public pickupPoints: PickupPoint[] = [];
  public choosenPickupPoint: number;
  public pickupPoint: PickupPoint;
  public deliveryAddress: DeliveryAddress;
  public deliveryAddressForm: FormGroup;
  public deliveryAddressErrorArray = new Array();

  public forceDeleteModalSecureKey: string;
  public forceDeleteModalSecureKeyConfirmation: string;

  private _eventId = undefined;
  private _countriesObjectArray: Country[];

  @ViewChild('forceDeleteModal')
  private forceDeleteModal: TemplateRef<any>;

  constructor(private _nav: NavigationService, private _translate: TranslateService,
    private _route: ActivatedRoute, private _eventsService: EventsService,
    private _toastr: ToastrService, private _router: AgularRouter,
    private modalService: NgbModal, modalConfig: NgbModalConfig, private _sharedService: SharedService, private _formBuilder: FormBuilder) {
    this.deliveryAddressForm = this._formBuilder.group({
      id: [],
      firstLastName: ['', <any>Validators.required],
      address: ['', <any>Validators.required],
      addressDetails: [''],
      city: ['', <any>Validators.required],
      province: ['', <any>Validators.required],
      postcode: ['', <any>Validators.required],
      phone: ['', <any>Validators.required],
      country: ['1', <any>Validators.required],
    });
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this._eventId = params['eventId'];
      forkJoin([
        this._eventsService.getEventById(this._eventId),
        this._sharedService.getPickupPoints(),
        this._sharedService.getAllCountries(),
      ]).subscribe(result => {
        this.event = result[0];
        console.log('this.event', this.event);
        this.event.endDate = moment(this.event.endDate).subtract(1, 'day').format('YYYY-MM-DD');
        this.populateDevicesSelect();
        this.populatePriceListSelectBox();
        this.populatePrinterSelect();
        this.populateRouterSelect();
        this.calculateRemeaningHardware();
        this.getNextAndPreviousEventId();
        if (this.event.status === 2 && (moment() >= moment(this.event.endDate).add(1, 'day'))) {
          this.eventFinished = true;
        }
        if (this.event.status === 1) {
          this.eventFinished = false;
          this._eventsService.approveEvent(this.event.id).subscribe(() => {
            this.event.status = 2;
          }, error => {
            console.log(error);
          });
        }
        this.pickupPoints = result[1];
        if (!this.event.delivery) {
          this._eventsService.getPickupPointByEventId(this.event.id.toString()).subscribe(pickupPoint => {
            this.pickupPoint = pickupPoint;
            this.choosenPickupPoint = pickupPoint.id;
          });
        } else {
          this._eventsService.getDeliveryAddressByEventId(this.event.id.toString()).subscribe(deliveryAddress => {
            this.deliveryAddress = deliveryAddress;
            this.deliveryAddressForm.patchValue(this.deliveryAddress);
            this.deliveryAddressForm.get('country').setValue(this.deliveryAddress.country.id);
          });
        }
        this._countriesObjectArray = result[2];
        this._countriesObjectArray.forEach(country => {
          this.countriesOptions.push({ value: country.id.toString(), label: country.name });
        });
      }, error => {
        console.log(error);
        this._router.navigate(['/events/all']);
      });
    });
  }

  getNextAndPreviousEventId() {
    this._eventsService.getNextAndPreviousEventIds(this.event.id.toString()).subscribe(result => {
      this.nextPreviousEventIds = result;
    });
  }

  changeToOtherEvent(type: string) {
    const eventId = (type === 'next' ? this.nextPreviousEventIds.nextId : this.nextPreviousEventIds.previousId);
    this._router.navigate(['/events/details/' + eventId]);
  }

  calculateRemeaningHardware() {
    console.log(this.event);
    const realRouterAmount = this.event.routers.length;
    let requestedRouterAmount = 0;
    this.event.requestedRouterTypes.forEach(requestedRouter => {
      requestedRouterAmount += requestedRouter.quantity;
    });
    this.freeRealRouterAmount = (requestedRouterAmount - realRouterAmount);
    //
    let requestedPrinterAmount = 0;
    this.event.requestedSoftwareTypes.forEach(requestedSoftwareType => {
      if (requestedSoftwareType.softwareType.id === 4) {
        requestedPrinterAmount += requestedSoftwareType.quantity;
      }
    });
    this.freeRealPrinterAmount = (requestedPrinterAmount - this.event.printers.length);
  }

  denyEventClick() {
    this._eventsService.cancelEvent(this.event.id).subscribe(result => {
      this.event.status = 3;
      this._toastr.success('Event denied Successfuly');
    }, error => {
      console.log(error);
    });
  }

  getRequestedSoftwareTypesQuantity(requestedSoftwareTypes) {
    let quantity = 0;
    requestedSoftwareTypes.forEach(softwareType => {
      if (softwareType.softwareType.id !== 4) {
        quantity += softwareType.quantity;
      }
    });
    return quantity;
  }

  populateDevicesSelect() {
    this.devicesListOptions = [{ value: undefined, label: 'No Device' }];
    this._eventsService.getAllDevices().subscribe(devices => {
      devices.forEach(device => {
        if (device.status === DeviceStatusEnum.Available) {
          const usersWithDevices = this.event.deviceUsers.filter(item => item.device !== null);
          const hasThisDevice = usersWithDevices.filter(user => user.device.id === device.id)[0];
          if (!hasThisDevice) {
            this.availableDevicesObjects.push(device);
            this.devicesListOptions.push({ value: device.id, label: device.name + ' - ' + device.deviceType.name });
          }
        }
      });
      this.event['deviceUsers'] = this.event.deviceUsers;
    }, error => {
      console.log(error);
    });
  }

  populatePrinterSelect() {
    this.printerListOptions = [{ value: undefined, label: 'Select a Printer' }];
    this._eventsService.getAllPrinters().subscribe(printers => {
      this.availablePrinterObjects = printers.filter(printer => printer.status === PrinterStatusEnum.Available);
      this.availablePrinterObjects.forEach(printer => {
        const requestedPrinter = this.event.printers.filter(currentPrinter => currentPrinter.id === printer.id)[0];
        if (requestedPrinter === undefined) {
          this.printerListOptions.push({ value: printer.id, label: printer.name });
        }
      });
      this.calculateRemeaningHardware();
      // Ivan: if I reassign an old printer, the printer categories component show my the old object
      // and not empty the categories of reassigned printer!!
      // Adding the notifier subject here, it has resolved the problem!!
      this.printerCategoriesComponentNotifier.next(this.event.deviceUsers);
    }, error => {
      console.log(error);
    });
  }

  populateRouterSelect() {
    this.routerListOptions = [{ value: undefined, label: 'Select a Router' }];
    this._eventsService.getAllRouters().subscribe(routers => {
      this.availableRouterObjects = routers.filter(router => router.status === RouterStatusEnum.Available);
      this.availableRouterObjects.forEach(router => {
        if (router.status === RouterStatusEnum.Available) {
          const requesterRouter = this.event.routers.filter(currentRouter => currentRouter.id === router.id)[0];
          if (requesterRouter === undefined) {
            this.routerListOptions.push({ value: router.id, label: router.name });
          }
        }
      });
      this.calculateRemeaningHardware();
    }, error => {
      console.log(error);
    });
  }

  populatePriceListSelectBox() {
    this.priceListOptions = [{ value: undefined, label: 'Select Price List' }];
    this._eventsService.getUnusedPriceListsByCompanyId(this.event.clientUser.company.id.toString()).subscribe(result => {
      result.forEach(priceList => {
        this.priceListOptions.push({ value: priceList.id, label: priceList.name });
      });
    }, error => {
      // console.log(error);
    });
    this._updateEventDeviceUsers();
  }

  onPriceListAssignedEvent() {
    this.populatePriceListSelectBox();
  }

  onPriceListAssignedEventB(priceListId: string) {
    let currentPriceList = null;
    let hasError = false;
    this.event.priceLists.forEach(priceList => {
      if (priceList.id.toString() === priceListId) {
        currentPriceList = priceList;
      }
    });
    console.log('currentPriceList:', currentPriceList);
    this.event.deviceUsers.forEach(deviceUser => {
      deviceUser.priceList = currentPriceList;
      this._eventsService.updateDeviceUser(deviceUser, this.event.id.toString()).subscribe(result => {
      }, error => {
        hasError = true;
        console.log(error);
      });
    });
    if (!hasError) {
      this._toastr.success('Price List assigned successfully');
    }
  }

  onPriceListRemovedEvent(priceListId: string) {
    const priceListIndex = this.event.priceLists.findIndex(priceList => priceList.id.toString() === priceListId);
    this.event.priceLists.splice(priceListIndex, 1);
    this._updateEventDeviceUsers();
    this.populatePriceListSelectBox();
  }

  onDeviceUserGenereated(generatedDeviceUsers: DeviceUser[]) {
    this.event.deviceUsers = generatedDeviceUsers;
    this.printerCategoriesComponentNotifier.next(generatedDeviceUsers);
  }

  onDeviceUserChange(generatedDeviceUsers: DeviceUser[]) {
    this.printerCategoriesComponentNotifier.next(generatedDeviceUsers);
  }

  onEventUpdate(newEvent: Event) {
    this.event = Event.fromDto(newEvent);
    this.calculateRemeaningHardware();
  }

  changeToPreparingStatus() {
    if (this.preparationDate === '') {
      this._toastr.warning('Please provice a Preparation Date');
      return;
    }
    let maxAmountPrinters = 0;
    let maxAmountRouters = 0;
    this.event.requestedPrinterTypes.forEach(printerType => {
      maxAmountPrinters += printerType.quantity;
    });
    this.event.requestedRouterTypes.forEach(routerType => {
      maxAmountRouters += routerType.quantity;
    });
    if (this.event.printers.length <= (maxAmountPrinters - 1)) {
      this._toastr.warning(this._translate.instant('You need to provide the required number of printers'));
    } else if (this.event.routers.length <= (maxAmountRouters - 1)) {
      this._toastr.warning(this._translate.instant('You need to provide the required number of routers'));
    } else {
      this._eventsService.preparingEvent(this._eventId, moment(this.preparationDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        .subscribe(result => {
          this._toastr.success('Event is now in preparation mode');
          this.event.status = 4;
          this.event.prepraingDate = moment(this.preparationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        });
    }
  }

  onEventUpdated(modifiedEvent: Event) {
    this.event.eventName = modifiedEvent.eventName;
    this.event.startDate = modifiedEvent.startDate;
    this.event.endDate = modifiedEvent.endDate;
    this.event.pickUpDate = modifiedEvent.pickUpDate;
    this.event.returnDate = modifiedEvent.returnDate;
  }

  private _updateEventDeviceUsers() {
    this._eventsService.getAllDeviceUsersByEventId(this._eventId).subscribe(result => {
      this.event.deviceUsers = result;
      this.printerCategoriesComponentNotifier.next(this.event.deviceUsers);
    });
  }

  openForceDeleteModal() {
    this.forceDeleteModalSecureKeyConfirmation = undefined;
    this.forceDeleteModalSecureKey = SharedUtils.makeid(5);
    this.modalService.open(this.forceDeleteModal);
  }

  forceDeleteEvent() {
    if (this.forceDeleteModalSecureKeyConfirmation === this.forceDeleteModalSecureKey) {
      this._eventsService.forceDeleteEvent(this._eventId).subscribe(result => {
        this.modalService.dismissAll();
        this._toastr.success('Event successfully deleted!');
        this._router.navigate(['/events/all']);
      }, error => {
        this.modalService.dismissAll();
        this._toastr.warning('Error during force delete');
      });
    } else {
      console.error('WRONG KEY');
    }
  }

  onDeliveryTypeRadioChange(delivery: boolean) {
    this.deliveryAddressErrorArray = [];
    this.event.delivery = delivery;
  }

  updateDeliveryInformations() {
    this.deliveryAddressErrorArray = [];
    let deliveryAddress: any;
    if (this.event.delivery) {
      if (this.deliveryAddressForm.valid) {
        deliveryAddress = DeliveryAddress.fromDto(this.deliveryAddressForm.value);
        deliveryAddress.country = this._countriesObjectArray.filter(
          country => country.id.toString() === this.deliveryAddressForm.value.country.toString())[0];
      } else {
        this.deliveryAddressErrorArray = ValidationUtils.getFormValidationErrors(this.deliveryAddressForm, 'offers.request');
        return;
      }
    } else {
      if (!this.choosenPickupPoint) {
        this._toastr.error('Please choose a pickup Place');
        return;
      }
    }
    this._eventsService.updateDeliveryMethodEvent(this.event.id.toString(), this.event.delivery, this.choosenPickupPoint, deliveryAddress)
      .subscribe(result => {
        if (!this.event.delivery) {
          this.deliveryAddressForm.reset({ country: '1' });
        } else {
          this.deliveryAddress = DeliveryAddress.fromDto(result);
          this.deliveryAddressForm.patchValue(this.deliveryAddress);
          this.deliveryAddressForm.get('country').setValue(this.deliveryAddress.country.id);
        }
      });
  }

}

import { Device } from './Device';

export class DeviceManufacturers {
    public id: number;
    public name: string;
    public devices: Device;

    static fromDto(d): DeviceManufacturers {
        const deviceManufacturers = new DeviceManufacturers();

        if (!d) {
            return null;
        }

        deviceManufacturers.id = d.id;
        deviceManufacturers.name = d.name;
        deviceManufacturers.devices = d.devices;

        return d;
    }
}

import { Component, OnInit } from '@angular/core';
import { EventsService } from '../shared/events.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Option } from 'src/app/shared/select/select.component';
import { LanguageEnum } from 'src/app/shared/enums/LanguageEnum';
import { forkJoin, Observable } from 'rxjs';
import * as moment from 'moment';
import { Client } from 'src/app/shared/models/Client';
import { ToastrService } from 'ngx-toastr';
import { RouterType } from 'src/app/shared/models/RouterType';
import { PrinterType } from 'src/app/shared/models/PrinterType';
import { DeviceType } from 'src/app/shared/models/DeviceType';
import { SoftwareType } from 'src/app/shared/models/SoftwareType';
import { Ups } from 'src/app/shared/models/Ups';
import { Cable } from 'src/app/shared/models/Cable';
import { Event } from 'src/app/shared/models/Event';
import { Village } from 'src/app/shared/models/Village';
import { RequestedSoftwareType } from 'src/app/shared/models/RequestedSoftwareType';
import { RequestedDevice } from 'src/app/shared/models/RequestedDevice';
import { RequestedPrinterType } from 'src/app/shared/models/RequestedPrinterType';
import { RequestedRouterType } from 'src/app/shared/models/RequestedRouterType';
import { RequestedUps } from 'src/app/shared/models/RequestedUps';
import { RequestedCable } from 'src/app/shared/models/RequestedCable';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request-event',
  templateUrl: './request-event.component.html',
  styleUrls: ['./request-event.component.css']
})
export class RequestEventComponent implements OnInit {
  public event: Event = new Event();
  public eventPrice = 0.00;
  public startDate = moment().format('DD-MM-YYYY');
  public endDate = moment().format('DD-MM-YYYY');
  public pickUpDate = moment().subtract(1, 'day').format('DD-MM-YYYY');
  public returnDate = moment().add(1, 'day').format('DD-MM-YYYY');
  public choosenVillage: Option = new Option();
  public villageOptions: Option[] = [];
  public eventLanguageArray: Option[] = [
    { value: 'de', label: LanguageEnum.DE },
    { value: 'it', label: LanguageEnum.IT },
    { value: 'en', label: LanguageEnum.EN },
  ];

  public softwareProductsAmount = {
    waiter: 0,
    cashRegister: 0,
    mainDevice: 0,
    printer: 0
  };

  public hardwareProductsAmount = {
    phones: 0,
    tablets: 0,
    printers: 0,
    routers: 0,
    ups: 0
  };

  public cableProductsAmount = {
    one: 0,
    five: 0,
    ten: 0,
    fifteen: 0,
    twenty: 0,
    thirty: 0,
    fifty: 0
  };

  private _routerTypesArray: RouterType[];
  private _printerTypesArray: PrinterType[];
  private _deviceTypesArray: DeviceType[];
  private _softwareTypesArray: SoftwareType[];
  private _upsArray: Ups[];
  private _cableArray: Cable[];
  private _villagArray: Village[];
  private _selectedClient = false;

  constructor(private _eventsService: EventsService, private _sharedService: SharedService, private _toastr: ToastrService,
    private _translate: TranslateService, private _router: Router) { }

  ngOnInit() {
    forkJoin([
      this._sharedService.getAllVillages(),
      this._eventsService.getAllRouterTypes(),
      this._eventsService.getAllPrinterTypes(),
      this._eventsService.getAllDeviceTypes(),
      this._eventsService.getAllSoftwareTypes(),
      this._eventsService.getAllUps(),
    ]).subscribe(responseList => {
      this._villagArray = responseList[0];
      this._villagArray.forEach(village => {
        this.villageOptions.push({ value: village.id.toString(), label: village.name });
      });
      this.villageOptions = [...this.villageOptions];
      this._routerTypesArray = responseList[1];
      this._printerTypesArray = responseList[2];
      this._deviceTypesArray = responseList[3];
      this._softwareTypesArray = responseList[4];
      this._upsArray = responseList[5];
      this._eventsService.getAllCables().subscribe(result => { this._cableArray = result; });
      this.event.language = 'de';
      this.event.zone = '';
    });
  }

  onClientSelectedEvent(client: Client) {
    this.event.clientUser = client;
    this._selectedClient = true;
    this.calculateEventPrice();
  }

  onSoftwareTypeQuantityChange(softwareTypeName: string, softwareTypeId: number) {
    const softwareTypeAmount = this.softwareProductsAmount[softwareTypeName];
    const existingSofwareTypeRequest = this.event.requestedSoftwareTypes.filter(requested =>
      requested.softwareType.id === softwareTypeId)[0];
    if (softwareTypeAmount > 0) {
      if (existingSofwareTypeRequest) {
        existingSofwareTypeRequest.quantity = softwareTypeAmount;
      } else {
        const softwareTypeRequest = new RequestedSoftwareType();
        softwareTypeRequest.quantity = softwareTypeAmount;
        softwareTypeRequest.requestedAt = moment().format('YYYY-MM-DD HH:mm:ss');
        softwareTypeRequest.softwareType = this._softwareTypesArray.filter(softwareType => softwareType.id === softwareTypeId)[0];
        this.event.requestedSoftwareTypes.push(softwareTypeRequest);
      }
    } else {
      this.event.requestedSoftwareTypes.splice(this.event.requestedSoftwareTypes.indexOf(existingSofwareTypeRequest), 1);
    }
    switch (softwareTypeId) {
      case 2:
      case 3:
        this.hardwareProductsAmount.tablets = (this.softwareProductsAmount.cashRegister + this.softwareProductsAmount.mainDevice);
        this.onHardwareTypeQuantityChange('tablets', 2);
        break;
      case 4:
        this.hardwareProductsAmount.printers = softwareTypeAmount;
        this.onHardwareTypeQuantityChange('printers', 1);
        break;
      default:
        this.hardwareProductsAmount.phones = softwareTypeAmount;
        this.onHardwareTypeQuantityChange('phones', 1);
        break;
    }
    this.calculateEventPrice();
  }

  onCableTypeQuantityChange(cableTypeName: string, cableTypeId: number) {
    const cableTypeAmount = this.cableProductsAmount[cableTypeName];
    const existingCableRequest = this.event.requestedCable.filter(cable => cable.cables.id === cableTypeId)[0];
    if (cableTypeAmount > 0) {
      if (existingCableRequest) {
        existingCableRequest.quantity = cableTypeAmount;
      } else {
        const cableRequest = new RequestedCable();
        cableRequest.quantity = cableTypeAmount;
        cableRequest.requestedAt = moment().format('YYYY-MM-DD HH:mm:ss');
        cableRequest.cables = this._cableArray.filter(cable => cable.id === cableTypeId)[0];
        this.event.requestedCable.push(cableRequest);
      }
    } else {
      this.event.requestedCable.splice(this.event.requestedCable.indexOf(existingCableRequest), 1);
    }
    this.calculateEventPrice();
  }

  onHardwareTypeQuantityChange(hardwareTypeName: string, hardwareTypeId: number) {
    const hardwareTypeAmount = this.hardwareProductsAmount[hardwareTypeName];
    switch (hardwareTypeName) {
      case 'printers':
        const existingPrinterType = this.event.requestedPrinterTypes.filter(printer => printer.printerType.id === hardwareTypeId)[0];
        const printerRequest = new RequestedPrinterType();
        printerRequest.quantity = hardwareTypeAmount;
        printerRequest.requestedAt = moment().format('YYYY-MM-DD HH:mm:ss');
        printerRequest.printerType = this._printerTypesArray.filter(printerType => printerType.id === hardwareTypeId)[0];
        this.manageHardware(existingPrinterType, hardwareTypeAmount, this.event.requestedPrinterTypes, printerRequest);
        break;
      case 'routers':
        const existingRouterType = this.event.requestedRouterTypes.filter(router => router.routerTypes.id === hardwareTypeId)[0];
        const routerRequest = new RequestedRouterType();
        routerRequest.quantity = hardwareTypeAmount;
        routerRequest.requestedAt = moment().format('YYYY-MM-DD HH:mm:ss');
        routerRequest.routerTypes = this._routerTypesArray.filter(routerType => routerType.id === hardwareTypeId)[0];
        this.manageHardware(existingRouterType, hardwareTypeAmount, this.event.requestedRouterTypes, routerRequest);
        break;
      case 'ups':
        const existingUps = this.event.requestedUps.filter(ups => ups.ups.id === hardwareTypeId)[0];
        const upsRequest = new RequestedUps();
        upsRequest.quantity = hardwareTypeAmount;
        upsRequest.requestedAt = moment().format('YYYY-MM-DD HH:mm:ss');
        upsRequest.ups = this._upsArray.filter(ups => ups.id === hardwareTypeId)[0];
        this.manageHardware(existingUps, hardwareTypeAmount, this.event.requestedUps, upsRequest);
        break;
      default: // phones & tablets
        const existingDeviceType = this.event.requestedDevices.filter(device => device.deviceTypes.id === hardwareTypeId)[0];
        const deviceRequest = new RequestedDevice();
        deviceRequest.quantity = hardwareTypeAmount;
        deviceRequest.requestedAt = moment().format('YYYY-MM-DD HH:mm:ss');
        deviceRequest.deviceTypes = this._deviceTypesArray.filter(deviceType => deviceType.id === hardwareTypeId)[0];
        this.manageHardware(existingDeviceType, hardwareTypeAmount, this.event.requestedDevices, deviceRequest);
        break;
    }
    this.calculateEventPrice();
  }

  manageHardware(existingHardwareType: any, hardwareTypeAmount: number, requestedHardwareTypeArray: any, requestedHardwareTypeObject: any) {
    if (hardwareTypeAmount > 0) {
      (existingHardwareType ? existingHardwareType.quantity = hardwareTypeAmount :
        requestedHardwareTypeArray.push(requestedHardwareTypeObject));
    } else {
      requestedHardwareTypeArray.splice(requestedHardwareTypeArray.indexOf(existingHardwareType), 1);
    }
  }

  submitEventRequest() {

    console.log(this.event);

    this.event.village = this._villagArray.filter(village => village.id.toString() === this.choosenVillage.value)[0];
    if (this.event.eventName === '' || this.event.eventName === undefined) {
      this._toastr.error(this._translate.instant('request-event-modal.missing-event-name-error'));
      return;
    }

    if (!this._selectedClient) {
      this._toastr.error(this._translate.instant('request-event-modal.missing-company-error'));
      return;
    }

    if (!this.event.village) {
      this._toastr.error('Please select the event village');
      return;
    }

    this.event.startDate = moment(this.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.event.endDate = moment(this.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.event.pickUpDate = moment(this.pickUpDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.event.returnDate = moment(this.returnDate, 'DD-MM-YYYY').format('YYYY-MM-DD');

    alert('Eccomi');

    return;

    this._eventsService.insertEventRequest(this.event).subscribe(result => {
      this._toastr.success(this._translate.instant('request-event-modal.event-added-success'));
      this._router.navigate(['/events/details/' + result.id.toString()]);
    }, error => {
      console.log(error);
    });
  }

  onDateChange(dateInputType: string) {
    if (dateInputType === 'startDate') {
      this.pickUpDate = moment(this.startDate, 'DD-MM-YYYY').subtract(1, 'day').format('DD-MM-YYYY');
    } else {
      this.returnDate = moment(this.endDate, 'DD-MM-YYYY').add(1, 'day').format('DD-MM-YYYY');
    }
    this.calculateEventPrice();
  }

  calculateEventPrice() {
    if (this._selectedClient) {
      this._sharedService.calculateEventPrice(this.event, this.startDate, this.endDate, false).subscribe(result => {
        this.eventPrice = result;
      });
    }
  }
}

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../shared/events.service';
import { Event } from 'src/app/shared/models/Event';
import { SearchObjectResult } from '../../shared/models/SearchObject';
import { Observable } from 'rxjs';
import { EventSearchCriteria } from '../shared/event-search-criteria.model';
import { DataTableColumn } from '../../shared/data-table/data-table.component';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/shared.service';
import { Option } from 'src/app/shared/select/select.component';
import { Village } from 'src/app/shared/models/Village';
import { LanguageEnum } from 'src/app/shared/enums/LanguageEnum';
import { RequestedRouterType } from 'src/app/shared/models/RequestedRouterType';
import { Router } from 'src/app/shared/models/Router';
import { RequestedSoftwareType } from 'src/app/shared/models/RequestedSoftwareType';
import { DeviceUser } from 'src/app/shared/models/DeviceUser';

@Component({
  selector: 'app-all-events',
  templateUrl: './all-events.component.html',
  styleUrls: ['./all-events.component.css']
})
export class AllEventsComponent implements OnInit {

  public eventToUpdate: Event = new Event();
  public currentStatus: number = undefined;
  public hideEditEventContainer = true;
  public hideEditBody = true;
  public villageOptions: Option[] = [];
  public eventVillage = undefined;

  public clientLanguageArray: Option[] = [
    { value: 'de', label: LanguageEnum.DE },
    { value: 'it', label: LanguageEnum.IT },
    { value: 'en', label: LanguageEnum.EN },
  ];

  public tBodyTrAdditionalClass = 'alternateBgColor';

  private _villageObjects: Village[];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;
  filters: EventSearchCriteria = {};

  @ViewChild('editColumn')
  editColumn: TemplateRef<any>;

  @ViewChild('idColumn')
  idColumn: TemplateRef<any>;

  @ViewChild('eventNameColumn')
  eventNameColumn: TemplateRef<any>;

  @ViewChild('startDateColumn')
  startDateColumn: TemplateRef<any>;

  @ViewChild('endDateColumn')
  endDateColumn: TemplateRef<any>;

  @ViewChild('pickUpDateColumn')
  pickUpDateColumn: TemplateRef<any>;

  @ViewChild('returnDateColumn')
  returnDateColumn: TemplateRef<any>;

  @ViewChild('villageColumn')
  villageColumn: TemplateRef<any>;

  // @ViewChild('customerNameColumn')
  // customerNameColumn: TemplateRef<any>;

  @ViewChild('companyColumn')
  companyColumn: TemplateRef<any>;

  @ViewChild('statusColumn')
  statusColumn: TemplateRef<any>;

  @ViewChild('onlineColumn')
  onlineColumn: TemplateRef<any>;

  @ViewChild('hasDeviceUsersColumn')
  hasDeviceUsersColumn: TemplateRef<any>;

  @ViewChild('hasPriceListColumn')
  hasPriceListColumn: TemplateRef<any>;

  @ViewChild('hasPrintersColumn')
  hasPrintersColumn: TemplateRef<any>;

  @ViewChild('hasRoutersColumn')
  hasRoutersColumn: TemplateRef<any>;

  @ViewChild('hasDevicesColumn')
  hasDevicesColumn: TemplateRef<any>;

  @ViewChild('actionsColumn')
  actionsColumn: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private _route: ActivatedRoute, private _eventsService: EventsService, private _toastr: ToastrService,
    private _sharedService: SharedService) {
  }

  ngOnInit() {
    this._route.queryParamMap.subscribe(params => {
      if (params.get('status')) {
        this.currentStatus = Number(params.get('status'));
        this.filters.status = params.get('status');
      }
      this.loadPage = (page: number, pageSize: number) => this._eventsService.search(page, pageSize, this.filters);
      console.log('filters:', this.filters);
      this._sharedService.getAllVillages().subscribe(result => {
        this._villageObjects = result;
        result.forEach(village => {
          this.villageOptions.push({ value: village.id, label: village.name });
        });
        this.villageOptions = [...this.villageOptions];
      });
    });
    this.columns = [
      {
        name: 'id',
        displayed: true,
        translationKey: 'Id',
        template: this.idColumn,
        sortable: true,
        additionalClass: 'red-bg'
      },
      {
        name: 'eventName',
        displayed: true,
        translationKey: 'Event Name',
        template: this.eventNameColumn,
        sortable: true
      },
      {
        name: 'startDate',
        displayed: true,
        translationKey: 'Start Date',
        template: this.startDateColumn,
        sortable: true
      },
      {
        name: 'endDate',
        displayed: true,
        translationKey: 'End Date',
        template: this.endDateColumn,
        sortable: true
      },
      {
        name: 'pickUpDate',
        displayed: true,
        translationKey: 'Pick up Date',
        template: this.pickUpDateColumn,
        sortable: true
      },
      {
        name: 'returnDate',
        displayed: true,
        translationKey: 'Return Date',
        template: this.returnDateColumn,
        sortable: true
      },
      {
        name: 'village',
        displayed: true,
        translationKey: 'Village',
        template: this.villageColumn,
        sortable: true
      },
      // {
      //   name: 'customerNameColumn',
      //   displayed: true,
      //   translationKey: 'Customer Name',
      //   template: this.customerNameColumn,
      //   sortable: true
      // },
      {
        name: 'company',
        displayed: true,
        translationKey: 'Organization',
        template: this.companyColumn,
        sortable: true
      },
      {
        name: 'status',
        displayed: true,
        translationKey: 'Status',
        template: this.statusColumn,
        sortable: true
      },
      {
        name: 'onlineStatus',
        displayed: true,
        translationKey: 'Online',
        template: this.onlineColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'hasDeviceUser',
        displayed: true,
        translationKey: 'Device users',
        template: this.hasDeviceUsersColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'hasPriceList',
        displayed: true,
        translationKey: 'Pricelist',
        template: this.hasPriceListColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'hasPrinters',
        displayed: true,
        translationKey: 'Printers',
        template: this.hasPrintersColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'hasRouter',
        displayed: true,
        translationKey: 'Routers',
        template: this.hasRoutersColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'hasDevices',
        displayed: true,
        translationKey: 'Devices',
        template: this.hasDevicesColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actionsColumn,
        sortable: true,
        additionalClass: 'text-right',
        trAddidionalClass: 'text-right'
      },
    ];
  }

  onTableReset() {
    if (this.currentStatus) {
      this.filters.status = this.currentStatus.toString();
    }
  }

  onUpdateEventButtonClick(eventId: string, target: HTMLElement) {
    this.hideEditEventContainer = false;
    target.scrollIntoView();
    this._eventsService.getEventById(eventId).subscribe(result => {
      this.eventToUpdate = result;
      this.hideEditBody = false;
    });
  }

  onEditCancelButtonClick() {
    this.eventToUpdate = new Event();
    this.hideEditEventContainer = true;
    this.hideEditBody = true;
  }

  onEditButtonClick() {
    if (this.eventToUpdate.eventName !== '') {
      console.log(this.eventVillage);
      if (this.eventVillage) {
        this.eventToUpdate.village = this._villageObjects.filter(village => village.id === this.eventVillage.value)[0];
      }
      this._eventsService.updateEvent(this.eventToUpdate).subscribe(result => {
        this._toastr.success('Event successfully updated');
        this.onEditCancelButtonClick();
        this.loadPage = (page: number, pageSize: number) => this._eventsService.search(page, pageSize, this.filters);
      }, error => {
        console.log(error);
      });
    }
  }

  checkRequestedPrinterQuantity(requestedSoftwareTypes: any, associatedPrinter: any): boolean {
    let requestedQuantity = 0;
    requestedSoftwareTypes.map(function (req) {
      if (req.softwareType.id === 4) {
        requestedQuantity += req.quantity;
      }
    });
    return (requestedQuantity === associatedPrinter.length ? true : false);
  }

  checkRequestedRouterQuantity(requestedRouters: RequestedRouterType[], associatedRouters: Router[]): boolean {
    let requestedQuantity = 0;
    requestedRouters.map(req => requestedQuantity += req.quantity);
    return (requestedQuantity === associatedRouters.length ? true : false);
  }

  checkRequestedDeviceUsersQuantity(requestedSoftwareTypes: RequestedSoftwareType[], deviceUsers: DeviceUser[]): boolean {
    let requestedQuantity = 0;
    requestedSoftwareTypes.map(function (req) {
      if (req.softwareType.id !== 4) {
        requestedQuantity += req.quantity;
      }
    });
    return (requestedQuantity === deviceUsers.length ? true : false);
  }

  checkDevicesOfDeviceUsers(deviceUsers: DeviceUser[]): boolean {
    if (deviceUsers.length === 0) {
      return false;
    }
    let hasDevices = true;
    deviceUsers.map(function (dev) {
      if (!dev.device) {
        hasDevices = false;
      }
    });
    return hasDevices;
  }

  changeOnlinePublish(newStatus: boolean, eventId: string) {
    this._eventsService.publishEventOnline(newStatus.toString(), eventId).subscribe(result => {
      this.loadPage = (page: number, pageSize: number) => this._eventsService.search(page, pageSize, this.filters);
    });
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';

@Component({
  selector: 'app-ng-form-group-input',
  templateUrl: './ng-form-group-input.component.html',
  styleUrls: ['./ng-form-group-input.component.css']
})
export class NgFormGroupInputComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  inputType: string;

  @Input()
  additionalClass: string;

  @Input()
  placeHolder: string;

  @Input()
  model: string;

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  name: string;

  @Input()
  id: string;

  @Input()
  error: string;

  @Input()
  formControlNameValue: string;

  @Input()
  formGroup: FormGroup;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  change() {
    this.modelChange.emit(this.model);
  }

}

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { DEVICES_ROUTES } from './devices.routes';
import { DevicesComponent } from './devices.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DevicesListComponent } from './devices-list/devices-list.component';
import { DevicesUpdateComponent } from './devices-update/devices-update.component';
import { DeviceManufacturerUpdateComponent } from './device-manufacturer-update/device-manufacturer-update.component';




@NgModule({
    imports: [
        RouterModule.forChild(DEVICES_ROUTES),
        SharedModule,
        NgSelectModule
    ],
    declarations: [
        DevicesComponent,
        DevicesListComponent,
        DevicesUpdateComponent,
        DeviceManufacturerUpdateComponent,
    ],
    providers: [

      ]
})
export class DevicesModule { }
import { Routes } from '@angular/router';
import { OffersComponent } from './offers.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { OffersListComponent } from './offers-list/offers-list.component';
import { CreateOfferComponent } from './create-offer/create-offer.component';
import { OfferDetailsComponent } from './offer-details/offer-details.component';

export const OFFERS_ROUTES: Routes = [
    {
        path: 'offers', component: OffersComponent, canActivate: [AuthGuard], children: [
            { path: '', component: OffersListComponent, canActivate: [AuthGuard] },
            { path: 'create', component: CreateOfferComponent, canActivate: [AuthGuard] },
            { path: 'details/:offerId', component: OfferDetailsComponent, canActivate: [AuthGuard] }
        ]
    }
];

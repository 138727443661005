import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {NavigationService} from '../../shared/navigation.service';
import {NgForm} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {


  constructor(private toastr: ToastrService, private authService: AuthService, private navigationService: NavigationService) { }

  ngOnInit() {
  }
  onResetPassword(form: NgForm) {
    const recoveryCode = form.value.code;
    const password = form.value.password;
    const confirmPassword = form.value.confirm_password;
    this.authService.resetPassword(recoveryCode , password).subscribe(data => {
      if (data) {
        this.toastr.success('Your password has been reset successfully!!');
        this.redirectToSignin();
      }
    }, err => {
      console.log(err);
    });
  }

  redirectToSignin() {
    this.navigationService.login().go();
  }
}

import { Event } from './Event';
import { Cable } from './Cable';

export class RequestedCable {
    public id: number;
    public quantity: number;
    public parentRequestId: number;
    public requestedAt: string;
    public event: Event;
    public cables: Cable;

    static fromDto(r): RequestedCable {
        const requestedCable = new RequestedCable();

        if (!r) {
            return null;
        }

        requestedCable.id = r.id;
        requestedCable.quantity = r.quantity;
        requestedCable.parentRequestId = r.parentRequestId;
        requestedCable.requestedAt = r.requestedAt;
        requestedCable.event = Event.fromDto(r.event);
        requestedCable.cables = Cable.fromDto(r.cables);

        return requestedCable;
    }
}

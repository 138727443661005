import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from 'src/app/clients/shared/clients.service';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../models/SearchObject';
import { ClientSearchCriteria } from 'src/app/clients/shared/client-search-criteria.model';
import { Client } from '../../models/Client';
import { DataTableColumn } from '../../data-table/data-table.component';
import { Company } from '../../models/Company';

@Component({
  selector: 'app-company-select-modal',
  templateUrl: './company-select-modal.component.html',
  styleUrls: ['./company-select-modal.component.css']
})
export class CompanySelectModalComponent implements OnInit, OnChanges {

  public companyName = '';
  private _modal: NgbModalRef;

  @Input()
  showLabel = true;

  @Input()
  companyObject: Company;

  @Output()
  companySelected: EventEmitter<Company> = new EventEmitter<Company>();

  constructor(private _modalService: NgbModal, modalConfig: NgbModalConfig, private _clientsService: ClientsService) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
  }

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Client>> = null;
  filters: ClientSearchCriteria = {};
  columns: DataTableColumn[];

  @ViewChild('idColumn')
  idColumn: TemplateRef<any>;

  @ViewChild('firstNameColumn')
  firstNameColumn: TemplateRef<any>;

  @ViewChild('lastNameColumn')
  lastNameColumn: TemplateRef<any>;
  @ViewChild('companyColumn')
  companyColumn: TemplateRef<any>;

  @ViewChild('addressColumn')
  addressColumn: TemplateRef<any>;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('COMPANY MODAL CHANGE');
    console.log(changes);
    if (changes.companyObject) {
      if (changes.companyObject.currentValue) {
        this.companyName = Company.fromDto(changes.companyObject.currentValue).name;
      }
    }
  }

  openModal(content) {
    this._modal = content;
    this.columns = [
      {
        name: 'id',
        displayed: true,
        translationKey: 'client-list.id.label',
        template: this.idColumn,
        sortable: true
      },
      {
        name: 'company',
        displayed: true,
        translationKey: 'client-list.company.label',
        template: this.companyColumn,
        sortable: true
      },
      {
        name: 'firstName',
        displayed: true,
        translationKey: 'client-list.firstName.label',
        template: this.firstNameColumn,
        sortable: true
      },
      {
        name: 'lastName',
        displayed: true,
        translationKey: 'client-list.lastName.label',
        template: this.lastNameColumn,
        sortable: true
      },
      {
        name: 'address',
        displayed: true,
        translationKey: 'client-list.address.label',
        template: this.addressColumn,
        sortable: true
      }
    ];
    this._modal = this._modalService.open(content, { size: 'lg', windowClass: 'company-select-modal' });
    this.loadPage = (page: number, pageSize: number) => this._clientsService.search(page, pageSize, this.filters);
  }


  onTableRowClickEvent(item: Client) {
    console.log(item);
    const company = Company.fromDto(item.company);
    this.companyName = company.name;
    this.companySelected.emit(company);
    // this._modalService.dismissAll();
    // this.activeModal
    this._modal.close();
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'endDate'
})
export class EndDatePipe implements PipeTransform {

  transform(value: string, format: string): string {
    return moment(value).subtract(1, 'day').format(format);
  }

}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-form-group-number-input-validation',
  templateUrl: './form-group-number-input-validation.component.html',
  styleUrls: ['./form-group-number-input-validation.component.css']
})
export class FormGroupNumberInputValidationComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  formControlNameValue: string;

  @Input()
  formGroupNameValue = undefined;

  @Input()
  formGroup: FormGroup;

  @Input()
  name: string;

  @Input()
  id: string;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  changeValueClickEvent(negative) {
    const currentValue = this.formGroup.get([this.formGroupNameValue, this.formControlNameValue]).value;
    if (negative) {
      if (currentValue >= 1) {
        this.formGroup.get([this.formGroupNameValue, this.formControlNameValue]).setValue(currentValue - 1);
      }
    } else {
      this.formGroup.get([this.formGroupNameValue, this.formControlNameValue]).setValue(currentValue + 1);
    }
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-group-pwd-generator',
  templateUrl: './form-group-pwd-generator.component.html',
  styleUrls: ['./form-group-pwd-generator.component.css']
})
export class FormGroupPwdGeneratorComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  buttonLabel: string;

  @Input()
  model: string;

  @Input()
  pwdLength: number;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.pwdLength = 8;
  }

  ngOnInit() {
  }

  generatePassword() {
    this.model = this.makeid();
    this.change();
  }

  makeid() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < this.pwdLength; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  change() {
    this.modelChange.emit(this.model);
  }

}

import { Injectable } from '@angular/core';
import { HttpUtils } from 'src/app/shared/http-utils';
import { HttpClient } from '@angular/common/http';
import { Company } from 'src/app/shared/models/Company';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { Observable } from 'rxjs';
import { URL_BASE } from 'src/app/core/constants';
import { map } from 'rxjs/operators';
import { CompaniesSearchCriteria } from './CompaniesSearchCriteria';
import * as moment from 'moment';
import { Printer } from 'src/app/shared/models/Printer';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private _http: HttpClient) { }

  /**
   * get all the companies from the database
   * ERROR 600 -> No companies in the database
   */
  public search(page: number, size: number, criteria: CompaniesSearchCriteria): Observable<SearchObjectResult<Company>> {

    const criteriaCopy = { ...criteria };
    if (criteriaCopy.creationDate) {
      criteriaCopy.creationDate = moment(criteriaCopy.creationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    console.log(params);
    return this._http.get(URL_BASE + '/admin/company/search', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, Company.fromDto);
      })
    );
  }

  /**
   * getCompanyInformationsById
   */
  public getCompanyInformationsById(companyId: string): Observable<Company> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/company/get', { headers: headers, params: { id: companyId } }).pipe(
      map((res: Company) => {
        return res;
      }));
  }

  /**
   * updateComapny
   */
  public updateComapny(company: Company): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/company/update', { company }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * insertNewCompany
   */
  public insertNewCompany(company: Company): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/company/insert', { company }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * hardDeleteCompany
   */
  public hardDeleteCompany(companyId: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.request('delete', URL_BASE + '/admin/company/hardDelete', { headers, body: { companyId } }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  public unAssignPrinterFromCompanyById(companyId: string, printers: Printer[]): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/company/unAssignPrinter', { companyPrinter: { companyId, printers } }, { headers }).pipe(
      map((res: any) => res));
  }


  public assignPrinterToCompanyByIds(companyId: string, printers: Printer[]): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/company/assignPrinter',  { companyId, printers }, { headers }).pipe(
      map((res: any) => res));
  }

    /**
   * getAllPrinters
   */
  public getAllPrinters(): Observable<Printer[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/printer/get', { headers: headers }).pipe(
      map((result: any) => result));

  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpUtils } from 'src/app/shared/http-utils';
import { URL_BASE } from 'src/app/core/constants';
import { PriceList } from 'src/app/shared/models/PriceList';
import { Product } from 'src/app/shared/models/Product';
import { ProductOption } from 'src/app/shared/models/ProductOption';
import { PriceListSearchCriteria } from './price-list-search-criteria.model';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { SearchProduct } from 'src/app/shared/models/SearchProduct';
import { Event } from 'src/app/shared/models/Event';
import * as PriceListSearch from './PriceListSearchCriteria';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PriceListService {
  private _headers: any;

  constructor(private _http: HttpClient) {

    this._headers = HttpUtils.createHeaders();
  }

  /**
   * get all the companies from the database
   * ERROR 600 -> No companies in the database
   */
  public searchPriceLists(page: number, size: number, criteria: PriceListSearch.PriceListSearchCriteria):
    Observable<SearchObjectResult<PriceList>> {

    const criteriaCopy = { ...criteria };
    if (criteriaCopy.creationDate) {
      criteriaCopy.creationDate = moment(criteriaCopy.creationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(URL_BASE + '/admin/priceList/search', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, PriceList.fromDto);
      })
    );
  }


  public gatAllPriceLists(): Observable<PriceList[]> {
    return this._http.get(URL_BASE + '/admin/priceList/get', { headers: this._headers }).pipe(
      map((result: any[]) => result.map(res => PriceList.fromDto(res)))
    );
  }
  /**
   * insertNewPriceList
   */
  public insertNewPriceList(priceListName: string, companyId: number): Observable<PriceList> {
    return this._http.put(URL_BASE + '/admin/priceList/insert', { name: priceListName, companyId: companyId.toString() },
      { headers: this._headers }).pipe(
        map((res: any) => PriceList.fromDto(res)));
  }

  /**
   *
   *
   */

  public insertProductCategory(productCategory: ProductCategory): Observable<ProductCategory> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/productCategory/insert', { productCategory: productCategory }, { headers }).pipe(
      map((res: any) => {
        console.log('|| INSERET PRODUCT CATEGORY RESULT ||' + res);
        return ProductCategory.fromDto(res);
      })
    );
  }

  public getAllCategoriesByPriceListId(priceListId: string): Observable<ProductCategory[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/productCategories/get', { headers: headers, params: { id: priceListId } }).pipe(
      map((result: any[]) => result.map(res => ProductCategory.fromDto(res))));
  }

  public addNewProduct(product: Product): Observable<Product> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/product/insert', { product }, { headers }).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }));
  }

  public getProductsByCategoryId(categoryId: string): Observable<Product[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/product/get', { headers: headers, params: { id: categoryId } }).pipe(
      map((result: any[]) => result.map(res => Product.fromDto(res))));
  }

  public getProductById(productId: string): Observable<Product> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/product/getProduct', { headers: headers, params: { id: productId } }).pipe(
      map((res: any) => {
        return Product.fromDto(res);
      }));
  }

  public addNewProductOption(productOption: ProductOption): Observable<ProductOption> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/productOption/insert', { productOption }, { headers }).pipe(
      map((res: any) => {
        console.log(res);
        return ProductOption.fromDto(res);
      }));
  }

  public gatPriceListsById(priceListId: string): Observable<PriceList> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/priceList/get', { headers: headers, params: { id: priceListId } }).pipe(
      map((res: any) => PriceList.fromDto(res)));
  }

  public getPriceListsByEventId(eventId: string): Observable<PriceList[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/priceList/getByEventId', { headers: headers, params: { eventId } }).pipe(
      map((result: any[]) => result.map(res => PriceList.fromDto(res))));
  }

  public search(page: number, size: number, criteria: PriceListSearchCriteria, priceListId: number):
    Observable<SearchObjectResult<SearchProduct>> {
    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      priceListId: String(priceListId),
      ...criteriaCopy
    };
    return this._http.get(URL_BASE + '/admin/priceList/searchProducts', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, SearchProduct.fromDto);
      })
    );
  }

  /**
   * getParentPriceLists
   */
  public getParentPriceLists(clientId: number): Observable<PriceList[]> {
    return this._http.get(URL_BASE + '/admin/priceList/getParentPriceLists', {
      headers: this._headers,
      params: { clientId: clientId.toString() }
    }).pipe(
      map((result: any[]) => result.map(res => PriceList.fromDto(res)))
    );
  }

  /**
   * getPriceList
   */
  public getPriceList(priceListId: number, clientId: number): Observable<PriceList> {
    return this._http.get(URL_BASE + '/admin/priceList/get', {
      headers: this._headers,
      params: { id: priceListId.toString(), clientId: clientId.toString() }
    }).pipe(
      map((result: any[]) => PriceList.fromDto(result))
    );
  }

  /**
   * uploadNewPriceListToEvent
   */
  public uploadNewPriceListToEvent(fileToUpload: File, companyId: string, priceListName: string, eventId?: string): Observable<any> {
    console.log({ companyId, 'file': fileToUpload, priceListName, eventId });
    const formData = new FormData();
    formData.append('companyId', companyId);
    formData.append('eventId', eventId);
    formData.append('priceListName', priceListName);
    formData.append('file', fileToUpload, fileToUpload.name);
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/admin/priceList/upload', formData, { headers }).pipe(
      map((res: any) => res));
  }

  /**
   * duplicatePriceList
   */
  public duplicatePriceList(priceListId: number, companyId: number): Observable<PriceList> {
    return this._http.post(URL_BASE + '/admin/priceList/duplicate', { priceListId, companyId }, { headers: this._headers }).pipe(
      map((res: any) => PriceList.fromDto(res)));
  }

  /**
   * deletePriceList
   */
  public deletePriceList(priceListId: number): Observable<PriceList> {
    return this._http.delete(URL_BASE + '/admin/priceList/delete', {
      headers: this._headers,
      params: { priceListId: priceListId.toString() }
    }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * updateProductCategory
   */
  public updateProductCategory(productCategory: ProductCategory): Observable<ProductCategory> {
    return this._http.put(URL_BASE + '/admin/productCategory/update', { productCategoryObject: productCategory },
      { headers: this._headers }).pipe(
        map((res: any) => ProductCategory.fromDto(res)));
  }

  /**
   * insertNewProductCategory
   */
  public insertNewProductCategory(productCategory: ProductCategory): Observable<ProductCategory> {
    return this._http.put(URL_BASE + '/admin/productCategory/insert', { productCategory: productCategory },
      { headers: this._headers }).pipe(
        map((res: any) => ProductCategory.fromDto(res)));
  }

  /**
   * updatePriceList
   */
  public updatePriceList(id: number, name: string, companyId: number): Observable<PriceList> {
    return this._http.put(URL_BASE + '/admin/priceList/update', { id, name, companyId }, { headers: this._headers }).pipe(
      map((res: any) => PriceList.fromDto(res)));
  }

  /**
   * changeProductCategoryPosition
   */
  public changeProductCategoryPosition(categoryId: number, itemOrder: number, priceListId: number): Observable<ProductCategory[]> {
    return this._http.put(URL_BASE + '/admin/productCategory/changeItemOrder', { categoryId, itemOrder, priceListId },
      { headers: this._headers }).pipe(
        map((result: any[]) => result.map(res => ProductCategory.fromDto(res)))
      );
  }

  /**
   * deleteProductCategory
   */
  public deleteProductCategory(categoryId: number): Observable<ProductCategory[]> {
    return this._http.delete(URL_BASE + '/admin/productCategory/delete', {
      headers: this._headers,
      params: { id: categoryId.toString(), force: '1' }
    }).pipe(
      map((result: any[]) => result.map(res => ProductCategory.fromDto(res)))
    );
  }

  /**
   * duplicateProductCategory
   */
  public duplicateProductCategory(categoryId: number, priceListId: number): Observable<ProductCategory[]> {
    return this._http.put(URL_BASE + '/admin/productCategory/duplicate', { categoryId, priceListId },
      { headers: this._headers }).pipe(
        map((result: any[]) => result.map(res => ProductCategory.fromDto(res)))
      );
  }

  /**
   * duplicateProduct
   */
  public duplicateProduct(productId: number): Observable<ProductCategory> {
    return this._http.put(URL_BASE + '/admin/product/duplicate', { productId },
      { headers: this._headers }).pipe(
        map((res: any) => ProductCategory.fromDto(res)));
  }

  /**
   * insertNewProduct
   */
  public insertNewProduct(product: Product): Observable<ProductCategory[]> {
    return this._http.put(URL_BASE + '/admin/product/insert', { product },
      { headers: this._headers }).pipe(
        map((result: any[]) => result.map(res => ProductCategory.fromDto(res)))
      );
  }

  /**
   * updateProduct
   */
  public updateProduct(product: Product): Observable<ProductCategory[]> {
    return this._http.put(URL_BASE + '/admin/product/update', { product },
      { headers: this._headers }).pipe(
        map((result: any[]) => result.map(res => ProductCategory.fromDto(res)))
      );
  }

  /**
   * deleteProduct
   */
  public deleteProduct(productId: number): Observable<ProductCategory> {
    return this._http.delete(URL_BASE + '/admin/product/delete', {
      headers: this._headers, params: { id: productId.toString(), force: '1' }
    }).pipe(
      map((res: any) => ProductCategory.fromDto(res)));
  }

  /**
   * changeProductPosition
   */
  public changeProductPosition(productId: number, itemOrder: number, categoryId: number): Observable<ProductCategory> {
    return this._http.put(URL_BASE + '/admin/product/changeItemOrder', { productId, itemOrder, categoryId },
      { headers: this._headers }).pipe(
        map((res: any) => ProductCategory.fromDto(res)));
  }

  /**
   * insertNewProductOption
   */
  public insertNewProductOption(productOption: ProductOption): Observable<Product> {
    return this._http.put(URL_BASE + '/admin/productOption/insert', { productOption },
      { headers: this._headers }).pipe(
        map((res: any) => Product.fromDto(res)));
  }

  /**
   * updateProductOption
   */
  public updateProductOption(productOption: ProductOption): Observable<Product> {
    return this._http.put(URL_BASE + '/admin/productOption/update', { productOption },
      { headers: this._headers }).pipe(
        map((res: any) => Product.fromDto(res)));
  }

  /**
   * duplicateProductOption
   */
  public duplicateProductOption(productOptionId: number): Observable<Product> {
    return this._http.put(URL_BASE + '/admin/productOption/duplicate', { productOptionId },
      { headers: this._headers }).pipe(
        map((res: any) => Product.fromDto(res)));
  }

  /**
   * deleteProductOption
   */
  public deleteProductOption(productOptionId: number): Observable<any> {
    return this._http.delete(URL_BASE + '/admin/productOption/delete', {
      headers: this._headers, params: { id: productOptionId.toString(), force: '1' }
    }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * changeProductOptionItemOrder
   */
  public changeProductOptionItemOrder(productOptionId: number, itemOrder: number, productId: number): Observable<Product> {
    return this._http.put(URL_BASE + '/admin/productOption/changeItemOrder', { productOptionId, itemOrder, productId },
      { headers: this._headers }).pipe(
        map((res: any) => Product.fromDto(res)));
  }

  /**
   * getUnAssignedApprovedEvents
   */
  public getUnAssignedApprovedEvents(companyId: number): Observable<Event[]> {
    return this._http.get(URL_BASE + '/admin/event/getUnAssignedApprovedEvents', {
      headers: this._headers,
      params: { companyId: companyId.toString() }
    }).pipe(
      map((result: any[]) => result.map(res => Event.fromDto(res)))
    );
  }

  /**
   * assignPriceListToEvent
   */
  public assignPriceListToEvent(priceListId: number, eventId: number): Observable<PriceList> {
    return this._http.put(URL_BASE + '/admin/priceList/assignPriceList', { priceListId, eventId },
      { headers: this._headers }).pipe(
        map((res: any) => PriceList.fromDto(res)));
  }

  /**
   * duplicateMultipleProducts
   */
  public duplicateMultipleProducts(products: Array<string>): Observable<ProductCategory[]> {
    return this._http.put(URL_BASE + '/admin/product/duplicates', { products },
      { headers: this._headers }).pipe(
        map((result: any[]) => result.map(res => ProductCategory.fromDto(res)))
      );
  }

  /**
   * deleteMultipleProducts
   */
  public deleteMultipleProducts(products: Array<string>): Observable<ProductCategory[]> {
    return this._http.request('delete', URL_BASE + '/admin/product/deletes', {
      headers: this._headers,
      body: { 'products': { ids: products, force: 1 } }
    }).pipe(
      map((result: any[]) => result.map(res => ProductCategory.fromDto(res)))
    );
  }

  /**
   * changeMultipleProductsCategory
   */
  public changeMultipleProductsCategory(products: Array<string>, productCategory: ProductCategory): Observable<ProductCategory[]> {
    return this._http.put(URL_BASE + '/admin/product/changeProductsCategory', { 'products': { ids: products, productCategory } },
      { headers: this._headers }).pipe(
        map((result: any[]) => result.map(res => ProductCategory.fromDto(res)))
      );
  }

  /**
   * changeMultipleProductsCategory
   */
  public addMultipleOptionsToMultipleProducts(productIds: Array<string>, productOptions: ProductOption[]): Observable<ProductOption> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/productOption/addMultipleOptions', { productIds, productOptions }, { headers }).pipe(
      map((res: any) => {
        return ProductOption.fromDto(res);
      }));
  }

  /**
   * exportPriceList
   */
  public exportPriceList(priceListId: number, language: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/admin/priceList/export', { id: priceListId, language }, {
      responseType: 'blob',
      headers: headers
    });
  }


}

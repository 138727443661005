import { Component, OnInit } from '@angular/core';
import { Village } from 'src/app/shared/models/Village';
import { Province } from 'src/app/shared/models/Province';
import { Region } from 'src/app/shared/models/Region';
import { Country } from 'src/app/shared/models/Country';
import { SharedService } from 'src/app/shared/shared.service';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {

  public villagesList: Village[] = [];
  public provincesList: Province[] = [];
  public regionsList: Region[] = [];
  public countriesList: Country[] = [];
  public provincesOptions: Option[] = [];
  public regionsOptions: Option[] = [];
  public countriesOptions: Option[] = [];

  constructor(private _sharedService: SharedService) {
  }

  ngOnInit() {
    forkJoin([
      this._sharedService.getAllVillages(),
      this._sharedService.getAllProvinces(),
      this._sharedService.getAllRegions(),
      this._sharedService.getAllCountries()
    ]).subscribe(result => {
      this.villagesList = result[0];
      this.provincesList = result[1];
      this.provincesList.forEach(province => {
        this.provincesOptions.push({ value: province.id.toString(), label: province.name });
      });
      this.regionsList = result[2];
      this.regionsList.forEach(region => {
        this.regionsOptions.push({ value: region.id.toString(), label: region.name });
      });
      this.countriesList = result[3];
      this.countriesList.forEach(country => {
        this.countriesOptions.push({ value: country.id.toString(), label: country.name });
      });
    });
  }
}

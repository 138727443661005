import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { NavigationService } from 'src/app/shared/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private _authService: AuthService, private navigationService: NavigationService, private _route: ActivatedRoute,
    private _toastr: ToastrService) {
    this._route.queryParams.subscribe(params => {
      if (params['activateCode']) {
        this._authService.activateClientUser(params['activateCode']).subscribe(result => {
          this._toastr.success('Client user activated successfully!');
          this.navigationService.home();
        }, error => {
          console.log(error);
        });
      } else {
        this.navigationService.home();
      }
    });
  }

  ngOnInit() {
  }

}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModalRef, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Client } from '../../models/Client';
import { ClientsService } from 'src/app/clients/shared/clients.service';
import { ClientSearchCriteria } from 'src/app/clients/shared/client-search-criteria.model';
import { DataTableColumn } from '../../data-table/data-table.component';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../models/SearchObject';
import { Company } from '../../models/Company';

@Component({
  selector: 'app-client-select-modal',
  templateUrl: './client-select-modal.component.html',
  styleUrls: ['./client-select-modal.component.css']
})
export class ClientSelectModalComponent implements OnInit, OnChanges {

  public clientName = '';
  private _modal: NgbModalRef;

  @Input()
  clientObject: Client;

  @Input()
  showLabel = true;

  @Input()
  labelText = 'Search Company *';

  @Output()
  clientSelected: EventEmitter<Client> = new EventEmitter<Client>();

  @Output()
  companySelected: EventEmitter<Company> = new EventEmitter<Company>();

  constructor(private _modalService: NgbModal, modalConfig: NgbModalConfig, private _clientsService: ClientsService) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
  }

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Client>> = null;
  filters: ClientSearchCriteria = {};
  columns: DataTableColumn[];

  @ViewChild('customerName')
  customerName: TemplateRef<any>;

  @ViewChild('clientStatusColumn')
  clientStatusColumn: TemplateRef<any>;

  @ViewChild('companyColumn')
  companyColumn: TemplateRef<any>;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes.clientObject.currentValue) {
      const client = Client.fromDto(changes.clientObject.currentValue);
      this.clientName = (client.firstName ? client.firstName : '') + ' ' + (client.lastName ? client.lastName : '');
    } else {
      this.clientName = '';
    }
  }

  openModal(content) {
    this._modal = content;
    this.columns = [
      {
        name: 'customerName',
        displayed: true,
        translationKey: 'Customer name',
        template: this.customerName,
        sortable: true
      },
      {
        name: 'clientStatusColumn',
        displayed: true,
        translationKey: 'Client status',
        template: this.clientStatusColumn,
        sortable: true
      },
      {
        name: 'company',
        displayed: true,
        translationKey: 'client-list.company.label',
        template: this.companyColumn,
        sortable: true
      }
    ];
    this._modal = this._modalService.open(content, { size: 'lg', windowClass: 'company-select-modal' });
    this._modal.result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
    this.loadPage = (page: number, pageSize: number) => this._clientsService.search(page, pageSize, this.filters);
  }

  onTableRowClickEvent(item: Client) {
    console.log(item);
    const client = Client.fromDto(item);
    this.clientName = client.company.name;
    this.clientSelected.emit(client);
    this.companySelected.emit(client.company);
    this._modal.close();
  }

}

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PriceListService } from 'src/app/price-lists/shared/price-list.service';
import { DeviceUser } from 'src/app/shared/models/DeviceUser';
import { Event } from 'src/app/shared/models/Event';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { Printer } from 'src/app/shared/models/Printer';
import { PrintersCategories } from 'src/app/shared/models/PrintersCategories';
import { EventsService } from 'src/app/events/shared/events.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { PriceList } from 'src/app/shared/models/PriceList';
import { forkJoin } from 'rxjs';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';

export class PriceListDeviceUser {
  public deviceUser: DeviceUser;
  public printerCategory: PrinterCategory[] = [];
}

export class PrinterCategory {
  public productCategory: ProductCategory;
  public printerItems: PrinterItem[] = [];
}

export class PrinterItem {
  public id: number;
  public printer: Printer;
}

@Component({
  selector: 'app-assign-printer-product-category',
  templateUrl: './assign-printer-product-category.component.html',
  styleUrls: ['./assign-printer-product-category.component.css']
})
export class AssignPrinterProductCategoryComponent implements OnInit, OnChanges {

  public deviceUsersPriceList: PriceListDeviceUser[] = [];
  public deviceUsersPriceListInit: PriceListDeviceUser[] = [];
  public deviceUsersByTypePriceList: PriceListDeviceUser[] = [];
  public berichtDeviceUsersByTypePriceList: PriceListDeviceUser;
  public tableDeviceUsersPriceList: any;
  public tableDeviceUsersTypePriceList: any;
  // public printersCategoriesArray: PrintersCategories[] = [];
  public loadingContent = true;

  private _loading = false;
  private _choosenDeviceTyoeCheckBoxes = [];

  @Input()
  eventId: string;

  @Input()
  eventFinished = false;

  @Input()
  deviceUsers: DeviceUser[];

  @Input()
  public printers: Printer[];

  @Input()
  notifier: Subject<DeviceUser[]>;

  constructor(private _priceListService: PriceListService, private _eventsService: EventsService, private _toastr: ToastrService) { }

  ngOnInit() {
    if (this.notifier != null) {
      this.notifier.subscribe((deviceUsers) => {
        this.deviceUsers = deviceUsers;
        new Promise((resolve, reject) => {
          if (!this._loading) {
            this._loading = true;
            this.deviceUsersPriceList = [];
            this.deviceUsersByTypePriceList = [];
            this.tableDeviceUsersPriceList = [];
            this.tableDeviceUsersTypePriceList = [];
            let printersCategoriesArray: PrintersCategories[] = [];
            let priceListsArray: PriceList[] = [];
            const berichtProductCategory: ProductCategory = {
              id: null,
              name: 'Bericht',
              status: 1,
              products: [],
              priceList: null,
              itemOrder: 0,
              printersCategories: undefined
            };
            forkJoin([
              this._eventsService.getPrintersCategoriesByEventId(this.eventId),
              this._priceListService.getPriceListsByEventId(this.eventId)
            ]).subscribe(results => {
              const onlineResult = results[0];
              priceListsArray = results[1];
              priceListsArray.forEach(priceListItem => {
                priceListItem.productCategories.push(ProductCategory.fromDto(berichtProductCategory));
              });
              onlineResult.map(function (result) {
                if (!result.productCategory) {
                  result.productCategory = { ...berichtProductCategory };
                }
              });
              printersCategoriesArray = onlineResult;
              new Promise((resolve2, reject2) => {
                this.deviceUsers.forEach(deviceUser => {
                  if (deviceUser.priceList) {
                    const deviceUserPriceListId = deviceUser.priceList.id.toString();
                    const priceList = priceListsArray.filter(pl => pl.id.toString() === deviceUserPriceListId)[0];
                    const printerCategoryByDeviceTypeExist = this.deviceUsersPriceList.filter(function (item) {
                      if (item.deviceUser.deviceUserType.id === deviceUser.deviceUserType.id) {
                        if (item.deviceUser.priceList.id === priceList.id) {
                          return true;
                        }
                      }
                      return false;
                    })[0];
                    this.deviceUsersPriceList.push(
                      this._getPriceListDeviceUserObject(priceList, deviceUser, printersCategoriesArray)
                    );
                    // if (printerCategoryByDeviceTypeExist) {
                    if (!printerCategoryByDeviceTypeExist) {
                      this.deviceUsersByTypePriceList.push(
                        this._getPriceListDeviceUserObject(priceList, deviceUser, printersCategoriesArray)
                      );
                    }
                  }
                });
                resolve2(null);
              }).then(() => {
                this.tableDeviceUsersPriceList = this.chunk(this.deviceUsersPriceList);
                console.log('tableDeviceUsersPriceList', this.tableDeviceUsersPriceList);
                this.tableDeviceUsersTypePriceList = this.chunk(this.deviceUsersByTypePriceList);
                if (this.deviceUsersPriceList.length > 0) {
                  const firstDeviceUserPriceList = JSON.parse(JSON.stringify(this.deviceUsersPriceList[0]));
                  firstDeviceUserPriceList.printerCategory.splice(0, firstDeviceUserPriceList.printerCategory.length - 1);
                  firstDeviceUserPriceList.printerCategory[0].printerItems = [];
                  this.berichtDeviceUsersByTypePriceList = firstDeviceUserPriceList;
                }
                resolve(null);
              });
            });

            this.deviceUsersPriceListInit = this.deviceUsersPriceList;
          }
        }).then(() => {
          this.loadingContent = false;
          this._loading = false;
        });
      });
    }
  }

  private _getPriceListDeviceUserObject(priceList: PriceList, deviceUser: DeviceUser, printersCategoriesArray: PrintersCategories[]) {
    const priceListDeviceUser = new PriceListDeviceUser();
    priceListDeviceUser.deviceUser = deviceUser;
    priceList.productCategories.forEach(category => {
      const printerCategory = new PrinterCategory();
      printerCategory.productCategory = ProductCategory.fromDto(category);
      const objectsToAdd = [];
      printersCategoriesArray.forEach(item => {
        if (item.productCategory) {
          if (item.deviceUser.id === deviceUser.id && item.productCategory.id === category.id) {
            objectsToAdd.push(item);
          }
        } else {
          if (item.deviceUser.id === deviceUser.id) {
            objectsToAdd.push(item);
          }
        }
      });
      if (objectsToAdd.length > 0) {
        objectsToAdd.forEach(object => {
          printerCategory.printerItems.push({
            id: object.id,
            printer: object.printer
          });
        });
      }
      priceListDeviceUser.printerCategory.push(printerCategory);
    });

    return priceListDeviceUser;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.event) {
      if (!changes.event.currentValue.id) {
        return;
      }
    }
  }

  onCheckBoxChange(result: any) {
    const configPrinters = this.printers;
    this.deviceUsersPriceList.filter(function (value) {
      if (value.deviceUser.id === result.deviceUserId) {
        const printerCategory = value.printerCategory.filter(category => category.productCategory.id === result.productCategoryId)[0];
        const choosenPrinter = printerCategory.printerItems.filter(printerItem => printerItem.printer.id === result.printerId)[0];
        if (!choosenPrinter) {
          printerCategory.printerItems.push({
            id: undefined,
            printer: configPrinters.filter(printer => printer.id === result.printerId)[0]
          });
        } else {
          const index = printerCategory.printerItems.indexOf(choosenPrinter);
          printerCategory.printerItems.splice(index, 1);
        }
      }
    });
  }

  onDeviceTypeCheckBoxChange(result: any) {
    const deviceUserCategoryId = result.deviceUserCategoryId;
    console.log('result:', result);
    console.log('deviceUserCategoryId:', deviceUserCategoryId);
    const configPrinters = this.printers;
    this.deviceUsersPriceList.filter(item => {
      const filterResult = item.printerCategory.filter(cat => {
        return (cat.productCategory.id === result.productCategoryId ? true : false);
      })[0];
      if (filterResult) { return filterResult; }
    }).filter(item => {
      return (item.deviceUser.deviceUserType.id === deviceUserCategoryId);
    }).map(item => {
      if (!result.productCategoryId) {
        item.printerCategory.map(cat => {
          if (cat.productCategory.id === result.productCategoryId) {
            cat.printerItems = [];
          }
        });
      } else {
        if (this._choosenDeviceTyoeCheckBoxes.length < 1) {
          item.printerCategory.map(cat => {
            if (cat.productCategory.id) {
              cat.printerItems = [];
            }
          });
        }
      }
      return item;
    }).map(item => {
      return item;
    }).map(item => {
      const printerCategory = item.printerCategory
        .filter(category => category.productCategory.id === result.productCategoryId)[0];
      console.log('printerCategory', printerCategory);
      const choosenPrinter = printerCategory.printerItems.filter(printerItem => printerItem.printer.id === result.printerId)[0];
      console.log('choosenPrinter', choosenPrinter);
      if (!choosenPrinter) {
        printerCategory.printerItems.push({
          id: undefined,
          printer: configPrinters.filter(printer => printer.id === result.printerId)[0]
        });
      } else {
        const index = printerCategory.printerItems.indexOf(choosenPrinter);
        printerCategory.printerItems.splice(index, 1);
      }
    });
    this._choosenDeviceTyoeCheckBoxes.push(result);
  }

  onBerichtCheckBoxChange(result: any) {
    this.onDeviceTypeCheckBoxChange(result);
  }

  onDeviceUserPrinterCategoryTabChange(event: NgbTabChangeEvent) {
    if (event.nextId === 'ngb-tab-4') {
      if (this.deviceUsersByTypePriceList.length === 0) {
        return;
      }
      this._choosenDeviceTyoeCheckBoxes = [];
      this.deviceUsersByTypePriceList.map(item => {
        item.printerCategory.map(cat => {
          cat.printerItems = [];
        });
      });
    }
  }

  onCancelButtonClick() {
    this.notifier.next(this.deviceUsers);
  }

  saveSettings() {
    const configPrinters = this.printers;
    console.log('configPrinters:', configPrinters);
    const printersCategoriesArray = [];
    this.deviceUsersPriceList.forEach(element => {
      element.printerCategory.forEach(printerCategory => {
        printerCategory.printerItems.forEach(printerItem => {
          configPrinters.forEach(configPrinterItem => {
            if (printerItem.printer.id === configPrinterItem.id) {
              printersCategoriesArray.push({
                id: undefined,
                event: this.eventId,
                deviceUser: element.deviceUser.id,
                printer: printerItem.printer.id,
                productCategory: printerCategory.productCategory.id,
                priceListId: element.deviceUser.priceList.id
              });
            }
          });
        });
      });
    });
    if (printersCategoriesArray.length > 0) {
      console.log('printersCategoriesArray', printersCategoriesArray);
      this._eventsService.insertPrintersCategories(printersCategoriesArray, this.eventId).subscribe(result => {
        this._toastr.success('Categories assigned successfully to the printers');
      });
    }
  }

  chunk(array) {
    const chunked_arr = [[], []];
    let index = 0;
    while (index < array.length) {
      chunked_arr[(index % 2 === 0 ? 0 : 1)].push(array[index]);
      index++;
    }
    return chunked_arr;
  }

}

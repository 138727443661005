import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-group-input',
  templateUrl: './form-group-input.component.html',
  styleUrls: ['./form-group-input.component.css']
})
export class FormGroupInputComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  inputType: string;

  @Input()
  additionalClass: string;

  @Input()
  placeHolder: string;

  @Input()
  model: string;

  @Input()
  disabled = false;

  @Input()
  inline = false;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  change() {
    this.modelChange.emit(this.model);
  }

}

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { EventOffer } from 'src/app/shared/models/EventOffer';
import { Option } from 'src/app/shared/select/select.component';
import { LanguageEnum } from 'src/app/shared/enums/LanguageEnum';
import * as moment from 'moment';
import { SharedService } from 'src/app/shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Village } from 'src/app/shared/models/Village';
import { EventOfferStatusEnum } from 'src/app/shared/enums/EventOfferStatusEnum';
import { OfferService } from '../shared/offer.service';
import { EventCost } from 'src/app/shared/models/EventCost';
import { forkJoin } from 'rxjs';
import { ClientsService } from 'src/app/clients/shared/clients.service';
import { Company } from 'src/app/shared/models/Company';
import { NgbTabChangeEvent, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OriginPlaceEnum } from 'src/app/shared/enums/OriginPlaceEnum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Country } from 'src/app/shared/models/Country';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { DeliveryAddress } from 'src/app/shared/models/DeliveryAddress';
import { PickupPoint } from 'src/app/shared/models/PickupPoint';
import { TranslateService } from '@ngx-translate/core';
import { AdditionalCost } from 'src/app/shared/models/AdditionalCost';

@Component({
  selector: 'app-create-offer',
  templateUrl: './create-offer.component.html',
  styleUrls: ['./create-offer.component.css']
})
export class CreateOfferComponent implements OnInit {

  public existingEventOfferId: string;
  public eventOffer = new EventOffer();
  public choosenCountryId;
  public choosenVillageId;
  public newCompany = true;
  public companyTab = 'tab-new';
  public choosenVillage: Option;
  public choosenCompany: Option;
  public villageOptions: Option[] = [];
  public countriesOptions: Option[] = [];
  public companyOptions: Option[] = [];
  public eventLanguageArray: Option[] = [
    { value: 'de', label: LanguageEnum.DE },
    { value: 'it', label: LanguageEnum.IT },
    { value: 'en', label: LanguageEnum.EN },
  ];
  public cableProductsAmount = {
    one: 0,
    five: 0,
    ten: 0,
    fifteen: 0,
    twenty: 0,
    thirty: 0,
    fifty: 0
  };
  public sendEmailToAdmin = true;
  public sendEmailToOrganization = true;
  public emailToSendOffer: string;
  public originPlaceEnum = OriginPlaceEnum;
  public deliveryAddressForm: FormGroup;
  public deliveryAddressErrorArray = new Array();
  public pickupPoints: PickupPoint[];
  public choosenPickupPoint: number;
  public currentLanguage: string;
  public villageObjectArray: Village[];
  //
  public startDate: any;
  public endDate: any;
  public pickUpDate: any;
  public returnDate: any;


  @ViewChild('organizationTab')
  organizationTab: TemplateRef<any>;

  @ViewChild('chooseEmailModal')
  private _chooseEmailModal: TemplateRef<any>;
  private _villagArray: Village[];
  private _countriesObjectArray: Country[];
  private _companyArray: Company[];
  private _currentVat: number;

  public additionalCostsCustomArray: {
    id: number, name: string, cost: number, typeShow: string, typeField: string, title: string,
    text: string, priceLabel: string, value: number, endRow: boolean, hiddenSummary: boolean, titleSummary: string, valueSummary: string,
    valueSummaryZero: string
  } [] = [];

  public additionalCostsContainerIsOpen = false;
  public additionalCostsCount = 0;
  public additionalCostsObjectArray: AdditionalCost[];

  constructor(private _router: Router, private _offerService: OfferService, private _sharedService: SharedService,
    private _toastr: ToastrService, private _activatedRoute: ActivatedRoute, private _clientsService: ClientsService,
    private _modalService: NgbModal, private _formBuilder: FormBuilder, private _translate: TranslateService,
    private _offersService: OfferService
    ) {
    this.deliveryAddressForm = this._formBuilder.group({
      firstLastName: ['', <any>Validators.required],
      address: ['', <any>Validators.required],
      addressDetails: [''],
      city: ['', <any>Validators.required],
      province: ['', <any>Validators.required],
      postcode: ['', <any>Validators.required],
      phone: ['', <any>Validators.required],
      country: ['1', <any>Validators.required],
    });
  }

  ngOnInit() {
    this.currentLanguage = 'de';
    this._activatedRoute.queryParams.subscribe(params => {
      this.existingEventOfferId = params['id'];
    });
    forkJoin([
      this._sharedService.getAllVillages(),
      this._clientsService.getAllCompanies(),
      this._sharedService.getAllCountries(),
      this._sharedService.getPickupPoints(),
      this._offersService.getAdditionalCosts()
    ]).subscribe(results => {
      this._villagArray = results[0];
      this.villageObjectArray = results[0];
      console.log('villages: ', this.villageObjectArray);
      this._villagArray.forEach(village => {
        this.villageOptions.push({ value: village.id.toString(), label: village.name });
      });
      this.villageOptions = [...this.villageOptions];
      this._companyArray = results[1];
      this._companyArray.forEach(company => {
        this.companyOptions.push({ value: company.id, label: company.name });
      });
      this.companyOptions = [... this.companyOptions];
      this._countriesObjectArray = results[2];
      this._countriesObjectArray.forEach(country => {
        this.countriesOptions.push({ value: country.id.toString(), label: country.name });
      });
      this.pickupPoints = results[3];
      if (this.existingEventOfferId) {
        this._offerService.getEventOfferById(this.existingEventOfferId).subscribe(result => {
          this.eventOffer = result;
          this.eventOffer.id = undefined;
          this.startDate = moment(this.eventOffer.startDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
          this.endDate = moment(this.eventOffer.endDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
          this.pickUpDate = moment(this.eventOffer.pickUpDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
          this.returnDate = moment(this.eventOffer.returnDate, 'YYYY-MM-DD').format('DD-MM-YYYY');

          if (this.eventOffer.notes) {
            this.eventOffer.notes = this.eventOffer.notes.replace(/<br[^>]*>/gi, '\n');
          }
          const villageOption = this.villageOptions.filter(option =>
            option.value.toString() === this.eventOffer.village.id.toString())[0];
          this.choosenVillage = villageOption;
          this.onVillageSelected(villageOption);
          this.cableProductsAmount = JSON.parse(this.eventOffer.hardwareCable);
          this.eventOffer.status = EventOfferStatusEnum.Pending;
          if (this.eventOffer.company) {
            this.companyTab = 'tab-existing';
            this.newCompany = false;
            this.choosenCompany = this.companyOptions.filter(option => option.value === this.eventOffer.company.id)[0];
          }
          if (this.eventOffer.delivery) {
            this.deliveryAddressForm.patchValue(this.eventOffer.deliveryAddress);
            this.deliveryAddressForm.get('country').setValue(this.eventOffer.deliveryAddress.country.id);
          } else {
            this.choosenPickupPoint = this.eventOffer.pickupPoint.id;
          }
        });
      } else {
        this.startDate = moment().format('DD-MM-YYYY');
        this.endDate = moment().format('DD-MM-YYYY');
        this.pickUpDate = moment().subtract(1, 'day').format('DD-MM-YYYY');
        this.returnDate = moment().add(1, 'day').format('DD-MM-YYYY');
        this.eventOffer.language = 'de';
        this.eventOffer.status = EventOfferStatusEnum.Pending;
        this.eventOffer.vat = 0.00;
        this.eventOffer.fullPrice = 0.00;
        this.eventOffer.discountedPrice = 0.00;
        this.eventOffer.discount = 0;
        this.eventOffer.discountedPriceWithVat = 0.00;

        // exclude saturdays and sundays
        for (let i = 1; i <= 3; i++) {
            const currentDate = moment(this.startDate, 'DD-MM-YYYY').subtract(i, 'day');
            if (currentDate.day() !== 0 && currentDate.day() !== 6) {
              this.pickUpDate = currentDate;
              break;
            }
        }
        // exclude saturdays and sundays
        for (let i = 1; i <= 3; i++) {
          const currentDate = moment(this.endDate, 'DD-MM-YYYY').add(i, 'day');
          if (currentDate.day() !== 0 && currentDate.day() !== 6) {
            this.returnDate = currentDate;
            break;
          }
        }
        this.additionalCostsObjectArray = results[4];
        if (results[4]) {
          this.additionalCostsCount = results[4].length;
          this._updateAdditionalCostObject(results[4]);
        }
      }
    });
  }

  public beforeChange($event: NgbTabChangeEvent) {
    if (this.existingEventOfferId && this.newCompany && $event.nextId === 'tab-existing') {
      $event.preventDefault();
      return;
    }
    if (this.existingEventOfferId && !this.newCompany && $event.nextId === 'tab-new') {
      $event.preventDefault();
      return;
    }
    if ($event.nextId === 'tab-existing') {
      this.newCompany = false;
      this.eventOffer.organizationName = '';
      this.eventOffer.organizationEmail = '';
      this.eventOffer.clientTitle = '';
      this.eventOffer.clientPhone = '';
      this.eventOffer.clientName = '';
    } else {
      this.newCompany = true;
      this.choosenCompany = new Option();
    }
  }

  onSoftwareTypeChange(softwareType: string) {
    switch (softwareType) {
      case 'mainDevice':
      case 'cashRegister':
        this.eventOffer.hardwareTablet = (this.eventOffer.softwareCashRegister + this.eventOffer.softwareMainDevice);
        break;
      case 'printer':
        this.eventOffer.hardwarePrinter = this.eventOffer.softwarePrinter;
        break;
      default:
        this.eventOffer.hardwarePhone = this.eventOffer.softwareWaiter;
        break;
    }
    this.calculateEventOfferPrice();
  }

  onVillageSelected(item: any) {
    const choosenVillage = this._villagArray.filter(village => village.id.toString() === item.value.toString())[0];
    this.choosenVillageId = choosenVillage.id;
    this.choosenCountryId = choosenVillage.province.region.country.id;
    this.calculateEventOfferPrice();
  }

  calculateEventOfferPrice() {

    const startDate = moment(moment(this.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
    const endDate = moment(moment(this.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));

    // exclude saturdays and sundays
    for (let i = 1; i <= 3; i++) {
        const currentDate = moment(this.startDate, 'DD-MM-YYYY').subtract(i, 'day');
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          this.pickUpDate = currentDate;
          break;
        }
    }
    // exclude saturdays and sundays
    for (let i = 1; i <= 3; i++) {
      const currentDate = moment(this.endDate, 'DD-MM-YYYY').add(i, 'day');
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        this.returnDate = currentDate;
        break;
      }
    }

    if (!this.choosenPickupPoint) {
      return;
    }

    const duration = moment.duration(endDate.diff(startDate));
    console.log('this.eventOffer:', this.eventOffer);
    this._offerService.calculateEventOfferPrice(this.eventOffer, (duration.asDays() + 1), this.choosenPickupPoint, true)
      .subscribe(result => {
        this._currentVat = result.vat;
        this.eventOffer.vat = this.formatCurrency(this._currentVat * 100);
        this.eventOffer.fullPrice = this.formatCurrency(EventCost.fromDtoPrice(result));
        this.eventOffer.discountedPrice = this.formatCurrency(EventCost.fromDtoPrice(result));
        this.eventOffer.discount = 0;
        this.eventOffer.insuranceCost = result.insuranceCost;
        this.calculateVat();
      });
  }

  calculateVat() {
    const vatPrice: number = this.eventOffer.discountedPrice / 100 * this.eventOffer.vat;
    this.eventOffer.discountedPriceWithVat = this.formatCurrency(
      parseFloat(this.eventOffer.discountedPrice.toString()) + parseFloat(vatPrice.toString()));
  }

  calculateDiscountPrice() {
    const discount = (this.eventOffer.discount / 100);
    this.eventOffer.discountedPrice = this.formatCurrency(this.eventOffer.fullPrice - (this.eventOffer.fullPrice * discount));
    this.calculateVat();
  }

  onDeliveryTypeRadioChange(delivery: boolean) {
    this.deliveryAddressErrorArray = [];
    if (!delivery) {
      this.eventOffer.pickupPoint = undefined;
      this.choosenPickupPoint = null;
      this.eventOffer.vat = 0.00;
      this.eventOffer.fullPrice = 0.00;
      this.eventOffer.discountedPrice = 0.00;
      this.eventOffer.discount = 0;
      this.eventOffer.discountedPriceWithVat = 0.00;
    } else {
      this.deliveryAddressForm.reset({ country: '1' });
      this.choosenPickupPoint = 1;
    }
    this.calculateEventOfferPrice();
  }

  onPickupPointChange(pickupPointId: number) {
    this.eventOffer.pickupPoint = this.pickupPoints.filter(pickup => pickup.id === pickupPointId)[0];
    this.calculateEventOfferPrice();
  }

  submitEventOfferRequest() {
    this.deliveryAddressErrorArray = [];
    if (!this.eventOffer.eventName) {
      this._toastr.error('Please write a Event Name');
      return;
    }
    if (!this.choosenCountryId) {
      this._toastr.error('Please choose a village');
      return;
    }
    if (this.newCompany) {
      if (!this.eventOffer.organizationName) {
        this._toastr.error('Please write a Organization Name');
        return;
      }
      if (!this.eventOffer.organizationEmail) {
        this._toastr.error('Please write a Organization Email');
        return;
      }
    } else {
      if (!this.choosenCompany) {
        this._toastr.error('Please choose a company');
        return;
      }
    }
    if (this.eventOffer.fullPrice === 0 || this.eventOffer.discountedPrice === 0 ||
      this.eventOffer.vat === 0 || this.eventOffer.discountedPriceWithVat === 0) {
      this._toastr.error('The prices can not be 0');
      return;
    }
    this.eventOffer.clientName = this.eventOffer.clientLastName + ' ' + this.eventOffer.clientFirstName;
    this.eventOffer.village = this._villagArray.filter(village => village.id.toString() === this.choosenVillageId.toString())[0];
    this.eventOffer.startDate = moment(this.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.eventOffer.endDate = moment(this.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.eventOffer.pickUpDate = moment(this.pickUpDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.eventOffer.returnDate = moment(this.returnDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.eventOffer.hardwareCable = JSON.stringify(this.cableProductsAmount);
    this.eventOffer.vat = this._currentVat;
    if (this.eventOffer.notes) {
      this.eventOffer.notes = this.eventOffer.notes.replace(/\n/g, '<br />');
    }
    if (this.eventOffer.delivery === undefined) {
      this._toastr.error('Please choose a Delivery Type');
      return;
    }
    if (this.eventOffer.delivery === true) {
      if (this.deliveryAddressForm.valid) {
        const deliveryAddress: any = DeliveryAddress.fromDto(this.deliveryAddressForm.value);
        deliveryAddress.country = this._countriesObjectArray.filter(country => country.id.toString() === deliveryAddress.country)[0];
        this.eventOffer.deliveryAddress = deliveryAddress;
      } else {
        this.deliveryAddressErrorArray = ValidationUtils.getFormValidationErrors(this.deliveryAddressForm, 'offers.request');
        return;
      }
    } else {
      if (!this.eventOffer.pickupPoint) {
        this._toastr.error('Please choose a pickup Place');
        return;
      }
    }
    if (!this.newCompany) {
      this.eventOffer.company = this._companyArray.filter(company => company.id === this.choosenCompany.value)[0];
      if (this.sendEmailToOrganization) {
        this.emailToSendOffer = '';
        this._modalService.open(this._chooseEmailModal, { centered: true }).result.then((result) => {
          const emailToSendArray = this.emailToSendOffer.split('***');
          this.emailToSendOffer = emailToSendArray[0];
          this.sendEventOffer();
        }, result2 => { });
      } else {
        // console.log('1:', this.eventOffer);
        if (this.eventOffer.clientName === 'undefined undefined') {
          this.eventOffer.clientName = null;
          if (this.eventOffer.company) {
            if (this.eventOffer.company.clientUsers) {
              const lastName = this.eventOffer.company.clientUsers[0].lastName;
              const firstName = this.eventOffer.company.clientUsers[0].firstName;
              this.eventOffer.clientName = lastName + ' ' + firstName;
            }
          }
        }
        // console.log('2:', this.eventOffer);
        this.sendEventOffer();
      }
    } else {
      this.sendEventOffer();
    }
  }

  public sendEventOffer() {
    if (!this.eventOffer.delivery) {
      this.eventOffer.deliveryAddress = undefined;
    }
    this._offerService.insertEventOffer(this.eventOffer, this.sendEmailToAdmin,
      this.sendEmailToOrganization, this.emailToSendOffer).subscribe(() => {
        this._modalService.dismissAll();
        this._toastr.success('Event Offer creted successfully');
        this._router.navigate(['/offers']);
      }, error => {
        if (error.status === 600) {
          this._toastr.error('Company "' + error.error.name + '" already exist with this email');
        }
      });
  }

  formatCurrency(value: number): number {
    const val = (Math.round(value * 100) / 100).toFixed(2);
    return parseFloat(val);
  }

  private _updateAdditionalCostObject (rs: AdditionalCost[]) {

    rs.forEach(rec => {
      const obj: {
        id: number, name: string, cost: number, typeShow: string, typeField: string, title: string,  text: string,
        priceLabel: string, value: number, endRow: boolean, hiddenSummary: boolean, titleSummary: string, valueSummary: string,
        valueSummaryZero: string
      } = {
        id: 0, name: '', cost: 0, typeShow: '', typeField: '', title: '', text: '',
        priceLabel: '', value: 0, endRow: false, hiddenSummary: false, titleSummary: '', valueSummary: '',
        valueSummaryZero: ''
      };
      obj.id = rec.id;
      obj.name = rec.name;
      obj.cost = rec.cost;
      obj.title = rec.name;
      obj.titleSummary = this._translate.instant('offers.request.additionalCosts.' + rec.name + '.title');
      obj.text = this._translate.instant('offers.request.additionalCosts.' + rec.name + '.description');
      obj.priceLabel = this._translate.instant('offers.request.additionalCosts.' + rec.name + '.priceLabel');
      switch (rec.type) {
        case 1:
          obj.typeShow = 'showPerc';
          break;
        case 2:
          obj.typeShow = 'showValue';
          break;
        case 3:
          obj.typeShow = 'showLabel';
          break;
      }
      switch (rec.name) {
        case 'beltBag':
        case 'wallet':
        case 'cashDrawer':
          obj.typeField = 'groupNumber';
          break;
        case 'badWeather':
        case 'pickupInstalled':
        case 'uploadingCheckingPricelist':
        case 'training':
          obj.typeField = 'radio';
          break;
        case 'cancellation':
          obj.typeField = 'simpleText';
          obj.hiddenSummary = true;
          break;
      }
      obj.endRow = (rec.name === 'training');
      if (rec.name !==  'cancellation') {
        this.additionalCostsCustomArray.push(obj);
      }
    });

    console.log(this.additionalCostsCustomArray);

  }

  public onAdditionalCostNumberInputChange(name) {
    this.eventOffer.additionalCosts = [];
    this.additionalCostsCustomArray.forEach(rec => {
      const obj: {id: number, quantity: number} = {id: 0, quantity: 0};
      obj.id = rec.id;
      obj.quantity = rec.value;
      if (rec.value > 0) {
        this.eventOffer.additionalCosts.push(obj);
      }
    });
    this.calculateEventOfferPrice();
  }

}
